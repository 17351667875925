import { Fade, Box } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Wrapper, Large, Small, Progress, Content, IconButton } from './styles'

const ProductCardVolume = ({
  id,
  large,
  small,
  label,
  progress,
  largeWithLabel,
  showControls,
  disabledAddButton,
  disabledSubtractButton,
  onAddButtonClick,
  onSubtractButtonClick,
  addButtonRef,
  subtractButtonRef,
}) => {
  return (
    <Wrapper>
      {progress && !showControls && (
        <Progress variant="determinate" value={progress} size={70} />
      )}
      <Fade in={showControls} unmountOnExit>
        <Box>
          <IconButton
            color="secondary"
            position="top"
            aria-label="add quantity"
            disabled={disabledAddButton}
            onClick={() => onAddButtonClick(id)}
            ref={addButtonRef}>
            <AddCircleIcon sx={{ fontSize: 22 }} />
          </IconButton>
          <IconButton
            color="secondary"
            position="bottom"
            aria-label="subtract quantity"
            disabled={disabledSubtractButton}
            onClick={() => onSubtractButtonClick(id)}
            ref={subtractButtonRef}>
            <RemoveCircleIcon sx={{ fontSize: 22 }} />
          </IconButton>
        </Box>
      </Fade>
      <Content label={label} progress={progress}>
        {label && !progress && !showControls && <Small>{label}</Small>}
        <Large label={largeWithLabel | showControls ? 1 : 0}>
          {large}
          <small>x</small>
        </Large>
        {!showControls && <Small>{small}</Small>}
      </Content>
    </Wrapper>
  )
}

export default ProductCardVolume
