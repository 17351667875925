import { Typography } from '@mui/material'
import { Wrapper, Content } from './styles'

const ProductCardVolume = ({ title, regionGrade, country, productNumber }) => {
  return (
    <Wrapper>
      <Content>
        <Typography variant='h2' mb='4px' pt={1.9} textAlign={'center'}>
          {title}
        </Typography>
        <Typography mb='2px'>
          {regionGrade} / {country}
        </Typography>
        <Typography mb='6px'>{productNumber}</Typography>
      </Content>
    </Wrapper>
  )
}

export default ProductCardVolume
