import { Wrapper, InfoCircleHolder } from './styles'

const ProductInfo = ({ mainInfo }) => {
  return (
    <Wrapper>
      <InfoCircleHolder>{mainInfo}</InfoCircleHolder>
    </Wrapper>
  )
}

export default ProductInfo
