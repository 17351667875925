import compose from '../../node_modules/ramda/src/compose'
import { getLocalState, getState, setPartialState } from './ui.store'

export const getSortedCartItems = () => getState('cart', getLocalState('cart'))

const clearItems = compose(
  ([lens, value]) => localStorage.setItem(lens, value),
  setPartialState('cart.items')
)
const clearSamples = compose(
  ([lens, value]) => localStorage.setItem(lens, value),
  setPartialState('cart.samples')
)

export const clearCartItems = () => {
  clearItems([])
  clearSamples([])
}
