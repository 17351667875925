import { useMemo } from 'react'
import ProductCard from 'components/product-card'
import ProductCardVolume from 'components/product-card-volume'
import { Delete } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { StyledDeleteIconButton } from './styles'
import { formatCurrency } from 'utils/helpers/formatting'
import { getCurrencySetting } from 'store/currency.state'

const formatPrice = (price) => {
  const { weight, sign, additionalSign } = getCurrencySetting()
  let priceData = weight !== 'lb' ? formatCurrency(price) : price
  return `${sign} ${priceData}${additionalSign}/${weight}`
}

export const CardLayout = ({ elements, onDelete }) => {
  return useMemo(() => {
    if (!elements) return null
    return elements.map((prod, idx) => {
      return (
        <Grid key={`prod-${idx}`} item xs={12} sm={6} lg={4}>
          <ProductCard
            volume={
              <ProductCardVolume
                label={prod.volumeLabel ? 'Future' : ''}
                large={prod.amount}
                small={`${prod.packingWeight} ${prod.packingUnit}`}
              />
            }
            price={
              prod.price === 'free'
                ? prod.price
                : `${formatPrice(prod.price) || '-'}`
            }
            tag={
              <StyledDeleteIconButton
                onClick={() => onDelete(prod.cartCategory, prod.reference)}
                color={'secondary'}
              >
                <Delete />
              </StyledDeleteIconButton>
            }
            description={prod.description}
            label={prod.label}
            title={prod.title}
            statusFuture={prod.statusFuture}
          />
        </Grid>
      )
    })
  }, [elements, onDelete])
}
