import { Card as MuiCard, styled } from '@mui/material'
import MuiCheckIcon from '@mui/icons-material/Check'
import MuiDeleteIcon from '@mui/icons-material/Delete'
import { colors } from '../../constants'

export const Card = styled(MuiCard)(
  {
    position: 'relative',
    width: '100%',
    boxShadow: '0px 2px 4px rgba(191, 191, 191, 0.5)'
  },
  ({ active }) => ({
    backgroundColor: active === 'true' ? colors.background.main : colors.white.main,
    color: colors.background.contrastText || colors.white.contrastText
  })
)

export const CheckIcon = styled(MuiCheckIcon)({
  position: 'absolute',
  right: 15,
  bottom: 15
})
export const DeleteIcon = styled(MuiDeleteIcon)({
  position: 'absolute',
  right: 15,
  top: 15
})
