import { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Grid, Typography, CircularProgress } from '@mui/material'
import AppBar from 'components/app-bar'
import BackLink from 'components/back-link'
import AccordionPanel from 'components/accordion-panel'
import { Main } from '../styles'
import { useFaq } from './faq.hook'
import CartController from '../../components/cart-controller/CartController'

const Faq = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { faq, loading } = useFaq()

  const renderBackLink = pathname.split('/').filter(Boolean).length > 1

  const renderView = useMemo(() => {
    if (!faq || loading) return null
    return (
      <Grid container>
        <Grid item xs={12} md={10} lg={8}>
          <Grid container spacing={5}>
            {faq.map(({ title, items }, idx) => (
              <Grid item key={`faq-${idx}`}>
                <AccordionPanel title={title} items={items} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }, [faq, loading])

  return (
    <>
      <AppBar title={'FAQ'}>
        <Grid container justifyContent={'flex-end'} alignItems={'center'}>
          <Grid item>
            <CartController />
          </Grid>
        </Grid>
      </AppBar>
      <Main>
        {renderBackLink && <BackLink onClick={() => navigate(-1)} />}
        <Box mb={5}>
          {loading && (
            <Typography variant='h5'>
              Loading faq
              <span style={{ marginLeft: '12px' }}>
                <CircularProgress color='secondary' size={20} />
              </span>
            </Typography>
          )}
          {renderView}
        </Box>
      </Main>
    </>
  )
}

export default Faq
