import { Box, Typography } from '@mui/material'
import ButtonRound from 'components/button-round'
import { ConfirmationWrapper, ConfirmationContent, ConfirmationIcon } from './styles'
import { useNavigate } from 'react-router-dom'

const CheckoutConfirmation = () => {
  const navigate = useNavigate()

  return (
    <ConfirmationWrapper maxWidth={'sm'}>
      <ConfirmationContent pt={6}>
        <ConfirmationIcon />
        <Typography variant='h3' mb={4} lineHeight={1.5} fontFamily={"'Adelle', serif"}>
          Thank you for your order.
        </Typography>
        <Box mb={6}>
          <Typography variant={'body1'} lineHeight={1.23625} sx={{ marginBottom: '20px' }}>
            You will receive a confirmation email and more information on billing.
          </Typography>
          <Typography variant={'body1'} lineHeight={1.23625} sx={{ marginBottom: '20px' }}>
            After processsing your order can be viewed in my orders.
          </Typography>
        </Box>
      </ConfirmationContent>
      <ButtonRound variant='contained' color='secondary' fullWidth onClick={() => navigate('/')}>
        Back to spotlist
      </ButtonRound>
    </ConfirmationWrapper>
  )
}

export default CheckoutConfirmation
