import { useState, useEffect, useCallback, useMemo } from 'react'
import { Box, Grid } from '@mui/material'
import AppBar from 'components/app-bar'
import { Main } from '../styles'
import { LoadingIndicator } from 'components/loading-indicator'
import { ConnectedToggleView } from 'components/view-mode/connected.toggle.view'
import { ToggleViewMode } from 'components/view-mode/toggle.view.mode'
import { CardLayout, TableLayout } from 'features/contracts'
import { contractsServiceHooks } from '../../services/contracts/hooks'
import { useSharedState } from 'store/ui.store'
import CartController from 'components/cart-controller/CartController'
import ContractsDrawer from '../../components/contracts-drawer'
import { sortContractsByStatus } from 'utils/helpers/list'
import { formatCurrency } from 'utils/helpers/formatting'
import { getCurrencyByName } from 'constants/currencies'
import ContractsEmpty from 'components/contracts-empty/ContractsEmpty'

const { useGetContracts } = contractsServiceHooks

const contractsWithPrices = (list) =>
  list.map((obj) => {
    const pricingData = {
      sign: getCurrencyByName(obj.currency).sign,
      price: formatCurrency(obj.sale_price)
    }
    return { ...obj, pricingData }
  })

const Contracts = () => {
  const [drawerData, setDrawerData] = useState(null)
  const viewmode = useSharedState('ui.viewmode')

  const [getContracts, loading, contracts, ,] = useGetContracts({
    onSuccess: (res) => contractsWithPrices(res?.data?.data)
  })

  const onContractClickHandler = useCallback((data) => {
    setDrawerData(data)
  }, [])
  const closeDrawer = useCallback(() => {
    setDrawerData(null)
  }, [])

  useEffect(() => {
    getContracts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewmode])

  // sort contracts by from_date & add prop 'custom_quantity', used for editing and 'storing'.
  const extendedContracts = useMemo(() => {
    if (contracts && contracts.length) {
      return sortContractsByStatus(contracts).map((contract, id) => ({
        ...contract,
        custom_quantity: 0,
        sbo: contract.sales_id,
        id,
        sale_price: formatCurrency(contract.sale_price),
        ordered_qty: `${contract.ordered_qty}`.match(/\./)
          ? formatCurrency(contract.ordered_qty)
          : contract.ordered_qty,
        orderId: contract.id
      }))
    }
    return null
  }, [contracts])

  const hasContracts = useMemo(
    () => extendedContracts && extendedContracts.length,
    [extendedContracts]
  )

  return (
    <>
      <AppBar title={'Contracts'}>
        {hasContracts && (
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <ConnectedToggleView />
            </Grid>
            <Grid item>
              <CartController />
            </Grid>
          </Grid>
        )}
      </AppBar>
      <Main>
        <Box mb={5} height='100vh'>
          <LoadingIndicator isLoading={loading} label={'Loading contracts'}>
            {!extendedContracts || !extendedContracts.length ? (
              <ContractsEmpty />
            ) : (
              <ToggleViewMode>
                <Grid container spacing={2} view='grid'>
                  <CardLayout
                    onCardClick={onContractClickHandler}
                    contracts={extendedContracts}
                  />
                </Grid>
                <TableLayout
                  view='table'
                  contracts={extendedContracts}
                  onRowClick={onContractClickHandler}
                />
              </ToggleViewMode>
            )}
          </LoadingIndicator>
        </Box>
        <ContractsDrawer
          open={!!drawerData}
          onClose={closeDrawer}
          data={drawerData}
        />
      </Main>
    </>
  )
}
export default Contracts
