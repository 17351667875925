import GuestFormBox from 'components/guest-form-box'
import ForgotPasswordForm from 'components/forms/forgot-password-form/ForgotPasswordForm'

const ForgotPassword = () => {
  return (
    <GuestFormBox>
      <ForgotPasswordForm />
    </GuestFormBox>
  )
}

export default ForgotPassword
