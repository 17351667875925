import {
  getCurrencyCodeBySign,
  getPriceByAmount,
  getSupportedSign
} from '../../utils/helpers/prices'
import { EnumCartItemTypes } from '../../utils/hooks/cart'

const useCartHook = () => {
  const convertContractToCartItem = (contract) => {
    const cartItem = {
      // table
      orderId: contract.orderId,
      reference: contract.sales_id,
      product: contract.name,
      warehouse: contract.warehouse?.name,
      // packaging replace to amount
      availableQty: contract.ordered_qty,
      amount: contract.amount,
      packingUnit: contract.pack_unit,
      packingWeight: contract.pack_weight,
      price: contract.sale_price,
      priceCurrency: getSupportedSign(contract.currency),
      currencyCode: contract.currency,
      type: 'contract',
      origin: contract.origin,
      region: contract.region,
      grade: contract.grade,
      name: `${contract?.origin}, ${contract?.region}, ${contract?.grade}`,
      //card
      label: contract.sales_id,
      description: contract.warehouse?.name,
      title: contract.name,
      // for spots
      volumeLabel: contract.inventory_status,
      // for spots
      statusFuture: !contract.available,
      // amount
      largeValue: contract.amount
    }

    return cartItem
  }

  const convertSpotToCartItem = (spot, amount) => {
    const cartItem = {
      // table
      orderId: spot.batch,
      reference: spot.batch,
      product: spot.location.name,
      warehouse: spot.warehouse?.name,
      // packaging replace to amount
      availableQty: spot.bags,
      amount: spot.amount,
      packingUnit: spot.pack_unit,
      packingWeight: spot.pack_weight,
      price:
        spot.cartCategory !== EnumCartItemTypes.Samples
          ? getPriceByAmount(spot.pricingData.prices, amount)
          : 'free',
      priceCurrency: spot.pricingData?.sign,
      currencyCode: getCurrencyCodeBySign(spot.pricingData?.sign)?.toUpperCase(),
      type: 'spot',
      origin: spot.origin,
      name: spot.region_with_grade,
      //card
      label: spot.batch,
      description: spot.warehouse?.name,
      title: spot.location?.name,
      // for spots
      volumeLabel: spot.availability_date ? 'Feature' : null,
      // for spots
      statusFuture: spot.availability_date || false,
      // amount
      largeValue: spot.amount
    }

    return cartItem
  }

  return { convertContractToCartItem, convertSpotToCartItem }
}

export default useCartHook
