import { Outlet } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { useAuth } from 'services/auth'
import { Main, Page, Backdrop, BottomBarWrapper } from './styles'
import { useSharedState } from '../../store'
import { navigation } from 'constants'
import AppDrawer from 'components/app-drawer'
import BottomBar from 'components/bottom-bar'

const AppLayout = () => {
  const drawerCompact = useSharedState('ui.drawer.compact')
  const auth = useAuth()
  return (
    <Page drawerCompact={drawerCompact}>
      <Backdrop open={auth.loading || false}>
        <CircularProgress color='primary' />
      </Backdrop>
      <AppDrawer items={navigation} />
      <BottomBarWrapper>
        <BottomBar items={navigation} />
      </BottomBarWrapper>
      <Main>
        <Outlet />
      </Main>
    </Page>
  )
}

export default AppLayout
