import { Fragment, useMemo } from 'react'
import { IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { GridWrapper } from './styles'
import { formatCurrency } from 'utils/helpers/formatting'
import { getCurrencySetting } from 'store/currency.state'

const formatPrice = (price) => {
  const { weight, sign, additionalSign } = getCurrencySetting()
  let priceData = weight !== 'lb' ? formatCurrency(price) : price
  return `${sign} ${priceData}${additionalSign}/${weight}`
}

const columns = (onDelete) => [
  {
    field: 'reference',
    headerName: 'Refrence',
    flex: 1
  },
  {
    field: 'product',
    headerName: 'Product',
    flex: 1
  },
  {
    field: 'warehouse',
    headerName: 'Warehouse',
    flex: 1
  },
  {
    field: 'pack',
    headerName: 'Packaging',
    valueGetter: ({ row }) => `${row?.packingWeight} ${row?.packingUnit}`,
    flex: 1
  },
  {
    field: 'price',
    headerName: 'Price',
    disableColumnMenu: true,
    valueGetter: ({ row, value }) => {
      return value === 'free' ? value : `${formatPrice(row.price) || '-'}`
    }
  },
  {
    field: 'amount',
    headerName: 'Bags',
    disableColumnMenu: true
  },
  {
    field: 'cartCategory',
    headerName: '',
    align: 'right',
    renderCell: ({ row }) => {
      return (
        <IconButton
          onClick={() => onDelete(row.cartCategory, row.reference)}
          variant='contained'
          color='secondary'
          size='small'
        >
          <Delete />
        </IconButton>
      )
    }
  }
]
export const TableLayout = ({ elements, onDelete }) => {
  return useMemo(() => {
    if (!elements) return null
    const cols = columns(onDelete)
    const autoHeight = elements.length * 120 <= 500

    return (
      <Fragment key={`group-${Math.random() * 100}`}>
        <div
          style={{
            flexGrow: 1,
            minHeight: 200,
            height: autoHeight ? elements.length * 120 : 500
          }}
        >
          <GridWrapper
            rowsPerPageOptions={[12]}
            rowHeight={52}
            pageSize={12}
            rows={elements.map((prods, id) => ({ ...prods, id }))}
            columns={cols}
            disableSelectionOnClick
          />
        </div>
      </Fragment>
    )
  }, [elements, onDelete])
}
