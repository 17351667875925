import { styled } from '@mui/material/styles'
import { Drawer as MuiDrawer } from '@mui/material'

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 360
    }
  }
}))

export const CloseButtonWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 30,
  left: 0,
  [theme.breakpoints.up('sm')]: {
    top: 0,
    left: 'auto',
    right: 0
  },
  '.MuiIconButton-root': {
    padding: 0,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('sm')]: {
      padding: 12
    }
  },
  '.MuiSvgIcon-root': {
    fontSize: '3rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.4rem'
    }
  }
}))
