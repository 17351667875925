import { useCallback, useEffect } from 'react'
import { Grid, Typography, TextField, CircularProgress } from '@mui/material'
import ButtonRound from 'components/button-round'
import { useForm } from 'react-hook-form'
import { useModal } from 'services/modal'
import { userServiceHooks } from 'services/user/hooks'

const { useChangePassword } = userServiceHooks

export const ChangePassword = () => {
  const { openModal } = useModal()
  const [changePassword, isLoading, response, error, abort] = useChangePassword({
    onSuccess: (res) => {
      openModal({
        title: 'Information',
        content: <Typography>Your password has been changed successfully.</Typography>
      })
      return res
    },
    onError: (error) => {
      const {
        data: { errors = {} }
      } = error
      return errors
    }
  })
  const {
    register,
    reset,
    setError,

    handleSubmit,
    formState: { errors, isDirty },
    watch
  } = useForm({
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: ''
    }
  })

  useEffect(() => abort, [abort])

  const onSubmit = useCallback(
    ({ current_password, password_confirmation, password }) => {
      changePassword({ current_password, password, password_confirmation })
    },
    [changePassword]
  )

  useEffect(() => {
    if (response && response?.status === 200) {
      reset({ current_password: '', password: '', password_confirmation: '' })
    }
  }, [reset, response])

  useEffect(() => {
    if (error && Object.keys(error).length) {
      Object.keys(error).forEach((key) => {
        setError(key, { type: 'string', message: error[key].join('') })
      })
    }
  }, [error, setError])

  return (
    <Grid container spacing={2} component={'form'} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} mb={2}>
        <Typography variant={'h3'}>Change password</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register('current_password', {
            required: { value: true, message: 'Current password is required' },
            minLength: { value: 8, message: 'Current password must have at least 8 characters' }
          })}
          error={!!errors['current_password']}
          helperText={errors['current_password']?.message}
          fullWidth
          label='Current password'
          type='password'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register('password', {
            required: { value: true, message: 'Password is required' },
            minLength: { value: 8, message: 'Password must have at least 8 characters' },
            validate: {
              currentPassword: (val) =>
                val !== watch('currentPassword') || 'New password cannot be same as current password'
            }
          })}
          type={'password'}
          label='New password'
          error={!!errors['password']}
          helperText={errors['password']?.message}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register('password_confirmation', {
            required: { value: true, message: 'Password confirmation is required' },
            validate: (val) => val === watch('password') || 'The passwords do not match'
          })}
          type={'password'}
          label='Confirm password'
          error={!!errors['password_confirmation']}
          helperText={errors['password_confirmation']?.message}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <ButtonRound
          type={'submit'}
          disabled={!isDirty || isLoading}
          endIcon={isLoading && <CircularProgress color='secondary' size={20} />}
          color='secondary'
          variant='contained'
          fullWidth
        >
          Change password
        </ButtonRound>
      </Grid>
    </Grid>
  )
}

export default ChangePassword
