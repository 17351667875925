import { Button, Grid, TextField, FormControl, FormHelperText } from '@mui/material'
import CustomSelect from '../../custom-select'
import countriesOptions from '../../../constants/mock-data/countries-options'
import { useForm, Controller } from 'react-hook-form'
import { useMemo } from 'react'

const formFields = [
  {
    name: 'company',
    label: 'Company name',
    type: 'text'
  },
  {
    name: 'national_registration',
    label: 'Vat no.',
    type: 'text',
    required: false
  },
  {
    name: 'ftusa_id',
    label: 'FLO/FTUSA id',
    type: 'text',
    required: false
  },

  {
    name: 'street',
    label: 'Street and no.',
    type: 'text'
  },
  {
    name: 'city',
    label: 'City',
    type: 'text'
  },
  {
    name: 'zipcode',
    label: 'Zip code',
    type: 'text'
  },
  {
    name: 'country',
    label: 'Select a country',
    type: 'select',
    options: countriesOptions
  },
  {
    name: 'telephone',
    label: 'Telephone',
    type: 'text'
  }
]

const AddShippingAddress = ({
  onSuccess,
  onAddAddress,
  isKnownUser,
  samplesOnly
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      company: '',
      national_registration: '',
      ftusa_id: '',
      street: '',
      city: '',
      zipcode: '',
      country: '',
      telephone: ''
    }
  })

  const onSubmit = (values) => {
    onAddAddress({ ...values })
    onSuccess && onSuccess()
  }

  const selectedFormFields = useMemo(() => {
    return formFields.filter((fld) => {
      if (!isKnownUser && !samplesOnly) {
        return true
      }
      return (
        (samplesOnly || isKnownUser) &&
        !['national_registration', 'ftusa_id'].includes(fld.name)
      )
    })
  }, [samplesOnly, isKnownUser])

  return (
    <Grid>
      <Grid container component={'form'} onSubmit={handleSubmit(onSubmit)}>
        {selectedFormFields.map((field) => {
          switch (field.type) {
            case 'select':
              return (
                <Grid
                  container
                  key={field.name}
                  item
                  xs={12}
                  mb={2}
                  component={FormControl}
                  error={!!errors[field.name]}
                >
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={field.name}
                      rules={{
                        required: {
                          value: true,
                          message: `${field.label} is required field`
                        }
                      }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <CustomSelect
                          value={value}
                          placeholder={field.label}
                          options={field.options}
                          size={'small'}
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                    />
                  </Grid>
                  {!!errors[field.name] && (
                    <Grid item xs={12}>
                      <FormHelperText>{errors[field.name]?.message}</FormHelperText>
                    </Grid>
                  )}
                </Grid>
              )

            default:
              return (
                <Grid key={field.name} item xs={12} mb={2}>
                  <TextField
                    {...register(field.name, {
                      ...(!['national_registration', 'ftusa_id'].includes(
                        field.name
                      ) && {
                        required: {
                          value: true,
                          message: `${field.label} is required field`
                        }
                      })
                    })}
                    helperText={errors[field.name]?.message}
                    error={!!errors[field.name]}
                    label={field.label}
                    fullWidth
                  />
                </Grid>
              )
          }
        })}
        <Grid item xs={12}>
          <Button
            type={'submit'}
            color={'secondary'}
            variant={'contained'}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddShippingAddress
