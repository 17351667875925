import GuestFormBox from 'components/guest-form-box'
import ResetPasswordForm from 'components/forms/reset-password-form'

const ResetPassword = () => {
  return (
    <GuestFormBox>
      <ResetPasswordForm />
    </GuestFormBox>
  )
}

export default ResetPassword
