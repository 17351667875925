import * as R from 'ramda'
import { useEffect, useState, useRef } from 'react'
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs'
import { isFunction, isArray, isString } from './helpers'

const getLensViewState = (lenses) => {
  let convertedToLenses
  if (isString(lenses)) {
    convertedToLenses = [getLens(lenses)]
  } else if (isArray(lenses)) {
    convertedToLenses = lenses.map(getLens)
  }
  return (value) =>
    convertedToLenses.reduce((memo, lens) => {
      memo = [...memo, R.view(lens, value)]
      return memo
    }, [])
}
const lensView = (lenses) => (source) => {
  const getLensValues = getLensViewState(lenses)
  return new Observable((subscriber) => {
    return source.subscribe({
      next(value) {
        const newState = getLensValues(value)
        subscriber.next(newState.length > 1 ? newState : newState[0])
      }
    })
  })
}
const getLens = (maybeLens) => {
  if (maybeLens && typeof maybeLens === 'string') {
    return createLens(maybeLens)
  } else if (maybeLens && typeof maybeLens !== 'string') {
    return maybeLens
  } else {
    return R.lensPath([])
  }
}
export const createSharedState = (initialValue) => {
  const store$ = new BehaviorSubject(initialValue)
  // outside react:
  const setNextState = (value, lens) => store$.next(R.set(lens, value, store$.getValue()))
  const setState = (maybeLens) => (nextState) => {
    let newState = nextState
    const rLens = getLens(maybeLens)
    if (isFunction(nextState)) {
      newState = nextState(R.view(rLens, store$.getValue()))
      setNextState(newState, rLens)
    } else {
      setNextState(nextState, rLens)
    }
    return [maybeLens, newState]
  }
  const getState = (maybeLens, defaultValue) => {
    const rLens = getLens(maybeLens)
    return R.view(rLens, store$.getValue()) || defaultValue
  }
  const getSelectorAndLenses = (lensesOrSelector) => {
    const isSelectorFn = isFunction(lensesOrSelector)
    if (!isSelectorFn) {
      return [lensesOrSelector, lensView(lensesOrSelector), null]
    }
    const [lenses, selector] = lensesOrSelector()
    return [lenses, lensView(lenses), selector]
  }
  // Inside React: the hook:
  const useSharedState = (lensesOrSelector) => {
    const tupleRef = useRef(getSelectorAndLenses(lensesOrSelector))
    const [state, setState] = useState(() => {
      const newState = getLensViewState(tupleRef.current[0])(store$.getValue())
      return newState.length > 1 ? newState : newState[0]
    })
    useEffect(() => {
      let subscription
      if (tupleRef.current && tupleRef.current.length) {
        const [, selectorFn, set] = tupleRef.current
        subscription = store$
          .pipe(
            selectorFn,
            distinctUntilChanged((prev, curr) => {
              return R.equals(prev, curr)
            })
          )
          .subscribe((value) => {
            set ? setState(set(value)) : setState(value)
          })
      }
      return () => subscription.unsubscribe()
    }, [])
    return state
  }
  return [setState, useSharedState, getState]
}
export const createSelector = (selectorFn, maybeLenses) => {
  return () => [maybeLenses, selectorFn]
}
export const createLens = (path) => (path.indexOf('.') > -1 ? R.lensPath(path.split('.')) : R.lensProp(path))
