import { setPartialState, useSharedState } from '../../store'
import { compose } from '../../acato-utils'
import { useModal } from '../../services/modal'
import { Typography } from '@mui/material'
import { getSortedCartItems } from '../../store/cart.state'
import useCartHook from '../../views/cart/cart.hook'
import { useEffect, useState } from 'react'
import { useGoogleTagManager } from 'utils/helpers/gtm'
import { getState } from 'store/ui.store'

const saveHandler = compose(
  ([lens, value]) => localStorage.setItem(lens, JSON.stringify(value)),
  setPartialState('cart.items')
)

const saveSample = compose(
  ([lens, value]) => localStorage.setItem(lens, JSON.stringify(value)),
  setPartialState('cart.samples')
)

export const EnumCartItemTypes = {
  Spots: 'spots',
  Contracts: 'contracts',
  Samples: 'samples'
}

export const useCart = ({ reference, limit = 0 } = { limit: 0, reference: '' }) => {
  const [availableQty, setAvailableQty] = useState(limit)
  const { convertContractToCartItem, convertSpotToCartItem } = useCartHook()
  const cart = useSharedState('cart')
  const { items: cartItems, samples } = cart
  const sortedCartElements = getSortedCartItems()
  const { openModal } = useModal()

  const { onAddToCart } = useGoogleTagManager()

  const showError = (err) => {
    openModal({
      content: <Typography>{err?.message || err}</Typography>
    })
  }

  const addSample = ({ value, onSuccess }) => {
    const el = convertSpotToCartItem(value)
    if (samples.length >= 5) {
      showError(
        'You can add a maximum of 5 samples. Please remove one sample or get in touch with a Trabocca sales representative to arrange special requests.'
      )
      return null
    }
    if (samples.find((item) => item.reference === el.reference)) {
      showError('You can add only one sample of the same product')
      return null
    } else {
      saveSample([
        ...samples,
        {
          ...el,
          amount: 1,
          price: 'free',
          cartCategory: 'samples',
          packingWeight: '200',
          packingUnit: 'gr'
        }
      ])
      onAddToCart({ ...el, type: 'sample', amount: 1 })
      onSuccess && onSuccess()
    }
  }

  const addToCart = ({ type, value, amount = 1, onSuccess }) => {
    let el =
      type === EnumCartItemTypes.Contracts
        ? convertContractToCartItem(value)
        : convertSpotToCartItem(value, amount)
    const prevValue = cartItems
    let nextValue = []

    if (!Object.values(EnumCartItemTypes).includes(type)) {
      showError(
        'Unacceptable cart element type. Allowed types is: [samples/spots/contracts]'
      )
      return null
    }

    const prevElementIndex = prevValue.findIndex(
      (item) => item.reference === el.reference
    )

    if (prevElementIndex >= 0) {
      prevValue[prevElementIndex].amount += +amount
      nextValue = [...prevValue]
      setAvailableQty(limit - getQtyInCart(el.reference))
    } else {
      setAvailableQty(limit - amount)
      nextValue = [...prevValue, { ...el, amount: +amount, cartCategory: type }]
    }
    saveHandler(nextValue)
    onAddToCart({ ...el, amount })
    onSuccess && onSuccess()
  }

  const removeFromCart = ({ type, reference }) => {
    switch (type) {
      case EnumCartItemTypes.Samples:
        saveSample(samples.filter((el) => el.reference !== reference))
        break

      default:
        saveHandler(cartItems.filter((el) => el.reference !== reference))
        break
    }
  }

  const convertKilogramsToPounds = (numKgs) => {
    const { currency } = getState('settings')
    return currency?.weight === 'lb' ? numKgs * 2.20462262 : numKgs
  }

  const getTotalCartPrice = () => {
    const totalPrice = cartItems.reduce((accelerator, el) => {
      if (el.price === 'free') return accelerator
      return (
        el.price * el.amount * convertKilogramsToPounds(el.packingWeight) +
        accelerator
      )
    }, 0)
    return +totalPrice?.toFixed(2) || totalPrice
  }

  const _getPriceInCategory = (items) => {
    const totalPrice = items.reduce((accelerator, el) => {
      return el.price * el.amount + accelerator
    }, 0)
    return +totalPrice?.toFixed(2) || totalPrice
  }

  const prepareCartItems = () => {
    return Object.keys(sortedCartElements).reduce((accelerator, key) => {
      if (key === EnumCartItemTypes.Samples) {
        accelerator[key] = sortedCartElements[key].map((sample) => sample.reference)
        return accelerator
      } else {
        const res = ['spots', 'contracts'].reduce((acc, category) => {
          acc[category] = sortedCartElements[key]
            .filter((item) => item.cartCategory === category)
            .map((item) => ({ id: item.orderId, amount: item.amount }))
          return acc
        }, {})

        return { ...accelerator, ...res }
      }
    }, {})
  }

  const _parseCheckoutCard = (() => {
    const titles = {
      [EnumCartItemTypes.Spots]: 'From spotlist',
      [EnumCartItemTypes.Contracts]: 'From contracts',
      [EnumCartItemTypes.Samples]: 'Samples'
    }

    return (key, cartItems) => {
      return {
        name: titles[key],
        totalPrice:
          key !== EnumCartItemTypes.Samples
            ? _getPriceInCategory(cartItems)
            : 'free',
        products: cartItems
      }
    }
  })()

  const prepareCartItemsForGtm = () => getCartItemsByCategory()

  const getCartItemsByCategory = () => {
    return Object.keys(sortedCartElements).reduce((accelerator, key) => {
      if (key === EnumCartItemTypes.Samples) {
        return [...accelerator, _parseCheckoutCard(key, sortedCartElements[key])]
      }
      const res = [EnumCartItemTypes.Spots, EnumCartItemTypes.Contracts].reduce(
        (acc, category) => {
          const items = _parseCheckoutCard(
            category,
            sortedCartElements[key].filter((item) => item.cartCategory === category)
          )
          return [...acc, items]
        },
        []
      )

      return [...accelerator, ...res]
    }, [])
  }

  const getQtyInCart = (reference) => {
    let inCartAmount = 0
    cartItems.forEach((el) => {
      if (el.reference === reference) {
        inCartAmount += el.amount
      }
    }, 0)
    return inCartAmount
  }

  const clearCart = () => {
    saveHandler([])
    saveSample([])
  }

  useEffect(() => {
    setAvailableQty(limit - getQtyInCart(reference))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, reference])

  return {
    clearCart,
    addToCart,
    addSample,
    availableQty,
    getQtyInCart,
    removeFromCart,
    prepareCartItems,
    getTotalCartPrice,
    getCartItemsByCategory,
    prepareCartItemsForGtm
  }
}
