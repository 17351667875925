import { Grid, Box, useTheme, useMediaQuery } from '@mui/material'
import ButtonRound from 'components/button-round'
import PersonalData from 'components/personal-data'
import ChangePassword from 'components/forms/change-password'
import ChangeCurrency from 'components/forms/change-currency'
import AppBar from 'components/app-bar'
import { Main } from '../styles'
import { contactEmail } from 'constants/contacts'
import { currencies } from 'constants/currencies'
import { getCurrencySetting } from 'store/currency.state'
import { useAuth, useUserData } from 'services/auth/index'
import { useCallback, useEffect } from 'react'
import { LoadingIndicator } from 'components/loading-indicator/index'
import CartController from '../../components/cart-controller/CartController'
import { Logout } from '@mui/icons-material'

const Settings = () => {
  const { whoami, loading, userData } = useUserData()
  const auth = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    whoami()
  }, [whoami])

  const signout = useCallback(() => {
    auth.logout()
  }, [auth])

  return (
    <>
      <AppBar title={'Account'}>
        <Grid container justifyContent={'flex-end'} alignItems={'center'}>
          {isMobile && (
            <Grid item>
              <ButtonRound
                startIcon={<Logout />}
                onClick={signout}
                color='navigation'
                variant='contained'
                fullWidth
              >
                {'Sign out'}
              </ButtonRound>
            </Grid>
          )}
          <Grid item>
            <CartController />
          </Grid>
        </Grid>
      </AppBar>
      <Main>
        <Grid container mb={5} style={{ paddingBottom: '4rem' }}>
          <Grid item xs={12} md={5} lg={3}>
            <Box mb={5}>
              <LoadingIndicator isLoading={loading} label={'Loading personal data'}>
                <PersonalData {...userData} contactEmail={contactEmail} />
              </LoadingIndicator>
            </Box>
            <Box mb={5}>
              <ChangePassword />
            </Box>
            <Box>
              <ChangeCurrency
                currencies={currencies}
                activeCurrency={getCurrencySetting()}
              />
            </Box>
          </Grid>
        </Grid>
      </Main>
    </>
  )
}

export default Settings
