import { styled } from '@mui/material'
import { CircularProgress } from '@mui/material'

export const Wrapper = styled('div')({
  margin: '0 auto',
  display: 'flex',
  height: 194,
  width: 194,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
})

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  lineHeight: 1.375,
}))

export const Progress = styled(CircularProgress)(({ theme, color }) => ({
  position: 'absolute',
  svg: {
    circle: {
      stroke: color ? theme.palette[color].main : theme.palette.text.primary,
      strokeLinecap: 'round',
    },
  },
}))
