import { CircularProgress, Grid, Link as MuiLink, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import ButtonRound from 'components/button-round'
import { Link, useParams } from 'react-router-dom'
import { authServiceHooks } from 'services/auth/hooks'
import { useModal } from 'services/modal'
import ModalErrorContent from 'components/modal-error-content/ModalErrorContent'
import { useEffect } from 'react'

const { useResetPassword } = authServiceHooks

const ResetPasswordForm = () => {
  const { token } = useParams()
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    }
  })
  const { openModal } = useModal()
  const [resetPassword, isLoading, response, , abort] = useResetPassword({
    onError: (error) => {
      openModal({
        content: <ModalErrorContent error={error?.message} />
      })
    }
  })

  const onSubmit = ({ email, password, passwordConfirmation }) => {
    try {
      resetPassword({
        email,
        password,
        password_confirmation: passwordConfirmation,
        token,
      })
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => abort, [abort])

  return (
    <Grid container spacing={1} component={'form'} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} mb={1}>
        <Typography variant={'h2'}>Create your new password</Typography>
      </Grid>
      {response?.status !== 200 ? (
        <>
          <Grid item xs={12}>
            <TextField
              {...register(
                'email',
                {
                  required: { value: true, message: 'Email is required' },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "The email field must be a valid email"
                  }
                })}
              label='Email address'
              error={!!errors['email']}
              helperText={errors['email']?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register(
                'password',
                {
                  required: { value: true, message: 'Password is required' },
                  minLength: { value: 8, message: 'Password must have at least 8 characters' },
                })}
              type={'password'}
              label='Password'
              error={!!errors['password']}
              helperText={errors['password']?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <TextField
              {...register(
                'passwordConfirmation',
                {
                  required: { value: true, message: 'Email is required' },
                  validate: val => val === watch('password') || 'The passwords do not match'
                })}
              type={'password'}
              label='Verify password'
              error={!!errors['passwordConfirmation']}
              helperText={errors['passwordConfirmation']?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <ButtonRound
              type={'submit'}
              variant={'contained'}
              color={'secondary'}
              size={'large'}
              fullWidth
              disabled={isLoading}
              endIcon={
                isLoading && <CircularProgress color='secondary' size={20} />
              }
            >
              Submit new password
            </ButtonRound>
          </Grid>
          <Grid item xs={12}>
            <MuiLink component={Link} to={'/sign-in'} color={'secondary'}>
              Go back to login
            </MuiLink>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} mb={1}>
            <Typography>
              You have successfully updated your password and now you can login with your new credentials
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonRound
              type={'submit'}
              variant={'contained'}
              color={'secondary'}
              size={'large'}
              to={'/sign-in'}
              fullWidth
              disabled={isLoading}
              component={Link}
            >
              Take me there
            </ButtonRound>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ResetPasswordForm
