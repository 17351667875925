import { styled } from '@mui/material/styles'
import { Backdrop as MuiBackdrop } from '@mui/material'

export const Page = styled('div', {
	shouldForwardProp: (prop) => ![ 'drawerCompact' ].includes(prop)
})(({ theme, drawerCompact }) => ({
	[theme.breakpoints.up('md')]: {
		marginLeft: drawerCompact ? 70 : 228
	},
	transition: theme.transitions.create('margin-left', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	})
}))

export const Main = styled('main')(({ theme }) => ({
	//paddingTop: theme.spacing(2)
}))

export const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1
}))

export const BottomBarWrapper = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'none'
	},
	position: 'fixed',
	bottom: 0,
	left: 0,
	right: 0,
	zIndex: theme.zIndex.appBar
}))
