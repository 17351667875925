import { styled } from '@mui/material/styles'
import {
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction as MuiBottomNavigationAction,
  Button
} from '@mui/material'

export const BottomNavigation = styled(MuiBottomNavigation)(({ theme }) => ({
  height: 74,
  borderRadius: '15px 15px 0px 0px',
  backgroundColor: theme.palette.navigation.main
}))

export const BottomNavigationAction = styled(MuiBottomNavigationAction)(({ theme }) => ({
  color: theme.palette.common.white,
  opacity: 0.6,
  '&.active': {
    opacity: 1
  }
}))

export const BottomBarButtonWrapper = styled('span')({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#fff',
  flex: 1,
  maxWidth: 168,
  minWidth: 80
})

export const BottomBarButtonSvgButton = styled(Button)(({ theme }) => ({
  minHeight: 54,
  minWidth: 54,
  borderRadius: 8,
  marginTop: -18,
  padding: 0,
  color: theme.palette.navigation.main,
  svg: {
    fill: theme.palette.navigation.main
  },
  '&.active': {
    svg: {
      fill: theme.palette.primary.main
    }
  }
}))
