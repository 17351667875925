import {
  styled,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material'

export const Accordion = styled(MuiAccordion)({
  marginLeft: -16,
  marginRight: -16,
  '&.Mui-expanded': {
    marginLeft: -16,
    marginRight: -16,
  },
})

export const AccordionSummary = styled(MuiAccordionSummary)({
  minHeight: 40,
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 0,
  backgroundColor: 'transparent',
  '&.Mui-expanded': {
    minHeight: 40,
  },
  '.MuiAccordionSummary-content': {
    margin: '9px 0',
    '&.Mui-expanded': {
      margin: '9px 0',
    },
  },
  '.MuiSvgIcon-root': {
    fontSize: '1.8rem',
  },
})

export const AccordionDetails = styled(MuiAccordionDetails)({
  padding: '10px 0',
  margin: 0,
  backgroundColor: 'transparent',
})
