import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Box, Grid } from '@mui/material'
import ContentDrawer from 'components/content-drawer'
import ProductInfo from 'components/product-info'
import ProductInfoCircle from 'components/product-info-circle'
import DetailsList from 'components/details-list'
import WithCartControllers from '../with-cart-controllers'
import { EnumCartItemTypes, useCart } from '../../utils/hooks/cart'
import { getCertifications } from '../../constants/certification'
import moment from 'moment'
import RequestForMediaButton from '../request-for-media-button/RequestForMediaButton'
import { NEW_LINE_CHARACTER } from '../../constants/email'

const ContactsDrawer = ({ onClose, open, data, timing = 500 }) => {
  const [content, setContent] = useState({})
  const { addToCart, availableQty } = useCart({
    limit: data?.ordered_qty,
    reference: data?.sales_id
  })

  const cartRef = useRef(null)
  const [showDetailsHidden, setShowDetailsHidden] = useState(false)

  const onCloseHandler = useCallback(() => {
    onClose && onClose()
    setTimeout(() => setContent({}), timing)
  }, [onClose, timing])

  const { title, regionGrade, country, productNumber, details, detailsHidden } =
    useMemo(
      () => ({
        ...content,
        title: content.name,
        regionGrade: content.region,
        country: content.origin,
        productNumber: content.invent_batch_id,
        details: [
          [
            {
              term: 'Process',
              description: content.process
            },
            {
              term: 'Certification',
              description: getCertifications(content)
            },
            {
              term: 'Packing quantity',
              description: `${content.pack_weight} ${content.pack_unit}`
            },
            {
              term: 'Price per kg',
              description: `${content.currency} ${content.sale_price}`
            }
          ],
          [
            {
              term: 'Contract no.',
              description: content.sales_id
            },
            {
              term: 'Expiry date',
              description: moment(content.to_date).format('MMM DD, YYYY')
            },
            {
              term: 'Contract created',
              description: moment(content.from_date).format('MMM DD, YYYY')
            }
          ]
        ],
        detailsHidden: [
          {
            term: 'Warehouse',
            description: content.warehouse?.title
          }
        ]
      }),
      [content]
    )

  const onSubmit = (values) => {
    addToCart({
      type: EnumCartItemTypes.Contracts,
      value: content,
      amount: values.quantity,
      onSuccess: () => {
        cartRef.current.openNotification(`${values.quantity} items added to cart`)
      }
    })
  }

  useEffect(() => {
    if (typeof open === 'boolean') setShowDetailsHidden(open)
  }, [open])

  useEffect(() => {
    if (data) setContent(data)
  }, [data])

  return (
    <ContentDrawer
      open={open}
      onClose={onCloseHandler}
      SlideProps={{ timeout: timing }}
    >
      <WithCartControllers
        ref={cartRef}
        initialQuantity={content.custom_quantity}
        onSubmit={onSubmit}
        batch={content.invent_batch_id}
        origin={origin}
        max={availableQty}
        disabled={!content.available}
      >
        <ProductInfo
          mainInfo={
            <ProductInfoCircle
              title={title}
              regionGrade={regionGrade}
              country={country}
              productNumber={productNumber}
            />
          }
        />
        <Box px={2} pt={8.5} pb={5}>
          <DetailsList
            details={details}
            detailsHidden={detailsHidden}
            showDetailsHidden={showDetailsHidden}
          />

          <Grid container spacing={1.5} pt={4}>
            <Grid item xs={12}>
              <RequestForMediaButton
                body={`Request for media&body=Request for media for:${NEW_LINE_CHARACTER} Coffee + Region and Grade: ${content.name} / ${content.region}${NEW_LINE_CHARACTER}Process and PBO nr: ${content.sales_id} / ${content.invent_batch_id}`}
              />
            </Grid>
          </Grid>
        </Box>
      </WithCartControllers>
    </ContentDrawer>
  )
}

export default ContactsDrawer
