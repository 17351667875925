import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { CardContent } from 'views/register/styles'
import { Controller, useForm } from 'react-hook-form'
import CustomSelect from 'components/custom-select'
import countriesOptions from 'constants/mock-data/countries-options'
import ButtonRound from 'components/button-round'
import { useModal } from 'services/modal'
import { registerServiceHooks } from 'services/register/hooks'
import RegistrationSteps from 'constants/registration-steps'
import ModalErrorContent from 'components/modal-error-content/ModalErrorContent'
import { useGoogleTagManager } from 'utils/helpers/gtm'
import { getCookie } from 'utils/helpers/cookies'

const { useRegister } = registerServiceHooks

const RegistrationForm = ({ onNextStep, defaultValues, children }) => {
  const { onRegister } = useGoogleTagManager()

  const { openModal } = useModal()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues
  })

  const values = watch()

  const [registration, loading] = useRegister({
    onError: (error) => {
      openModal({
        content: <ModalErrorContent error={error.data.errors || error?.message} />
      })
    },
    onSuccess: ({ data }) => {
      const { code, success } = data
      const gaCookie = getCookie('_ga')
      const gidCookie = getCookie('_gid')
      onRegister({ email: values?.email, _ga: gaCookie, _gid: gidCookie })
      if (code === 'proceed' && !success) {
        onNextStep(RegistrationSteps.Connect, { ...defaultValues, ...values })
      } else {
        openModal({
          content: (
            <Grid>
              <Typography>Fail to recognize the error</Typography>
            </Grid>
          )
        })
      }
    }
  })

  const onSubmit = async (finalValues) => {
    const { firstName, lastName, email, country, password, passwordConfirmation } =
      finalValues
    try {
      registration({
        first_name: firstName,
        last_name: lastName,
        email,
        country,
        password,
        password_confirmation: passwordConfirmation
      })
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={6}>
            <TextField
              {...register('firstName', {
                required: { value: true, message: 'First name is required' }
              })}
              label='First name'
              fullWidth
              error={!!errors['firstName']}
              helperText={errors['firstName']?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              {...register('lastName', {
                required: { value: true, message: 'Last name is required' }
              })}
              label='Last name'
              fullWidth
              error={!!errors['lastName']}
              helperText={errors['lastName']?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <TextField
                {...register('email', {
                  required: { value: true, message: 'Email is required' },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'The email field must be a valid email'
                  }
                })}
                label='Email address'
                error={!!errors['email']}
                helperText={errors['email']?.message}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{
                required: { value: true, message: 'Country is required field' }
              }}
              render={({ field: { value, onChange, onBlur } }) => (
                <FormControl error={!!errors['country']} fullWidth>
                  <CustomSelect
                    value={value}
                    onBlur={onBlur}
                    placeholder='Select a country'
                    size={'small'}
                    onChange={(e) => onChange(e.target.value)}
                    options={countriesOptions}
                    skipSelectBackground
                  />
                  <FormHelperText>{errors['country']?.message}</FormHelperText>
                </FormControl>
              )}
              name={'country'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register('password', {
                required: { value: true, message: 'Password is required' },
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters'
                }
              })}
              label='Password'
              type={'password'}
              error={!!errors['password']}
              helperText={errors['password']?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register('passwordConfirmation', {
                required: {
                  value: true,
                  message: 'Verify password is required'
                },
                validate: (val) =>
                  val === watch('password') || 'The passwords do not match'
              })}
              type={'password'}
              label='Verify password'
              error={!!errors['passwordConfirmation']}
              helperText={errors['passwordConfirmation']?.message}
              fullWidth
            />
          </Grid>
        </Grid>
        <ButtonRound
          disabled={loading}
          type={'submit'}
          color='secondary'
          variant='contained'
          size={'large'}
          endIcon={loading && <CircularProgress color='secondary' size={20} />}
          fullWidth
        >
          Next
        </ButtonRound>
        {children && (
          <Grid container pt={2}>
            <Grid item>{children}</Grid>
          </Grid>
        )}
      </CardContent>
    </form>
  )
}

export default RegistrationForm
