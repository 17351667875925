import { NavLink } from 'react-router-dom'
import BottomBarButton from './BottomBarButton'
import { BottomNavigation, BottomNavigationAction } from './styles'

const BottomBar = ({ items }) => {
  return (
    <BottomNavigation>
      {items.map(({ label, to, icon, asButton }, index) => (
        <BottomNavigationAction
          component={asButton ? BottomBarButton : NavLink}
          showLabel
          key={`bottom-bar-action-${index}`}
          label={label}
          to={to}
          icon={icon}
        />
      ))}
    </BottomNavigation>
  )
}

export default BottomBar
