import EmptyPage from 'components/empty-page'
import ButtonRound from 'components/button-round'
import ContractsImg from '../../assets/images/contracts-empty-img.png'
import { infoContacts } from './../../constants/contacts'
import { Link } from 'react-router-dom'

const ContractsEmpty = () => {
  return (
    <EmptyPage
      img={<img src={ContractsImg} width={287} height={186} alt='contracts empty' />}
      button={
        <ButtonRound
          variant='outlined'
          color='secondary'
          fullWidth
          component={Link}
          to={'/'}
        >
          Order from spotlist
        </ButtonRound>
      }
      contacts={infoContacts}
      title='It looks like you have no contracts with us yet.'
      text='Interested in how contracts can benefit you? Please get in contact
    with us'
    />
  )
}

export default ContractsEmpty
