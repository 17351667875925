import { styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

export const GridWrapper = styled(DataGrid)({
  display: 'flex',
  width: '100%',
  maxHeight: 'none!important',
  marginLeft: 0,
  margin: 0
})
