import { useState, useEffect, useCallback } from 'react'

// Components
import { Box, TextField, Typography, CircularProgress } from '@mui/material'
import ButtonRound from 'components/button-round'
import ForgotClientIDModal from './ForgotClientIDModal'

// Services
import { useModal } from 'services/modal'
import { companiesHooks } from 'services/companies/hooks'

// Styles
import { UnknownClientID } from './styles'

// Constants
import { linkCompanyContent } from 'constants/link-company'

const { useLinkById } = companiesHooks

const LinkCompanyModal = ({ user }) => {
  // Hooks
  const { openModal } = useModal()

  const [requestLinkByID, loading, success, err] = useLinkById()

  // State
  const [input, setInput] = useState('')
  const [error, setError] = useState()

  // Methods
  const handleError = useCallback(() => {
    const company = user?.company || 'This client ID'
    const defaultError = 'We were unable to link your company at this time.'

    if (!err?.status) {
      return defaultError
    }
    if (err.status) {
      switch (err.status) {
        case 403:
          return `${company} is already linked. No further actions are required.`

        case 422:
          return `The client ID you entered does not match our database records.`

        default:
          return defaultError
      }
    }
  }, [err, user?.company])

  const unknownClientIdLink = () => {
    openModal({
      title: linkCompanyContent.requestSent.title,
      content: <ForgotClientIDModal user={user} />,
      showCloseButton: true
    })
  }

  // Success responses
  useEffect(() => {
    if (success) {
      openModal({
        title: linkCompanyContent?.linkSuccess?.title,
        content: (
          <Typography variant='body'>
            {linkCompanyContent?.linkSuccess?.body}
          </Typography>
        ),
        severity: 'success',
        showCloseButton: true
      })
    }
    //eslint-disable-next-line
  }, [success])

  // Error responses
  useEffect(() => {
    if (err) setError(handleError(err))
  }, [err, handleError])

  return (
    <>
      <Box mb={2}>
        <Typography variant='body'>{linkCompanyContent?.modal?.body}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant='body'>{linkCompanyContent?.modal?.findId}</Typography>
      </Box>
      <Box mb={2} display='flex'>
        <UnknownClientID onClick={unknownClientIdLink} variant='body2'>
          {linkCompanyContent?.modal?.cannotFindId}
        </UnknownClientID>
      </Box>
      <TextField
        value={input}
        onChange={(e) => setInput(e.target.value)}
        fullWidth
        placeholder={linkCompanyContent?.modal?.enterClientId}
      />
      {error && (
        <Typography variant='body2' color='error' mt={1}>
          {error}
        </Typography>
      )}
      <Box mt={2}>
        <ButtonRound
          onClick={() => requestLinkByID(input)}
          color='secondary'
          variant='contained'
          disabled={loading}
        >
          {linkCompanyContent?.modal?.confirmLink}
          {loading && <CircularProgress size={12} sx={{ marginLeft: 1 }} />}
        </ButtonRound>
      </Box>
    </>
  )
}

export default LinkCompanyModal
