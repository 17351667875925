import { useState, useEffect, useCallback } from 'react'

// Components
import { Typography } from '@mui/material'

// Services
import { companiesHooks } from 'services/companies/hooks'

// Constants
import { linkCompanyContent } from 'constants/link-company'

const { useForgotClientID } = companiesHooks

const ForgotClientIDModal = ({ user }) => {
  const [requestEmailLink, loading, success, err] = useForgotClientID()

  const [linkStatus, setLinkStatus] = useState(
    linkCompanyContent.requestSent?.body?.loading
  )

  const handleError = useCallback(() => {
    const company = user?.company || 'This company'

    if (err?.status === 403) {
      return `${company} is already linked. No further actions are required.`
    } else {
      return 'We were unable to link your company. Please contact your account manager to link your company.'
    }
  }, [err, user?.company])

  useEffect(() => {
    requestEmailLink(user.id)
  }, [requestEmailLink, user.id])

  useEffect(() => {
    if (loading) setLinkStatus(linkCompanyContent.requestSent?.body?.loading)
  }, [loading])

  useEffect(() => {
    if (success) setLinkStatus(linkCompanyContent.requestSent?.body?.success)
  }, [success])

  useEffect(() => {
    if (err) setLinkStatus(handleError(err))
  }, [err, handleError])

  return <Typography variant='body'>{linkStatus}</Typography>
}

export default ForgotClientIDModal
