import { Fragment, useMemo } from 'react'
import { Button, TextField } from '@mui/material'
import { GridWrapper } from './styles'
import { getCertifications } from 'constants/certification'
import SmudgedTag from 'components/smudged-tag/SmudgedTag'
import { statusEnum } from 'constants/status-colors'

const renderChangeQuantity = ({ ...params }) => <ChangeQuantity {...params} />

const ChangeQuantity = (props) => {
  const { id, api, field } = props
  const handleChange = async (event) => {
    api.setEditCellValue({ id, field, value: Number(event.target.value) }, event)
    const isValid = await api.commitCellChange({ id, field })
    if (isValid) {
      api.setCellMode(id, field, 'view')
    }
  }
  return <TextField id='quantity' onBlur={handleChange} defaultValue={0} />
}

const columns = () => [
  {
    field: 'sales_id',
    headerName: 'SBO',
    flex: 1
  },
  {
    field: 'invent_batch_id',
    headerName: 'PBO',
    flex: 1
  },
  {
    field: 'location',
    headerName: 'Location',
    renderCell: (params) => <strong>{params.value}</strong>,
    width: 140,
    sortable: true
  },
  {
    field: 'region',
    headerName: 'Region grade',
    valueGetter: ({ row }) => [row.region, row.grade].filter(Boolean).join('-'),
    flex: 1
  },
  {
    field: 'origin',
    headerName: 'Origin',
    width: 100,
    sortable: true
  },
  {
    field: 'process',
    headerName: 'Process',
    flex: 1
  },
  {
    field: 'certification',
    headerName: 'Certification',
    valueGetter: ({ row }) => {
      return getCertifications(row)
    },
    flex: 1
  },
  {
    field: 'inventory_status',
    headerName: 'Status',
    width: 100,
    align: 'center',
    renderCell: (params) => {
      return statusEnum[params.value] ? (
        <SmudgedTag color={statusEnum[params.value]}>{params.value}</SmudgedTag>
      ) : (
        ''
      )
    },
    flex: 1
  },
  {
    field: 'ordered_qty',
    headerName: 'Bags',
    flex: 1
  },
  {
    field: 'custom_quantity',
    headerName: '',
    align: 'right',
    disableColumnMenu: true,
    hideSortIcons: true,
    type: 'number',
    editable: true,
    selectable: false,
    width: 80,
    renderCell: (params) => renderChangeQuantity(params),
    renderEditCell: (params) => renderChangeQuantity(params)
  },
  {
    disableColumnMenu: true,
    hideSortIcons: true,
    field: 'Add',
    align: 'right',
    headerName: '',
    renderCell: (params) => {
      const isDisabled = params?.row?.inventory_status !== ''
      return (
        <Button
          disabled={isDisabled}
          variant='contained'
          color='primary'
          size='small'
        >
          Add
        </Button>
      )
    }
  }
]

export const TableLayout = ({ contracts, onRowClick }) => {
  return useMemo(() => {
    if (!contracts) return null

    const cols = columns()
    return (
      <Fragment key={`group-${Math.random() * 100}`}>
        <div style={{ height: '100%' }}>
          <GridWrapper
            hideFooterPagination
            hideFooter
            autoHeight
            rowsPerPageOptions={[]}
            rowHeight={52}
            rows={contracts}
            columns={cols}
            onCellClick={({ isEditable }, event) => {
              if (isEditable) {
                event?.target?.focus()
                event?.stopPropagation()
              }
            }}
            onRowClick={(e) => {
              onRowClick(e.row)
            }}
            disableSelectionOnClick
          />
        </div>
      </Fragment>
    )
  }, [contracts, onRowClick])
}
