import { Grid } from '@mui/material'
import { useMemo } from 'react'
import ProductCard from 'components/product-card'
import ProductCardVolume from 'components/product-card-volume'
import SmudgedTag from 'components/smudged-tag'
import { statusEnum } from 'constants/status-colors'

export const CardLayout = ({ contracts, onCardClick }) => {
  console.log({ contracts })
  return useMemo(() => {
    if (!contracts) return null
    return contracts.map((contract, idx) => {
      return (
        <Grid key={`contract-${idx}`} item xs={12} sm={6} lg={4}>
          <ProductCard
            volume={
              <ProductCardVolume
                label={contract.volumeLabel ? 'Future' : ''}
                large={contract.ordered_qty}
                small={`${contract.pack_weight} ${contract.pack_unit}`}
              />
            }
            tag={
              statusEnum[contract.inventory_status] ? (
                <SmudgedTag color={statusEnum[contract.inventory_status]}>
                  {contract.inventory_status}
                </SmudgedTag>
              ) : (
                ''
              )
            }
            price={`${contract.pricingData.sign} ${contract.pricingData.price}`}
            description={`${contract.origin} / ${contract.process}`}
            label={contract.sbo}
            title={contract.location}
            cursor={onCardClick ? 'pointer' : 'default'}
            onClick={() => onCardClick(contract)}
          />
        </Grid>
      )
    })
  }, [contracts, onCardClick])
}
