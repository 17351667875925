export const currencies = [
  {
    id: 'eur',
    label: 'EUR / Kg',
    sign: '\u20AC',
    weight: 'kg',
    additionalSign: ''
  },
  { id: 'gbp', label: 'GBP / Kg', sign: '\xA3', weight: 'kg', additionalSign: '' },
  {
    id: 'usd',
    label: 'USD / lb',
    sign: '\u0024',
    weight: 'lb',
    additionalSign: '\u00A2'
  },
  {
    id: 'usd_kg',
    label: 'USD / Kg',
    sign: '\u0024',
    weight: 'kg',
    additionalSign: ''
  }
]

export const getCurrencyIndexById = (id) => currencies.findIndex((c) => c.id === id)
export const getCurrencyByName = (currencyName) =>
  currencies.find((c) => c.id === currencyName.toLowerCase())
