import React, { useCallback, useMemo } from 'react'
import { Routes, Route } from 'react-router-dom'
import AppRoute from 'components/app-route'
import { routes } from 'constants'

const AppRouter = ({ auth }) => {
  const groupedRoutes = useMemo(() => {
    return routes.reduce((acc, route) => {
      const { layout } = route
      if (!layout) {
        acc.push(route)
        return acc
      }

      if (!acc.find((item) => item.layout === layout)) {
        acc.push({ layout, routes: [] })
      }
      acc.find((item) => item.layout === layout).routes.push(route)
      return acc
    }, [])
  }, [])
  const getRoutes = useCallback(
    (input) => {
      if (!auth) return null
      return input.map(({ layout: Layout, routes, redirectOnAuth, element: Element, path, guarded, index }, key) => {
        if (Element) {
          return (
            <Route
              key={Math.random()}
              index={index}
              element={
                <AppRoute auth={auth} redirectOnAuth={redirectOnAuth} guarded={guarded}>
                  <Element />
                </AppRoute>
              }
              path={path}
            />
          )
        } else {
          return (
            <Route key={key} index={index} element={<Layout />}>
              {getRoutes(routes)}
            </Route>
          )
        }
      })
    },
    [auth]
  )
  if (!auth) return null // splash screen?
  return <Routes>{getRoutes(groupedRoutes)}</Routes>
}

export default React.memo(AppRouter)
