import { Step, StepButton } from '@mui/material'

import { StyledStepper } from './styles'

const Stepper = ({
  inActiveStepId,
  activeStepId,
  onClickStep,
  children,
  steps = []
}) => {
  return (
    <>
      <StyledStepper>
        {steps.map((step) => {
          const isActive = step.id === activeStepId
          const inActive = step.id === inActiveStepId
          return (
            <Step key={step.id} active={isActive} disabled={!!inActive}>
              <StepButton onClick={() => onClickStep(step.id)} />
            </Step>
          )
        })}
      </StyledStepper>
      {children}
    </>
  )
}

export default Stepper
