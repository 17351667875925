export const certifications = {
  cert_eu: 'ORG',
  cert_flo: 'FLO',
  cert_ftusa: 'FTUSA',
  cert_jas: 'JAS',
  cert_nop: 'NOP',
  cert_rfa: 'RFA',
  cert_ra: 'RA',
  cert_utz: 'UTZ'
}

const certificationReducer = (reducer) =>
  Object.entries(certifications).reduce(reducer, [])

export const getCertifications = (obj) => {
  return certificationReducer((memo, [key, label]) => {
    if (obj[key]) {
      memo.push(label)
    }
    return memo
  }).join(', ')
}
