import { Typography, Collapse } from '@mui/material'
import { Wrapper, InnerAppBar, Toolbar } from './styles'

const AppBar = ({ children, title, toolbarProps }) => {
  return (
    <Wrapper position='static' color='transparent'>
      <InnerAppBar maxWidth={false}>
        <Toolbar disableGutters {...toolbarProps}>
          <Collapse orientation='vertical' in={true} key={title} appear style={{ flexShrink: 0 }}>
            <Typography variant='h1'>{title}</Typography>
          </Collapse>
          {children && (
            <Collapse
              orientation='vertical'
              in={true}
              key={`${title}-controls`}
              appear
              style={{ flexGrow: 1, width: '100%' }}
            >
              {children}
            </Collapse>
          )}
        </Toolbar>
      </InnerAppBar>
    </Wrapper>
  )
}

export default AppBar
