import { Fragment } from 'react'
import { Box, Grid, Typography, Fade } from '@mui/material'

const DetailsList = ({ details, detailsHidden, showDetailsHidden }) => {
  return (
    <Box>
      {details.map((detailsItem, idx) => (
        <Grid container rowSpacing={1.6} columnSpacing={2.1} mb={5} key={idx}>
          {detailsItem.map(({ term, description }, idx) =>
            description ? (
              <Fragment key={idx}>
                <Grid item xs={6}>
                  <Typography variant='body2' color='primary'>
                    {term}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography lineHeight={1.25}>{description}</Typography>
                </Grid>
              </Fragment>
            ) : null
          )}
        </Grid>
      ))}
      <Fade in={showDetailsHidden} unmountOnExit>
        <Grid container rowSpacing={1.6} columnSpacing={2.1}>
          {detailsHidden.map(({ term, description }, idx) => (
            <Fragment key={idx}>
              <Grid item xs={6}>
                <Typography variant='body2' color='primary'>
                  {term}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography lineHeight={1.25}>{description}</Typography>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Fade>
    </Box>
  )
}

export default DetailsList
