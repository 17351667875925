import CustomIcon from '../components/custom-icon'
import CartBottomController from '../components/cart-bottom-controller'

const navigation = [
  {
    label: 'Spotlist',
    to: '/',
    icon: <CustomIcon icon={'coffee-grain'} size={31} />
  },
  {
    label: 'Contracts',
    to: '/contracts',
    icon: <CustomIcon icon={'contracts'} size={31} />
  },
  {
    label: 'Cart',
    to: '/cart',
    icon: <CartBottomController color={'dark'} />,
    onlyBottomBar: true,
    asButton: true
  },
  {
    label: 'Account',
    to: '/account',
    icon: <CustomIcon icon={'person'} size={29} />
  },
  {
    label: 'FAQ',
    to: '/faq',
    icon: <CustomIcon icon={'info'} />
  }
]

export default navigation
