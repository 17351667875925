import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { BottomBarButtonWrapper, BottomBarButtonSvgButton } from './styles'

const BottomBarButton = forwardRef(({ children, to }, ref) => {
  return (
    <BottomBarButtonWrapper>
      <BottomBarButtonSvgButton
        color="white"
        variant="contained"
        component={NavLink}
        to={to}>
        {children[0][0]}
      </BottomBarButtonSvgButton>
      <span className="MuiBottomNavigationAction-label">{children[0][1]}</span>
      {children[1]}
    </BottomBarButtonWrapper>
  )
})

export default BottomBarButton
