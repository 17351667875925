export const infoContacts = {
  phone: {
    value: '+310204074499',
    label: '+31 (0)204 074 499'
  },
  email: {
    value: 'info@trabocca.com',
    label: 'info@trabocca.com'
  }
}

export const contactEmail = 'mytrabocca@trabocca.com'
