import AppBar from '../../components/app-bar'
import { Container, Grid, Typography, Link as MuiLink } from '@mui/material'
import { ReactComponent as NotFoundIcon } from '../../assets/images/not-found.svg'
import ButtonRound from '../../components/button-round'
import { Link } from 'react-router-dom'
import { Main } from '../styles'

const NotFound = () => {
  return (
    <>
      <AppBar
        title={'Out of caffeine...'}
        toolbarProps={{ style: { justifyContent: 'center' } }}
      />
      <Main component={Container} maxWidth={'xl'}>
        <Grid
          container
          justifyContent={'center'}
          minHeight={'calc(100% - 80px)'}
          textAlign={'center'}
        >
          <Grid container item lg={3} width={316} maxwidth={'100%'}>
            <Grid item mb={2}>
              <NotFoundIcon maxwidth={'100%'} />
            </Grid>
            <Grid item mb={2}>
              <Typography variant={'h3'}>
                Oh, no. It looks like the page you were directed is not here anymore.
              </Typography>
            </Grid>
            <Grid item mb={2}>
              <Typography variant={'body1'}>Do you have any questions?</Typography>
            </Grid>
            <Grid container mb={2}>
              <Typography
                variant={'body1'}
                component={MuiLink}
                href={'tel:+31 (0)204 074 499'}
              >
                +31 (0)204 074 499
              </Typography>
            </Grid>
            <Grid container mb={2}>
              <Typography
                variant={'body1'}
                component={MuiLink}
                href={'mailto:info@trabocca.com'}
              >
                info@trabocca.com
              </Typography>
            </Grid>
            <Grid item>
              <ButtonRound
                variant={'outlined'}
                size={'large'}
                color={'secondary'}
                fullWidth
                component={Link}
                to={'/'}
              >
                View our latest coffees on spot
              </ButtonRound>
            </Grid>
          </Grid>
        </Grid>
      </Main>
    </>
  )
}

export default NotFound
