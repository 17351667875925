import { Wrapper, Svg, Label } from './styles'

const SmudgedTag = ({ children, color }) => {
  return (
    <Wrapper>
      <Svg color={color} />
      <Label color={color}>{children}</Label>
    </Wrapper>
  )
}

export default SmudgedTag
