import CheckoutForm from '../../components/checkout-form'
import { Grid } from '@mui/material'
import { useSharedState } from 'store/ui.store'
import { useMemo } from 'react'

const Checkout = () => {
  const cart = useSharedState('cart')
  const hasOnlySamples = useMemo(() => {
    const { items: cartItems, samples } = cart
    if (!cartItems.length && samples.length) {
      return true
    }
    return false
  }, [cart])

  return (
    <Grid>
      <CheckoutForm samplesOnly={hasOnlySamples} />
    </Grid>
  )
}

export default Checkout
