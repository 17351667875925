import { useEffect } from 'react'
import { faqServiceHooks } from '../../services'
const { useGetFaq } = faqServiceHooks

export const useFaq = () => {
  const [getFaq, loading, faq, , abort] = useGetFaq({
    memoize: false,
    onSuccess: (res) => res.data.data
  })

  useEffect(() => {
    getFaq()
    return () => abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { faq, loading }
}
