import { useState, useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionBlock, AccordionHeader, AccordionTitle } from './styles'

const AccordionPanel = ({ title, items, openFirstDefault }) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (openFirstDefault) {
      setExpanded('panel-0')
    } else {
      setExpanded(false)
    }
  }, [openFirstDefault])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <AccordionBlock>
      {title && <AccordionHeader variant='h3'>{title}</AccordionHeader>}

      {items.map(({ question, answer }, id) => (
        <Accordion
          expanded={expanded === `panel-${id}`}
          onChange={handleChange(`panel-${id}`)}
          key={id}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${id}-content`}
            id={`panel-${id}-header`}
          >
            <AccordionTitle>{question}</AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <span dangerouslySetInnerHTML={{ __html: answer }} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionBlock>
  )
}

export default AccordionPanel
