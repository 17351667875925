import { Slide, Typography } from '@mui/material'
import { Wrapper, Card, TitleWrapper } from './styles'

const GuestFormBox = ({ title, nostyles = false, children }) => {
  return (
    <Wrapper clean={nostyles}>
      <Slide direction='up' in={true} appear>
        <Card clean={nostyles}>
          {title && (
            <TitleWrapper>
              <Typography variant='h2'>{title}</Typography>
            </TitleWrapper>
          )}
          {children}
        </Card>
      </Slide>
    </Wrapper>
  )
}

export default GuestFormBox
