import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import ExportIcon from '@mui/icons-material/SaveAlt'
import { spotServiceHooks } from '../../services'
import { useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useModal } from 'services/modal/ModalProvider'

const { useExportSpots } = spotServiceHooks

export const ExportSpotList = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { openModal } = useModal()

  const [exportSpots, loading, response, , abort] = useExportSpots({
    onError: (err) => {
      openModal({
        content: (
          <Grid>
            <Typography>{`Er is een fout opgetreden bij het exporteren van de spotlist`}</Typography>
          </Grid>
        )
      })
    }
  })

  useEffect(() => {
    return abort
  }, [abort])

  useEffect(() => {
    if (response?.data) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      })
      const anchor = document.createElement('a')
      anchor.href = window.URL.createObjectURL(blob)
      anchor.download = 'Spotlist.xlsx'
      document.body.append(anchor)
      anchor.click()
      anchor.remove()
    }
  }, [response])

  return (
    <Button
      fullWidth
      startIcon={
        loading ? <CircularProgress color='white' size={17} /> : <ExportIcon />
      }
      disabled={!!loading}
      variant='contained'
      color='primary'
      size='small'
      sx={{
        ...(matches && {
          mt: 2,
          mb: 2,
          minWidth: '18rem'
        })
      }}
      onClick={() => exportSpots()}
    >
      Export spotlist
    </Button>
  )
}
