import { authorization, applyInterceptor } from 'acato-utils/acato-axios'
import { addErrorHandlers } from 'acato-utils/acato-axios/interceptor'
import { setPartialState } from 'store'
import { getAuthDataFromStorage, storeAuthData } from 'utils/helpers/storage'
import { authService } from './auth/service'

const setAuthData = setPartialState('auth')

const errorHandler = addErrorHandlers({
  match: 401,
  handler: async (err, axios) => {
    const originalRequest = err.config
    if (!originalRequest.__retry) {
      originalRequest.__retry = true
      const res = await authService.refreshToken()
      setAuthData(storeAuthData(res?.data))
      return axios(originalRequest)
    }
    return Promise.reject(err)
  }
})

export const intercept = applyInterceptor(
  authorization(() => getAuthDataFromStorage().token),
  errorHandler
)
