import { Badge, styled } from '@mui/material'

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    right: 0,
    top: -11.5,
    fontSize: 8,
    width: 13,
    height: 13,
    padding: 2,
    minWidth: 13
  }
})
