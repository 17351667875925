import { getCurrencySetting } from 'store/currency.state'
import { interpolateColors } from 'utils/helpers/color'

const interpolate = interpolateColors('69C99B', '366665')

export const formatAmount = (amount) => {
  return Math.floor(parseInt(amount))
}

export const formatCurrency = (value, digits = 2) =>
  (Math.round(value * 100) / 100).toFixed(digits)

export const getScoreBasedColor = (minScore, maxScore) => (score) => {
  const diff = maxScore - minScore
  let fraction = score - minScore
  fraction = 1 - fraction / diff
  return interpolate(fraction)
}

/*
  costs_eur: {1: 8.46, 5: 8.09, 10: 6.99, 50: 6.62, 150: 6.25, tmp: "ignore"}
  costs_gbp: {1: 9.78, 5: 9.36, 10: 8.08, 50: 7.65, 150: 7.23, tmp: "ignore"}
  costs_usct: {1: 345, 5: 330, 10: 285, 50: 270, 150: 255, tmp: "ignore"}
  costs_usd_kg: {...}
*/
const costs = ['costs_eur', 'costs_gbp', 'costs_usct', 'costs_usd_kg']

export const formatWithCurrency = (obj) => {
  const currencyIdx = getCurrencySetting()
  const costProp = costs[currencyIdx]
  return (amount) => {
    return obj[costProp][`${amount}`]
  }
}
