import { ButtonBase as MuiButtonBase, Card as MuiCard, styled } from '@mui/material'
import { colors } from '../../constants'

export const Card = styled(MuiCard)(
  {
    display: 'flex',
    width: '100%',
    minHeight: 62,
    justifyContent: 'center',
    padding: '10px 16px',
    textAlign: 'left',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',

    'svg': {
      width: 28,
      height: 28,
    }
  },
  ({ active }) => ({
    backgroundColor: active ? colors.background.main : colors.white.main,
  })
)

export const ButtonBase = styled(MuiButtonBase)({
  borderRadius: 5,
  width: '100%',
})
