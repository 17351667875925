const businessTypeOptions = [
  {
    value: 0,
    label: 'Micro roaster < 15.000 kg',
  },
  {
    value: 1,
    label: 'Small roaster < 100.000 kg',
  },
  {
    value: 2,
    label: 'Medium roaster < 1.000.000 kg',
  },
  {
    value: 3,
    label: 'Industrial roaster > 1.000.000 kg',
  },
  {
    value: 4,
    label: 'Importer / Exporter',
  },
]

export default businessTypeOptions
