import { Grid, Typography, List, ListItem, Link } from '@mui/material'
import { ImgHolder, ActionButton } from './styles'

const EmptyPage = ({ img, title, text, button, actionButton, contacts }) => {
  return (
    <Grid container justifyContent='center' pt={5} pb={25}>
      <Grid item xs={12} md={4} lg={3}>
        <Grid container rowSpacing={1.8} textAlign='center'>
          <Grid item xs={12} px={2} mb={2.4}>
            <ImgHolder>{img}</ImgHolder>
          </Grid>
          <Grid item xs={12} px={2} mb={1.3}>
            <Typography variant='h3'>{title}</Typography>
          </Grid>
          <Grid item xs={12} px={2}>
            <Typography>{text}</Typography>
          </Grid>
          <Grid item xs={12} px={2}>
            <List>
              <ListItem sx={{ justifyContent: 'center' }} disablePadding>
                <Link href={`tel:${contacts.phone.value}`} underline='hover' color='inherit'>
                  {contacts.phone.label}
                </Link>
              </ListItem>
              <ListItem sx={{ justifyContent: 'center' }} disablePadding>
                <Link href={`mailto:${contacts.email.value}`} underline='hover' color='inherit'>
                  {contacts.email.label}
                </Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            {button}
          </Grid>
        </Grid>

        <ActionButton>{actionButton}</ActionButton>
      </Grid>
    </Grid>
  )
}

export default EmptyPage
