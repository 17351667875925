import { useMemo, useEffect, useCallback } from 'react'
import { setPartialState } from 'store'
import { removeAuthDataFromStorage, storeAuthData } from 'utils/helpers/storage'
import ModalErrorContent from 'components/modal-error-content/ModalErrorContent'
import { authServiceHooks } from './hooks'
import { useModal } from 'services/modal'
import { userServiceHooks } from 'services/user/hooks'
import { clearCartItems } from 'store/cart.state'

const { useLogin } = authServiceHooks
const { useWhoami } = userServiceHooks

const setAuthData = setPartialState('auth')

const authFeedback = {
  login: `Password and or username combination incorrect. Try again or reset your password.`
}

export const useUserData = () => {
  const { openModal } = useModal()

  const [whoami, loading, userData, , abort] = useWhoami(
    useMemo(
      () => ({
        memoize: false,
        onSuccess: (res) => {
          const {
            id,
            last_name: lastName,
            first_name: firstName,
            full_name: fullName,
            email,
            customer
          } = res?.data?.data
          return {
            id,
            lastName,
            firstName,
            fullName,
            email,
            customer,
            addresses: customer?.addresses,
            company: customer?.addresses?.map((address) => address.name).join(',')
          }
        },
        onError: (err) => {
          openModal({
            content: <ModalErrorContent error={err?.message || err} />
          })
        }
      }),
      [openModal]
    )
  )

  useEffect(() => {
    return () => abort()
  }, [abort])

  return useMemo(() => ({ whoami, loading, userData }), [whoami, loading, userData])
}

export const useAuth = () => {
  const { openModal } = useModal()

  const [login, loading, res, , abort] = useLogin({
    onSuccess: (res) => {
      setAuthData(storeAuthData(res.data))
      return res.data
    },
    onError: (err) => {
      console.log('Login error:', err.response.status)
      if (err.response.status === 400) {
      }
      openModal({
        content: (
          <ModalErrorContent
            error={
              err?.response?.status === 400
                ? authFeedback.login
                : err?.response?.data?.message
            }
          />
        )
      })
    }
  })
  const logout = useCallback(() => {
    setAuthData((state) => ({ ...state, ...removeAuthDataFromStorage() }))
    clearCartItems()
  }, [])

  useEffect(() => {
    return () => abort()
  }, [abort])

  return useMemo(() => {
    return {
      token: (res && res.access_token) || null,
      loading,
      login,
      logout,
      initialized: true
    }
  }, [res, loading, login, logout])
}
