import { createResource } from 'acato-utils'
import { intercept } from '../interceptors'

const API_URL = process.env.REACT_APP_API_URL

export const registrationResource = intercept(
  createResource({
    baseURL: `${API_URL}/api/auth/register`
  })
)
