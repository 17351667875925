import { Button, styled } from '@mui/material'
import { colors } from '../../../constants'

export const AddAddressButton = styled(Button)({
  backgroundColor: colors.tertiary.main,
  color: colors.tertiary.contrastText,
  padding: '16px 20px',
  justifyContent: 'flex-start',

  '.MuiButton-startIcon .MuiSvgIcon-root': {
    fontSize: 28,
    marginRight: 10,
  },

  '&:hover': {
    backgroundColor: colors.tertiary.main,

  }
})
