import { Grid, styled } from '@mui/material'

export const StyledTotalWrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  marginRight: -24 + ' !important'
}))

export const StyledHorizontalLine = styled('div')(({ theme }) => ({
  width: '100%',
  height: 3,
  backgroundColor: theme.palette.tertiary.main,
  marginBottom: 12
}))
