import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box } from '@mui/material'
import ButtonRound from 'components/button-round'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { IconButton } from './styles'

const BackLink = ({ onClick }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box mb={2.75}>
      <Box mb={2.75}>
        {matches ? (
          <ButtonRound variant='outlined' color='secondary' startIcon={<ArrowBackIcon />} onClick={onClick}>
            Back
          </ButtonRound>
        ) : (
          <IconButton color='secondary' aria-label='go back' onClick={onClick}>
            <ChevronLeftIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default BackLink
