import { ButtonBase as MuiButtonBase, styled } from '@mui/material'

export const CloseButton = styled(MuiButtonBase)({
  position: 'absolute',
  right: 15,
  top: 15,
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
