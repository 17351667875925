import { Grid, Typography } from '@mui/material'
import ButtonRound from 'components/button-round'
import { Link } from 'react-router-dom'

const CartEmpty = () => {
  return (
    <Grid container alignItems='center' justifyContent='center' minHeight='calc(100vh - 100px)'>
      <Grid item xs={12} md={4} lg={3}>
        <Grid container rowSpacing={1.5}>
          <Grid item xs={12} mb={3.75}>
            <Typography variant='h3' textAlign='center'>
              Nothing in your cart yet
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonRound variant='contained' color='secondary' fullWidth component={Link} to={'/'}>
              Order from the spotlist
            </ButtonRound>
          </Grid>
          <Grid item xs={12}>
            <ButtonRound variant='contained' color='secondary' fullWidth component={Link} to={'/contracts'}>
              Order from contracts
            </ButtonRound>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CartEmpty
