import { Grid } from '@mui/material'
import { useCallback } from 'react'

import { ErrorMessage } from './styles'

//TODO component to render error, but currently it accept only string, need to add array/object parsing
const ModalErrorContent = ({ error }) => {
  const pullErrorDescriptions = useCallback((err) => {
    const content = []

    if (typeof err === 'object') {
      Object.keys(err).forEach((key) => {
        if (Array.isArray(err[key])) {
          content.push(
            <div key={key}>
              <ErrorMessage variant={'h6'} style={{ textTransform: 'capitalize' }}>
                {key}:
              </ErrorMessage>
              <ul>
                {err[key].map((error, index) => (
                  <ErrorMessage key={index} component={'li'}>
                    {error}
                  </ErrorMessage>
                ))}
              </ul>
            </div>
          )
        }
      })
    } else {
      content.push(<ErrorMessage key={'single-message'}>{err}</ErrorMessage>)
    }

    return content
  }, [])

  return <Grid container>{pullErrorDescriptions(error)}</Grid>
}

export default ModalErrorContent
