const currencySpec = [
  {
    currency: 'eur',
    key: 'costs_eur',
    sign: '\u20AC'
  },
  {
    currency: 'gbp',
    key: 'costs_gbp',
    sign: '\xA3'
  },
  {
    currency: 'usd',
    key: 'costs_usct',
    sign: '\u0024'
  },
  {
    currency: 'usd_kg',
    key: 'costs_usd_kg',
    sign: '\u0024'
  }
]

export const getPrices = (prices, currency, bags) => {
  const spec = currencySpec.find((item) => item.currency === currency)

  const priceList = prices[spec.key]

  return {
    prices: Object.keys(priceList).reduce((memo, key) => {
      return {
        ...memo,
        [key]: priceList[key]
      }
    }, {}),
    sign: spec.sign,
    ...spec
  }
}

export const getCurrencyCodeBySign = (sign) =>
  currencySpec.find((cur) => cur.sign === sign)?.currency

export const getSupportedSign = (currency = '') => {
  return (
    currencySpec.find((el) => el.currency === currency.toLowerCase())?.sign ||
    currency
  )
}

export const getPriceByAmount = (prices, amount) => {
  return prices[
    Object.keys(prices).reduce((accelerator, key) => {
      return +key <= amount ? +key : accelerator
    }, 1)
  ]
}
