import { useCallback, useEffect, useState } from 'react'

let timeoutHandle
const events = ['keyup', 'touchstart', 'mousemove', 'scroll']

export const useInactivity = (thresholdMillis) => {
  const [inactive, setInactive] = useState()

  const startTimer = useCallback(() => {
    return setTimeout(() => {
      setInactive(true)
    }, thresholdMillis)
  }, [thresholdMillis])

  const resetTimer = useCallback(() => {
    clearTimeout(timeoutHandle)
    timeoutHandle = startTimer()
  }, [startTimer])

  const setAsActive = useCallback(() => setInactive(false), [])

  useEffect(() => {
    if (!timeoutHandle) {
      timeoutHandle = startTimer()
      events.map((evt) => document.addEventListener(evt, resetTimer))
    }
    return () => {
      clearTimeout(timeoutHandle)
      events.map((evt) => document.removeEventListener(evt, resetTimer))
    }
  }, [startTimer, resetTimer])

  return [inactive, setAsActive]
}
