import { useCallback, useMemo, useState, useRef } from 'react'

const cache = {}

const memoizeAsync =
  (fn, key) =>
  async (...args) => {
    const isCached = key in cache
    if (isCached) {
      return Promise.resolve(cache[key])
    }
    try {
      const response = await fn(...args)
      cache[key] = response
    } catch (e) {
      cache[key] = null
      throw new Error(e)
    }
    return cache[key]
  }

const createUseFetchHook = (fn, fnName) => {
  // The hook:
  return ({ memoize = false, onSuccess, onError, onLoadStatus, next } = {}) => {
    const [loading, setLoading] = useState()
    const [response, setResponse] = useState()
    const [error, setError] = useState()
    const abortRef = useRef()
    const abort = useCallback(() => abortRef.current && abortRef.current.abort(), [])

    const controller = useCallback(() => {
      if (abortRef.current) {
        abortRef.current.abort()
      }
      abortRef.current = new AbortController()
      return abortRef.current.signal
    }, [])

    const serviceMethod = useMemo(
      () => (memoize ? memoizeAsync(fn(controller), fnName) : fn(controller)),
      [memoize, controller]
    )

    const executeServiceMethod = useCallback(
      async (...args) => {
        onLoadStatus ? onLoadStatus(true) : setLoading(true)
        setError(null)
        serviceMethod(...args)
          .then((response) => {
            const responseState = onSuccess ? onSuccess(response) : response
            setResponse(responseState)
            if (next && typeof next === 'function') {
              return next(responseState)
            }
          })
          .catch((err) => {
            debugger
            const errorState = onError ? onError(err) : err
            setError(errorState)
          })
          .finally(() => {
            onLoadStatus ? onLoadStatus(false) : setLoading(false)
          })
      },
      [onSuccess, onLoadStatus, onError, serviceMethod, next]
    )

    return useMemo(
      () => [executeServiceMethod, loading, response, error, abort],
      [executeServiceMethod, loading, response, error, abort]
    )
  }
}

/**
 * Creates hooks for each method in the given service object.
 * A service object is an object that defines your remote api:
 * ```export const myService = {
  getTodos: (signal) => () => myResource.get('/api/todos', { signal: signal() }),
  addTodo: (signal) => (todo) => myResource.post('/api/todos', {...todo}, { signal: signal() }),
}```

 * In the above example ```createFetchHook``` creates 2 'fetch' hooks: ```useGetTodos``` and ```useAddTodo```:

```const { useGetTodos, useAddTodo } = createFetchHook(myService)```.

 * The returned hooks accept these optional arguments:  ```memoize = false, onSuccess, onError, onLoadStatus```.
 *
 * The hook return value is an array: ```[methodCall, loading, response, error, abort]```.
 * @param {*} service A service object with keys and values. Keys are the method names, values are the actual remote method call.
 * @param {*} useHookNames Default value is ```true```.
 * @returns An object literal in which keys are the hook names and the values are the hooks itself.
 */
export const createFetchHook = (service, useHookNames = true) => {
  if (!service) return null
  return Array.from(Object.entries(service)).reduce((memo, [key, value]) => {
    const hookName =
      (!useHookNames && key) ||
      `use${key
        .split('')
        .map((char, idx) => (idx < 1 ? char.toUpperCase() : char))
        .join('')}`
    memo[hookName] = createUseFetchHook(value, hookName)
    return memo
  }, {})
}
