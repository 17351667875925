import { spotsResource } from './resource'

export const spotsService = {
  getSpots: (signal) => () => spotsResource.get('/api/spots', { signal: signal() }),
  exportSpots: (signal) => () =>
    spotsResource.get('/api/spots/export', {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' },
      signal: signal()
    })
}
