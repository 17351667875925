import { Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useSharedState } from '../../store'

import { StyledBadge } from './styles'
import { Link } from 'react-router-dom'
import CustomIcon from '../custom-icon'
import { useMemo } from 'react'

const CartController = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const { items, samples } = useSharedState('cart')
  const totalAmount = useMemo(() => {
    return items?.reduce((memo, curr) => memo + curr.amount, samples?.length || 0)
  }, [items, samples])

  if (matches) return null

  return (
    <Grid container alignItems={'center'}>
      <Typography mr={0.6}>{'Cart'}</Typography>
      <IconButton component={Link} to={'/cart'}>
        <CustomIcon icon={'cart'} size={29} />
        <StyledBadge color={'primary'} badgeContent={totalAmount} />
      </IconButton>
    </Grid>
  )
}

export default CartController
