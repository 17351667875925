import { Grid, Typography } from '@mui/material'
import { Card, CheckIcon } from './styles'
import ButtonBaseCard from '../button-base-card'
import countries from '../../constants/mock-data/countries-options'

const AddressCard = ({
  company,
  street,
  country,
  zipcode,
  city,
  telephone,
  isActive = false,
  onClick
}) => {
  const countryLabel = countries.find((c) => c.value === country)?.label
  return (
    <ButtonBaseCard onClick={onClick}>
      <Card active={isActive.toString()}>
        <Grid p={'33px 21px'}>
          <Typography variant={'h6'} mb={1.5}>
            {company}
          </Typography>
          <Typography mb={0.5}>{street}</Typography>
          <Typography mb={0.5}>
            {zipcode} {city}
          </Typography>
          {countryLabel && <Typography mb={0.5}>{countryLabel}</Typography>}
          <Typography>{telephone}</Typography>
        </Grid>

        {isActive && <CheckIcon />}
      </Card>
    </ButtonBaseCard>
  )
}

export default AddressCard
