import { Typography, Box, CircularProgress } from '@mui/material'
import ButtonRound from 'components/button-round'
import OpenClose from 'components/open-close'
import ProductCard from 'components/product-card'
import ProductCardVolume from 'components/product-card-volume'
import { getState } from 'store/ui.store'
import { formatCurrency } from 'utils/helpers/formatting'
import { Wrapper, Description } from './styles'

const formatPrice = (price) => {
  const { currency } = getState('settings')
  if (currency?.weight === 'lb') {
    return `${currency?.sign} ${formatCurrency(price, 0)}${currency?.additionalSign}`
  }
  return `${currency?.sign} ${formatCurrency(price)}`
}

const CheckoutTotal = ({
  currency,
  products,
  totalPrice,
  subTotalPrice,
  carrierCompany,
  isLoading,
  disabled,
  children
}) => {
  return (
    <Wrapper>
      <Box borderBottom={3} borderColor={'tertiary.main'} pb={'11px'} mb={'19px'}>
        {products?.map(({ name, totalPrice, products }, idx) =>
          products?.length ? (
            <OpenClose summary={name} key={idx} style={{ backgroundColor: 'pink' }}>
              {products?.map((prod, idx) => (
                <ProductCard
                  key={prod.reference}
                  volume={
                    <ProductCardVolume
                      label={prod.volumeLabel}
                      large={prod.amount}
                      small={`${prod.packingWeight} ${prod.packingUnit}`}
                    />
                  }
                  price={
                    prod.price !== 'free'
                      ? formatPrice(prod.price) //  `${prod.priceCurrency} ${prod.price}`
                      : prod.price
                  }
                  description={prod.description}
                  label={prod.label}
                  title={prod.title}
                  statusFuture={prod.statusFuture}
                  cursor={'default'}
                />
              ))}
              <Description color='tertiary'>
                <Typography>Total {name.toLowerCase()}</Typography>
                <Typography fontSize={18} fontWeight='bold'>
                  {formatPrice(totalPrice)}
                  {/* {currency} {totalPrice} */}
                </Typography>
              </Description>
            </OpenClose>
          ) : null
        )}
      </Box>

      <Box borderBottom={3} borderColor={'tertiary.main'} pb={'11px'} mb={'19px'}>
        <Description mb={'36px'}>
          <Typography fontSize={18}>Subtotal</Typography>
          <Typography fontSize={18} fontWeight='bold'>
            {formatPrice(subTotalPrice)}
            {/* {currency} {subTotalPrice} */}
          </Typography>
        </Description>
        <Typography fontSize={18} fontWeight='bold' sx={{ mb: '6px' }}>
          Delivery costs
        </Typography>

        {carrierCompany && (
          <>
            <Description mb={'6px'}>
              <Typography fontSize={14}>Carrier {carrierCompany.name}</Typography>
              {/*
              <Typography fontSize={14}>
                {currency} {carrierCompany.price}
              </Typography>
            */}
            </Description>
            <Description mb={'11px'}>
              <Typography fontSize={14}>
                Trabocca will shortly contact you with more details on transport and
                associated costs.
              </Typography>
            </Description>
          </>
        )}
      </Box>
      <Description mb={'31px'}>
        <Typography fontSize={20}>Total</Typography>
        <Typography fontSize={18} fontWeight='bold'>
          {formatPrice(totalPrice)}
          {/* //{currency} {totalPrice} */}
        </Typography>
      </Description>

      {children}

      <ButtonRound
        {...(isLoading && {
          endIcon: <CircularProgress color='primary' size={20} />
        })}
        disabled={disabled}
        variant='contained'
        color='secondary'
        fullWidth
        type={'submit'}
      >
        Place order
      </ButtonRound>
    </Wrapper>
  )
}

export default CheckoutTotal
