import * as R from 'ramda'

const sortBy = (key) =>
  R.compose(
    R.sortBy(R.prop(key)),
    R.map(
      R.ifElse(
        R.propEq(key, 'In stock'),
        R.over(R.lensProp(key), () => ''),
        R.identity
      )
    )
  )

export const groupBy = (key, list) => R.groupBy(R.prop(key), list)

export const sortContractsByStatus = sortBy('inventory_status')
