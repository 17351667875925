import { createResource } from 'acato-utils'
import { intercept } from 'services/interceptors'

const API_URL = process.env.REACT_APP_API_URL

export const contractsResource = intercept(
  createResource({
    baseURL: `${API_URL}`
  })
)
