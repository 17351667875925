import { styled } from '@mui/material/styles'
import { Select } from '@mui/material'

export const SelectTransparent = styled(Select)(({ theme }) => ({
  '.MuiSelect-select': {
    paddingTop: '8.5px !important',
    paddingBottom: '8.5px !important',
    backgroundColor: 'transparent !important'
  },
  '.MuiSelect-icon': {
    right: 12,
    color: theme.palette.secondary.main
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(55, 71, 79, 0.6)'
  }
}))
