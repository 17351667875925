import { Grid, styled } from '@mui/material'
import { colors } from '../../constants'

export const Root = styled(Grid)({
  position: 'fixed',
  backgroundColor: colors.white.main,
  bottom: 0,
  left: 0,
  boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.15)',
  zIndex: 1001,
})
