import { useCallback } from 'react'
import { List, IconButton, Fade } from '@mui/material'
import { ChevronLeft, ChevronRight, Logout } from '@mui/icons-material'
import { useSharedState, setPartialState } from '../../store'
import { useAuth } from 'services/auth'
import AppDrawerItem from './AppDrawerItem'
import AppDrawerHeader from './AppDrawerHeader'
import { Drawer, ToggleCompactButtonWrapper } from './styles'
import { compose } from 'acato-utils'

const setDrawerCompact = compose(
  ([lens, value]) => localStorage.setItem(lens, parseInt(+value)),
  setPartialState('ui.drawer.compact')
)

const AppDrawer = ({ items }) => {
  const drawerCompact = useSharedState('ui.drawer.compact')

  const auth = useAuth()
  const toggleCompact = useCallback(() => {
    setDrawerCompact((value) => !value)
  }, [])

  const signOutHandler = () => {
    auth.logout()
  }

  return (
    <Fade in={true} appear>
      <Drawer variant='permanent' compact={drawerCompact} open={true}>
        <AppDrawerHeader compact={drawerCompact} />
        <List disablePadding>
          {items
            .filter(({ onlyBottomBar }) => !onlyBottomBar)
            .map((item, index) => (
              <AppDrawerItem {...item} key={index} />
            ))}
          <AppDrawerItem
            onClick={signOutHandler}
            icon={<Logout />}
            label='Sign out'
          />
        </List>
        <ToggleCompactButtonWrapper compact={drawerCompact}>
          <IconButton onClick={toggleCompact} size='small'>
            {drawerCompact ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </ToggleCompactButtonWrapper>
      </Drawer>
    </Fade>
  )
}

export default AppDrawer
