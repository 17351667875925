import { styled, Box } from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({ width: '100%' }))

export const Description = styled(Box)(({ theme, color }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: color ? '9px 18px' : 0,
  backgroundColor: color ? theme.palette[color].main : 'transparent'
}))
