import { styled, Typography, Grid } from '@mui/material'

export const WarehouseWrapper = styled(Typography)(({ theme }) => ({
  marginTop: 48,
  marginBottom: 24,
  display: 'flex',
  fontSize: 16,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'left',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '24px',
    left: 0,
    right: 0,
    borderBottom: `2px solid ${theme.palette.sand.main}`
  }
}))

export const OriginWrapper = styled('div')({
  marginTop: 48
})

export const FilterDiv = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  }
}))
