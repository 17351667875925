import { compose } from '../acato-utils'
import { getLocalState, getState, setPartialState } from './ui.store'

export const setNewAddress = compose(
  ([lens, value]) => localStorage.setItem(lens, JSON.stringify(value)),
  setPartialState('delivery.addresses')
)
export const removeUserDefinedAddress = (addressId) => {
  setPartialState('delivery.addresses')((addresses) =>
    addresses.filter((a) => a.id !== addressId)
  )
  localStorage.setItem(
    'delivery.addresses',
    JSON.stringify(getState('delivery.addresses'))
  )
}

export const getAddresses = () =>
  getState('delivery.addresses', getLocalState('delivery.addresses'))?.map(
    (address, id) => ({ ...address, id, userDefined: true })
  )
