import { styled } from '@mui/material/styles'

export const ImgHolder = styled('div')({
  '& > img': {
    maxWidth: '100%',
    height: 'auto',
  },
})

export const ActionButton = styled('div')({
  position: 'fixed',
  zIndex: 10,
  bottom: 90,
  right: 16,
})
