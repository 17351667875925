import GuestFormBox from 'components/guest-form-box'
import { CircularProgress, Grid, Typography } from '@mui/material'
import ButtonRound from 'components/button-round'
import { Link, useParams } from 'react-router-dom'
import { registerServiceHooks } from 'services/register/hooks'
import { useEffect, useState } from 'react'
import { useModal } from 'services/modal'
import ModalErrorContent from 'components/modal-error-content/ModalErrorContent'

const { useVerify } = registerServiceHooks

const EmailVerification = () => {
  const { token } = useParams()
  const [isSuccess, setIsSuccess] = useState(false)
  const { openModal } = useModal()
  const [verify, loading, , ,abort ] = useVerify({
    onSuccess: () => {
      setIsSuccess(true)
    },
    onError: error => {
      openModal({
        content: <ModalErrorContent error={error?.message} />
    })
    }
  })

  useEffect(() => {
    verify({ token })
    return () => abort()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  if (loading) return <CircularProgress size={'large'} />

  if (isSuccess) return (
    <GuestFormBox>
      <Grid container>
        <Grid item xs={12} mb={2}>
          <Typography variant={'h3'}>Email successfully verified</Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Typography variant={'body1'}>You are now ready to log in!</Typography>
        </Grid>
        <Grid item xs={12}>
          <ButtonRound
            variant={'contained'}
            color={'secondary'}
            component={Link}
            to={'/sign-in'}
            fullWidth
          >
            Take me there
          </ButtonRound>
        </Grid>
      </Grid>
    </GuestFormBox>
  )

  return null
}

export default EmailVerification
