import { useEffect, useState } from 'react'
import { Grid, Typography, MenuItem, CircularProgress } from '@mui/material'
import ButtonRound from 'components/button-round'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import SelectTransparent from 'components/select-transparent'
import { settingsServiceHooks } from 'services/settings/hooks'
import { setCurrencyShared } from 'store/currency.state'
import { useModal } from 'services/modal'
import { getCurrencyIndexById } from '../../../constants/currencies'

const { useSaveCurrency } = settingsServiceHooks

const ChangeCurrency = ({ currencies, activeCurrency = {} }) => {
  const [currency, setCurrency] = useState(getCurrencyIndexById(activeCurrency?.id))
  const { openModal } = useModal()

  const [saveCurrency, loading, , , abort] = useSaveCurrency({
    memoize: false,
    onSuccess: () => {
      setCurrencyShared({ ...currencies[currency] })
      // open modal
      openModal({
        title: 'Information',
        content: <Typography>Your currency was saved successfully.</Typography>
      })
    }
  })

  useEffect(() => {
    return () => abort()
  }, [abort])

  const handleSubmit = () => {
    const currencyItem = currencies[currency]
    saveCurrency(currencyItem?.id)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} mb={0.5}>
        <Typography variant={'h3'}>{'Currency & unit'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectTransparent
          value={currency}
          onChange={(event) => setCurrency(event.target.value)}
          IconComponent={KeyboardArrowRightIcon}
          color='secondary'
          fullWidth
        >
          {currencies.map(({ label }, idx) => (
            <MenuItem value={idx} key={`currency-${idx}`}>
              {label}
            </MenuItem>
          ))}
        </SelectTransparent>
      </Grid>

      <Grid item xs={12} mt={1}>
        <ButtonRound
          {...(loading && {
            endIcon: <CircularProgress color='primary' size={20} />
          })}
          disabled={loading}
          onClick={handleSubmit}
          color='secondary'
          variant='contained'
          fullWidth
        >
          {'Change currency & unit'}
        </ButtonRound>
      </Grid>
    </Grid>
  )
}

export default ChangeCurrency
