export const compose = (...fns) => (args) =>
	fns.reduceRight((arg, fn) => {
		return fn(arg)
	}, args)

export const composeAsync = (fns) => (args) => fns.reduceRight((chain, func) => chain.then(func), Promise.resolve(args))

export const promisify = (fn) => {
	return (...args) => {
		// return  wrapper function
		return new Promise((resolve, reject) => {
			const callback = (err, result) => {
				// our custom callback for fn
				if (err) {
					reject(err)
				} else {
					resolve(result)
				}
			}
			args.push(callback) // append custom callback at the end of the fn arguments
			fn.call(this, ...args) // call the original function
		})
	}
}

const saveItem = (key) => (value) => localStorage.setItem(key, value)
const getItem = (key) => () => localStorage.getItem(key)
const removeItem = (key) => () => localStorage.removeItem(key)

export const makeStorage = (...keys) => {
	return keys.reduce((memo, curr) => {
		memo[curr] = {
			set: saveItem(curr),
			get: getItem(curr),
			remove: removeItem(curr)
		}
		return memo
	}, {})
}
