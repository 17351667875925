import { styled } from '@mui/material/styles'

import { AppBar as MuiAppBar, Container as MuiContainer, Toolbar as MuiToolbar } from '@mui/material'

export const Wrapper = styled(MuiAppBar)(({ theme }) => ({
  boxShadow: 'none',
  flexDirection: 'row',
  height: 80,
  justifyContent: 'space-between'
}))

export const InnerAppBar = styled(MuiContainer)({
  display: 'flex',
  justifyContent: 'space-between'
})

export const Toolbar = styled(MuiToolbar)({ width: '100%' })
