import { Typography } from '@mui/material'

import {
  Header,
  Footer,
  Card,
  Row,
  CardContent,
  VolumeColumn,
  ContentColumn,
  StatusIndicator
} from './styles'

const ProductCard = ({
  volume,
  title,
  label,
  description,
  price,
  tag,
  onClick,
  cursor,
  noBorderRadius,
  statusFuture
}) => {
  return (
    <Card
      onClick={onClick}
      sx={{ cursor: cursor, borderRadius: noBorderRadius && 0 }}
    >
      {statusFuture && <StatusIndicator />}
      <CardContent>
        <VolumeColumn>{volume}</VolumeColumn>
        <ContentColumn>
          <Header>
            <Typography fontSize={'0.875rem'} fontWeight={600}>
              {label}
            </Typography>
            {tag}
          </Header>
          <Row>
            <Typography variant='h3' component={'div'} sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
          </Row>
          <Footer>
            <span>{description}</span>
            <span>{price}</span>
          </Footer>
        </ContentColumn>
      </CardContent>
    </Card>
  )
}

export default ProductCard
