import { styled } from '@mui/material/styles'
import DraftsIcon from '@mui/icons-material/Drafts'
import { Container, Grid } from '@mui/material'

export const ConfirmationWrapper = styled(Container)({
  textAlign: 'center'
})

export const ConfirmationContent = styled(Grid)({
  marginLeft: 16,
  marginRight: 16
})

export const ConfirmationIcon = styled(DraftsIcon)({
  margin: '0 auto 20px',
  width: 98,
  height: 98
})
