import { useTheme } from '@mui/material/styles'
import { useMediaQuery, IconButton } from '@mui/material'
import { ChevronLeft, Close } from '@mui/icons-material'
import { Drawer, CloseButtonWrapper } from './styles'

const ContentDrawer = ({ open, onClose, children, ...props }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      {...props}
      ModalProps={{
        sx: {
          '.MuiBackdrop-root': {
            backgroundColor: 'rgba(196, 196, 196, 0.4)',
            mixBlendMode: 'multiply',
            filter: 'blur(4px)',
          },
        },
      }}>
      <CloseButtonWrapper>
        <IconButton onClick={onClose}>
          {matches ? <Close /> : <ChevronLeft />}
        </IconButton>
      </CloseButtonWrapper>
      {children}
    </Drawer>
  )
}

export default ContentDrawer
