import { getAuthDataFromStorage } from 'utils/helpers/storage'
import { authResource } from './resource'

const grantType = process.env.REACT_APP_API_GRANT_TYPE
const clientId = process.env.REACT_APP_API_CLIENT_ID
const clientSecret = process.env.REACT_APP_API_CLIENT_SECRET

export const authService = {
  login:
    (signal) =>
    ({ username, password }) => {
      return authResource.post(
        '/oauth/token',
        { grant_type: grantType, client_id: clientId, client_secret: clientSecret, username, password },
        { signal: signal() }
      )
    },
  refreshToken: () => {
    return authResource.post('/oauth/token', {
      grant_type: 'refresh_token',
      client_id: clientId,
      client_secret: clientSecret,
      refresh_token: getAuthDataFromStorage().refresh_token,
      scope: ''
    })
  },
  forgotPassword:
    (signal) =>
    ({ email }) => {
      return authResource.post('/api/auth/forgot-password', { email }, { signal: signal() })
    },
  resetPassword: (signal) => (data) => {
    return authResource.post('/api/auth/reset-password', data, { signal: signal() })
  }
}
