import { CardContent } from 'views/register/styles'
import { CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import ButtonRound from 'components/button-round'
import { useModal } from 'services/modal'
import { registerServiceHooks } from 'services/register/hooks'
import RegistrationSteps from 'constants/registration-steps'

const { useConnect } = registerServiceHooks

const ConnectForm = ({ onNextStep, defaultValues, children }) => {
  const { openModal } = useModal()
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues
  })

  const [connect, loading] = useConnect({
    onError: (error) => {
      openModal({
        content: (
          <Grid>
            <Typography>{error?.message}</Typography>
          </Grid>
        )
      })
    },
    onSuccess: ({ data }) => {
      const { success } = data
      if (success) {
        onNextStep(RegistrationSteps.Success, {})
      } else {
        openModal({
          content: (
            <Grid>
              <Typography>Fail to recognize the error</Typography>
            </Grid>
          )
        })
      }
    }
  })

  const onSubmit = async ({ customerNumber }) => {
    const { firstName, lastName, email, country, password, passwordConfirmation } =
      defaultValues
    try {
      connect({
        first_name: firstName,
        last_name: lastName,
        email,
        country,
        password,
        password_confirmation: passwordConfirmation,
        customer_number: customerNumber
      })
    } catch (error) {
      console.log({ error })
    }
  }

  const goToCreateUser = () => {
    onNextStep(RegistrationSteps.Create, defaultValues)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <Typography>Are you already a customer?</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Through your client reference we can connect your order history and
              preferences.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register('customerNumber', {
                required: { value: true, message: 'Customer number is required' }
              })}
              label='Client no.'
              fullWidth
              error={!!errors['customerNumber']}
              helperText={errors['customerNumber']?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ButtonRound
              type={'submit'}
              color='secondary'
              variant='contained'
              fullWidth
              size={'large'}
              disabled={!watch('customerNumber') || loading}
              endIcon={loading && <CircularProgress color='secondary' size={20} />}
            >
              Connect me
            </ButtonRound>
          </Grid>
          <Grid item xs={6}>
            <ButtonRound
              disabled={loading}
              type={'button'}
              onClick={goToCreateUser}
              color='secondary'
              variant='contained'
              fullWidth
              size={'large'}
            >
              I'm a new user
            </ButtonRound>
          </Grid>
        </Grid>
        {children && (
          <Grid container pt={2}>
            <Grid item>{children}</Grid>
          </Grid>
        )}
      </CardContent>
    </form>
  )
}

export default ConnectForm
