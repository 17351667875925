import { ButtonBase, styled } from '@mui/material'

const ButtonBaseCard = styled(ButtonBase)({
  width: '100%',
  borderRadius: 5,
  textAlign: 'unset',
})

export default ButtonBaseCard

