const mockCarrierCompanies = [
  {
    id: '85ff7a65-e910-4c1c-baf5-c22c637edf65',
    label: 'Most flexible',
    carrierName: 'Jonker & Schut',
    deliveryDuration: '2 working days',
    options: ['Taillift option', 'Custom delivery time'],
  },
  {
    id: '6a65f7ef-6cc4-4507-9dca-e1d260adfa69',
    label: 'Most flexible',
    carrierName: 'Jonker & Schut',
    deliveryDuration: '2 working days',
    options: ['Taillift option', 'Custom delivery time'],
  },
  {
    id: 'f3154fae-77b6-4f5f-bfa9-5f7bac487a51',
    label: 'Most flexible',
    carrierName: 'Jonker & Schut',
    deliveryDuration: '2 working days',
    options: ['Taillift option', 'Custom delivery time'],
  },
  {
    id: '33345aad-5ec9-4023-ab07-0bcc9f463d4a',
    label: 'Most flexible',
    carrierName: 'Jonker & Schut',
    deliveryDuration: '2 working days',
    options: ['Taillift option', 'Custom delivery time'],
  },
  {
    id: 'f39d06ce-794a-4c86-b5c1-02cfbf3de81a',
    label: 'Most flexible',
    carrierName: 'Jonker & Schut',
    deliveryDuration: '2 working days',
    options: ['Taillift option', 'Custom delivery time'],
  },
]

export default mockCarrierCompanies
