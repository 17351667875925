import { ListItemIcon as MuiListItemIcon } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Drawer as MuiDrawer,
  ListItemButton as MuiListItemButton
} from '@mui/material'
import backgroundImage from '../../assets/images/app-drawer-header.jpg'

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'compact'
})(({ theme, compact }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  },
  '& .MuiDrawer-paper': {
    border: 'none',
    backgroundColor: theme.palette.navigation.main,
    width: compact ? 70 : 228,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}))

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  height: 80,
  paddingTop: '1.1rem',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  color: theme.palette.common.white,
  fontSize: '2.4rem',
  fontWeight: 600
}))

export const ToggleCompactButtonWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'compact'
})(({ theme, compact }) => ({
  marginTop: 'auto',
  textAlign: compact ? 'center' : 'right',
  padding: theme.spacing(1),
  svg: {
    color: theme.palette.common.white
  }
}))

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  color: theme.palette.common.white,
  whiteSpace: 'nowrap',
  alignItems: 'center',
  backgroundColor: theme.palette.navigation.main,
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.standard
  }),
  '&.active': {
    backgroundColor: theme.palette.navigation.active,
    span: {
      fontWeight: 700
    }
  },

  svg: {
    fill: theme.palette.common.white
  }
}))

export const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  color: theme.palette.common.white
}))
