export const carrierTimeOptions = {
  pre0100: 'Before 1:00 a.m.',
  pre0200: 'Before 2:00 a.m.',
  pre0300: 'Before 3:00 a.m.',
  pre0400: 'Before 4:00 a.m.',
  pre0500: 'Before 5:00 a.m.',
  pre0600: 'Before 6:00 a.m.',
  pre0700: 'Before 7:00 a.m.',
  pre0800: 'Before 8:00 a.m.',
  pre0900: 'Before 9:00 a.m.',
  pre1000: 'Before 10:00 a.m.',
  pre1100: 'Before 11:00 a.m.',
  pre1200: 'Before 12:00 a.m.',
  pre1300: 'Before 1:00 p.m.',
  pre1400: 'Before 2:00 p.m.',
  pre1500: 'Before 3:00 p.m.',
  pre1600: 'Before 4:00 p.m.',
  pre1700: 'Before 5:00 p.m.',
  pre1800: 'Before 6:00 p.m.',
  pre1900: 'Before 7:00 p.m.',
  pre2000: 'Before 8:00 p.m.',
  pre2100: 'Before 9:00 p.m.',
  pre2200: 'Before 10:00 p.m.',
  pre2300: 'Before 11:00 p.m.',
  pre2400: 'Before 12:00 p.m.',
  standard: 'Standard delivery'
}

export const sortTimeOptions = (values) => {
  const anyNonDigitValues = values.filter((v) => v.match(/^\D+$/))
  const formattedValues = values
    .filter((v) => !v.match(/^\D+$/))
    .map((v) => v.replace(/\D+/, ''))
    .sort((a, b) => a - b)
    .map((v) => `pre${v}`)
  return [...anyNonDigitValues, ...formattedValues]
}
