export const getUUID = (prefix) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let text = ''

  for (let i = 0; i < 24; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))

  if (prefix) text = prefix + '-' + text

  return text
}
