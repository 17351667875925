import { Typography, Grid } from '@mui/material'
import { Global } from '@emotion/react'

import { Root } from './styles'
import { getState } from 'store/ui.store'
import { formatCurrency } from 'utils/helpers/formatting'

const formatPrice = (price) => {
  const { currency } = getState('settings')
  if (currency?.weight === 'lb') {
    return `${currency?.sign} ${formatCurrency(price, 0)}${currency?.additionalSign}`
  }
  return `${currency?.sign} ${formatCurrency(price)}`
}

const CartTotal = ({ sum = 0 }) => (
  <Root container p={3} pb={11.5} justifyContent={'space-between'}>
    <Grid item flexGrow={0}>
      <Typography>Total</Typography>
    </Grid>
    <Grid>
      <Typography variant={'h6'}>{formatPrice(sum)}</Typography>
    </Grid>
    <Global
      styles={`
        body {
          padding-bottom: 90px !important;
        }
      `}
    />
  </Root>
)

export default CartTotal
