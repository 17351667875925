import { makeStorage } from 'acato-utils/acato-utilities'

const { token: authToken, token_expires, refresh_token } = makeStorage('token', 'token_expires', 'refresh_token')

export const storeAuthData = (data) => {
  authToken.set(data.access_token)
  token_expires.set(data.expires_in)
  refresh_token.set(data.refresh_token)
  return getAuthDataFromStorage()
}
export const getAuthDataFromStorage = () => ({
  token: authToken.get(),
  token_expires: token_expires.get(),
  refresh_token: refresh_token.get()
})

export const removeAuthDataFromStorage = () => {
  authToken.remove()
  token_expires.remove()
  refresh_token.remove()
  return getAuthDataFromStorage()
}
