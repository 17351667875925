import { getState } from '../../store'
import { useCart } from '../../utils/hooks/cart'
import { Grid, Typography, Box } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { groupBy } from '../../utils/helpers/list'
import { TableLayout, CardLayout } from '../../features/cart'
import AppBar from '../../components/app-bar'
import { Main } from '../styles'
import CartEmpty from '../../components/cart-empty'

import { StyledSumRow } from './styles'
import ButtonRound from '../../components/button-round'
import { ConnectedToggleView } from '../../components/view-mode/connected.toggle.view'
import { ToggleViewMode } from '../../components/view-mode/toggle.view.mode'
import { Link } from 'react-router-dom'
import { formatCurrency } from 'utils/helpers/formatting'

const labels = {
  samples: 'Samples',
  contracts: 'Contract orders',
  spots: 'Spotlist orders'
}

const Cart = () => {
  const { items: cartItems, samples } = getState('cart')
  const { currency } = getState('settings')
  const { removeFromCart } = useCart()

  const removeElement = useCallback(
    (type, reference) => {
      removeFromCart({ type, reference })
    },
    [removeFromCart]
  )

  const renderView = useMemo(() => {
    const list = [...cartItems, ...samples]
    let totalPrice = 0

    if (!list?.length) return <CartEmpty />
    const formatPrice = (price) => {
      if (currency?.weight === 'lb') {
        return `${currency?.sign} ${formatCurrency(price, 0)}${
          currency?.additionalSign
        }`
      }
      return `${currency?.sign} ${formatCurrency(price)}`
    }

    const convertKilogramsToPounds = (numKgs) =>
      currency?.weight === 'lb' ? numKgs * 2.20462262 : numKgs

    return (
      <Grid>
        {Object.entries(groupBy('cartCategory', list)).map(
          ([origin, products], idx) => {
            const totalCategoryPrice = products.reduce((accelerator, el) => {
              if (el.price === 'free') return accelerator
              return (
                el.price * el.amount * convertKilogramsToPounds(el.packingWeight) +
                accelerator
              )
            }, 0)

            totalPrice += totalCategoryPrice

            return (
              <Box mb={4} key={`country-${idx}`}>
                <Box mb={2} key={`country-${idx}`}>
                  <Typography variant='h3'>{labels[origin]}</Typography>
                </Box>
                <Grid container spacing={2}>
                  <ToggleViewMode>
                    <CardLayout
                      view='grid'
                      elements={products}
                      onDelete={removeElement}
                    />
                    <TableLayout
                      view='table'
                      elements={products}
                      onDelete={removeElement}
                    />
                  </ToggleViewMode>
                </Grid>
                <StyledSumRow>
                  <Grid container justifyContent={'space-between'} p={1}>
                    <Grid item>
                      <Typography>Total from {origin}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontWeight={700}>
                        {origin === 'samples'
                          ? 'free'
                          : formatPrice(totalCategoryPrice)}
                      </Typography>
                    </Grid>
                  </Grid>
                </StyledSumRow>
              </Box>
            )
          }
        )}
        <StyledSumRow>
          <Grid container justifyContent={'space-between'} p={1}>
            <Grid item>
              <Typography>Total order amount</Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight={700}>{formatPrice(totalPrice)}</Typography>
            </Grid>
          </Grid>
        </StyledSumRow>
        <Grid container pt={3}>
          <Grid item xs={12}>
            <Typography>
              The delivery costs are based on pallets of 10 bags
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Typography>
              A full pallet will get you the most out of your delivery costs
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <ButtonRound
              color={'secondary'}
              variant={'contained'}
              component={Link}
              to={'/checkout'}
            >
              Go to checkout
            </ButtonRound>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [
    cartItems,
    currency?.weight,
    currency?.additionalSign,
    currency?.sign,
    removeElement,
    samples
  ])

  return (
    <>
      <AppBar title={'Cart'}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <ConnectedToggleView />
          </Grid>
        </Grid>
      </AppBar>
      <Main>
        <Box mb={5}>{renderView}</Box>
      </Main>
    </>
  )
}

export default Cart
