import { useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GuestFormBox from 'components/guest-form-box'
import RegistrationForm from 'components/forms/registration-form'
import RegistrationSteps from 'constants/registration-steps'
import ConnectForm from 'components/forms/connect-form/ConnectForm'
import { Link as MuiLink, Typography } from '@mui/material'
import CreateUserForm from 'components/forms/create-user-form'
import { setPartialState } from 'store/ui.store'

const BackToLogin = () => (
  <MuiLink component={Link} to={'/sign-in'} color={'secondary'}>
    Go back to login
  </MuiLink>
)

const _renderStepForm = ({ step, updateStep, external, values }) => {
  switch (step) {
    case RegistrationSteps.Registration:
      return {
        title: 'Register today',
        component: (
          <RegistrationForm onNextStep={updateStep} defaultValues={values}>
            {!external && <BackToLogin />}
          </RegistrationForm>
        )
      }

    case RegistrationSteps.Connect:
      return {
        title: 'Connect Me',
        component: (
          <ConnectForm onNextStep={updateStep} defaultValues={values}>
            {!external && <BackToLogin />}
          </ConnectForm>
        )
      }

    case RegistrationSteps.Create:
      return {
        title: 'Create Account',
        component: (
          <CreateUserForm onNextStep={updateStep} defaultValues={values}>
            {!external && <BackToLogin />}
          </CreateUserForm>
        )
      }

    case RegistrationSteps.Success:
      return {
        title: 'Email Confirmation',
        component: (
          <Typography>
            Please check your mailbox to verify your e-mail address.
          </Typography>
        )
      }

    default:
      return {
        title: 'Create Account',
        component: null
      }
  }
}

const Register = ({ external = false }) => {
  const [state, setState] = useState({
    step: RegistrationSteps.Registration,
    values: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      password: '',
      passwordConfirmation: '',
      customerNumber: '',
      companyName: '',
      size: ''
    }
  })

  const updateStep = (step, values) => {
    setState({ step, values })
  }

  const { title, component } = _renderStepForm({
    step: state.step,
    external,
    updateStep,
    values: state.values
  })

  useLayoutEffect(() => {
    if (external) {
      // set the marketing gtm id:
      setPartialState('gtm.id')(process.env.REACT_APP_MARKETING_GTM_ID)
      document.documentElement.style.setProperty('--color-background', 'white')
    }
    return () => {
      // reset to the default gtm id:
      external && setPartialState('gtm.id')(process.env.REACT_APP_GTM_ID)
    }
  }, [external])

  return (
    <GuestFormBox nostyles={external} title={title}>
      {component}
    </GuestFormBox>
  )
}

export default Register
