import { Collapse, Typography } from '@mui/material'
import { DrawerHeader } from './styles'
const AppDrawerHeader = ({ compact }) => {
  return (
    <DrawerHeader>
      <Collapse in={!compact} orientation='vertical' collapsedSize={60}>
        <Typography variant='h1' component='span'>
          Trabocca
        </Typography>
      </Collapse>
    </DrawerHeader>
  )
}

export default AppDrawerHeader
