import { MenuItem } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { BagsPrice } from './styles'

export const BagsPriceSelect = ({ value, onChange }) => {
  const options = [1, 5, 10, 50, 150]

  return (
    <BagsPrice
      value={value}
      onChange={(e) => onChange(e.target.value)}
      IconComponent={KeyboardArrowDown}
    >
      {options.map((value) => (
        <MenuItem key={value} value={value}>
          {`Price ${value}+ bags`}
        </MenuItem>
      ))}
    </BagsPrice>
  )
}
