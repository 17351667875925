import { useNavigate } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import AppBar from 'components/app-bar'
import BackLink from 'components/back-link'
import { Main } from '../styles'
import CartController from '../../components/cart-controller/CartController'

const OrderHistory = () => {
  const navigate = useNavigate()

  return (
    <>
      <AppBar title={'Order History'}>
        <Grid container justifyContent={'flex-end'} alignItems={'center'}>
          <Grid item>
            <CartController />
          </Grid>
        </Grid>
      </AppBar>
      <Main>
        <BackLink onClick={() => navigate(-1)} />
        <Box mb={5}>
          <Typography>Order History page</Typography>
        </Box>
      </Main>
    </>
  )
}

export default OrderHistory
