export const linkCompanyContent = {
  modal: {
    title: 'Link your company',
    body: 'If you have your client ID, you can link your account to your company (this may take a couple of hours). If you do not know your client ID, you can send us a request to link the company for you.',
    findId:
      '💡 You can find your 5 digit client ID on your invoice(s) in the “Please make payment with reference to: IBO****** (invoice number) and ***** (client ID)” sentence.',
    cannotFindId: "I can't find my client ID",
    enterClientId: 'Enter your client ID',
    confirmLink: 'Confirm Link'
  },
  linkSuccess: {
    title: 'We will link your company as soon as possible!',
    body: 'Thank you'
  },
  requestSent: {
    title: 'ID request sent',
    body: {
      loading: 'Please wait...',
      success:
        'Thank you for your request. We will link your account to your company (this may take up to 5 working days) and send you your client ID.',
      error: 'We were unable to link your company. Please try again later.'
    }
  },
  action: 'Link my company'
}
