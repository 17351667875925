import { IconButton, styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

export const GridWrapper = styled(DataGrid)({
  display: 'flex',
  width: '100%',
  margin: '16px'
})

export const StyledDeleteIconButton = styled(IconButton)({
  marginRight: -8,
  marginTop: -14,
  marginBlock: -5
})
