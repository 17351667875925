import { Card as MuiCard, styled } from '@mui/material'

export const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clean'
})(({ clean }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: clean ? 'start' : 'center',
  justifyContent: 'center',
  zIndex: 1002
}))

export const Card = styled(MuiCard, {
  shouldForwardProp: (prop) => prop !== 'clean'
})(({ clean }) => ({
  border: clean ? 'none' : '1px solid #EAE4D4',
  boxShadow: clean ? 'none' : '0px 2px 12px 1px rgb(234 228 212 / 46%)',
  maxWidth: '100%',
  width: 400,
  padding: 32
}))

export const TitleWrapper = styled('div')({
  display: 'flex',
  marginBottom: 15
})
