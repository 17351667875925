import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import { colors } from 'constants'

const theme = createTheme({
  palette: {
    ...colors
  },
  typography: {
    fontFamily: "'GT Walsheim', sans-serif",
    fontWeight: 400,
    h1: {
      fontWeight: 700,
      fontSize: '2.25rem',
      fontFamily: "'Adelle', serif;"
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.625rem',
      fontFamily: "'Adelle', serif;",
      lineHeight: 1.2692
    },
    h3: {
      fontSize: '1.375rem',
      fontWeight: 600
    },
    h6: {
      fontSize: '1.125rem',
      lineHeight: '1rem',
      fontWeight: 700,
      fontFamily: "'Adelle', serif;"
    },
    body1: {
      lineHeight: '22px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: `var(--color-background, #F3EFE6)`
        }
      }
    },

    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 12px 1px rgba(234, 228, 212, 0.464898)',
          border: `1px solid ${colors.sand.main}`,
          borderRadius: 5,
          minHeight: '5rem'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 5
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          '.MuiToggleButton-root': {
            marginLeft: 4,
            marginRight: 4
          },
          '.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5
          },
          '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            marginLeft: 4,
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.sand.main
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 41,
          backgroundColor: colors.sand.main,
          '.MuiTabs-indicator': {
            bottom: '20%',
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            '&:after': {
              position: 'absolute',
              content: '""',
              width: 'calc(100% - 32px)',
              height: 2,
              backgroundColor: colors.primary.main
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fff',
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 12,
          paddingRight: 12,
          borderRadius: 5,
          '.MuiSvgIcon-root': {
            marginRight: 4
          },
          '.custom-select &': {
            boxShadow: '0px 2px 4px rgba(191, 191, 191, 0.5)',
            border: '1px solid rgba(55, 71, 79, 0.6)',
            padding: '17.5px 48px 17.5px 27px'
          },
          '.custom-select-small &': {
            padding: '7.5px 48px 7.5px 27px'
          },
          '.custom-select-xsmall &': {
            padding: '3px 12px 3px 12px'
          },
          '.custom-select.Mui-error &': {
            borderColor: red['700']
          },
          '.custom-select-transparent &': {
            backgroundColor: 'transparent!important'
          }
        },
        icon: {
          '.custom-select &': {
            right: 10,
            color: colors.tertiary.contrastText
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            borderRadius: 5
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: 5
          },
          '&.custom-select': {
            '&:not(.Mui-focused):not(:hover)': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent'
              }
            }
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiMenu-paper': {
            borderRadius: 5
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 41,
          fontSize: '1.125rem',
          textTransform: 'none',
          padding: '5px 16px 9px',
          flexGrow: 1,
          '&.Mui-selected': {
            fontWeight: 700,
            borderBottom: 'none',
            color: 'inherit',
            backgroundColor: colors.background.main,
            borderRadius: '9px 9px 0px 0px'
          },
          '&:after': {
            content: '""'
          }
        }
      }
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          margin: '0!important'
        },
        columnHeaderTitle: {
          fontSize: '0.75rem',
          fontWeight: 600
        },
        columnHeaders: {
          borderBottom: 'none',
          lineHeight: 36
        },
        columnHeader: {
          paddingLeft: 6,
          paddingRight: 6,
          '&:focus': {
            outline: 'none'
          }
        },
        columnSeparator: {
          display: 'none'
        },

        cell: {
          '&:focus, &:active, &:focus-within': {
            outline: 'none'
          },
          '&.MuiDataGrid-cell--editing': {
            '&:focus, &:active, &:focus-within': {
              outline: 'none'
            },
            backgroundColor: 'transparent',
            outline: 'none',
            boxShadow: 'none',
            alignItems: 'center',
            paddingLeft: 10,
            paddingRight: 10
          }
        },
        row: {
          margin: '1px 0',
          backgroundColor: '#ffffff',
          ':hover': {
            backgroundColor: colors.sand.main
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: 8,
          borderRadius: '0 !important',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:before': {
            content: 'none'
          },
          '&:last-child': {
            marginBottom: 0
          },
          '&.Mui-expanded': {
            margin: 0,
            marginBottom: 8
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 7px 0 11px',
          minHeight: '44px',
          borderRadius: 5,
          backgroundColor: colors.white.main,
          '&.Mui-expanded': {
            minHeight: '44px'
          }
        },
        content: {
          margin: '11px 0',
          '&.Mui-expanded': {
            margin: '11px 0'
          }
        },
        expandIconWrapper: {
          color: colors.secondary.main
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          marginTop: 8,
          padding: '16px 28px 16px 16px',
          borderRadius: 5,
          backgroundColor: colors.sand.main
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 10
        },
        title: {
          display: 'inline-flex',
          fontSize: 18,
          fontWeight: 700,
          alignItems: 'center'
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: colors.navigation.mainTransparent
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: 24,
          height: 24,
          marginRight: -8,
          circle: {
            r: 11,
            stroke: colors.navigation.main,
            fill: 'transparent',
            strokeWidth: 1
          },

          text: {
            transform: 'translateY(1px)',
            fontWeight: 700,
            fontSize: 16,
            fill: colors.navigation.main
          },

          '&.Mui-active': {
            width: 26,
            height: 26,
            circle: {
              fill: colors.navigation.main,
              strokeWidth: 0
            },

            text: {
              fill: colors.navigation.contrastText
            }
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-input': {
            padding: '8.5px 14px'
          },
          '.MuiInputLabel-root': {
            paddingRight: 1,
            color: 'rgba(55, 71, 79, 0.6)',
            fontStyle: 'italic',
            transform: 'translate(14px, 9px) scale(1)'
          },
          '.MuiInputLabel-shrink': {
            color: 'rgba(55, 71, 79, 0.6)',
            fontStyle: 'italic',
            transform: 'translate(14px, -9px) scale(0.75)'
          },
          '.MuiInputBase-root': {
            borderRadius: 5
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(55, 71, 79, 0.6)'
          }
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          paddingTop: 4,
          '&.active:after': {
            content: '""',
            position: 'absolute',
            width: 5,
            transform: 'translateX(-50%)',
            borderRadius: '50%',
            height: 5,
            left: '50%',
            bottom: 8,
            backgroundColor: colors.primary.main
          }
        },
        label: {
          fontSize: 10,
          lineHeight: '28px'
        }
      }
    }
  }
})

export default theme
