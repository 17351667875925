import { Button, Grid, IconButton, styled, TextField } from '@mui/material'

export const Root = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  transform: 'translateY(-100%)',
  boxShadow: '0px -3px 12px rgba(0, 0, 0, 0.1)'
}))

export const StyledIconButton = styled(IconButton)({
  fontSize: 32.5
})

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: 5,
  '& input': {
    textAlign: 'center',
    paddingRight: '0 !important'
  }
}))

export const StyledSnackbarContainer = styled(Grid)({
  position: 'absolute',
  bottom: 'calc(100% + 10px)',
  width: '100%'
})

export const ReserveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.pine.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main
  }
}))
