import { Grid, Typography } from '@mui/material'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { Card, ButtonBase } from './styles'

const Option = ({ label, isActive, onClick, ...restProps }) => {
  return (
    <ButtonBase {...restProps} onClick={onClick}>
      <Card active={isActive ? 1 : 0}>
        <Grid container alignItems={'center'} flexWrap={'nowrap'}>
          <Grid item mr={2} alignItems={'center'} display={'flex'}>
            {isActive ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
          </Grid>
          <Grid item flexGrow={1}>
            <Typography>{label}</Typography>
          </Grid>
        </Grid>
      </Card>
    </ButtonBase>
  )
}

export default Option
