import { getAuthDataFromStorage } from 'utils/helpers/storage'
import { currencies } from '../constants/currencies'
import { createSharedState } from '../acato-utils'
import { isMobile } from 'react-device-detect'

export const defaultCurrencyState = () => {
  const storedCurrency = localStorage.getItem('settings.currency')
  if (storedCurrency) {
    return JSON.parse(storedCurrency)
  }
  return currencies[0]
}

export const getLocalState = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key)
  if (storedValue) {
    return JSON.parse(storedValue)
  }
  return defaultValue || []
}

const { token, token_expires, refresh_token } = getAuthDataFromStorage()

const [setPartialState, useSharedState, getState] = createSharedState({
  auth: { token, token_expires, refresh_token, user: null, initialized: true },
  ui: {
    theme: '',
    drawer: {
      compact: localStorage.getItem('ui.drawer.compact') === '1' ? true : false
    },
    viewmode: isMobile ? 'grid' : localStorage.getItem('ui.viewmode') || 'table'
  },
  settings: { currency: defaultCurrencyState() },
  cart: {
    items: getLocalState('cart.items'),
    samples: getLocalState('cart.samples')
  },
  gtm: { id: null },
  delivery: { addresses: getLocalState('delivery.addresses', []) }
})

export { setPartialState, useSharedState, getState }
