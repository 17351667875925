import { useState } from 'react'
import { SwiperSlide } from 'swiper/react'

import { Swiper, RootGrid } from './styles'

import 'swiper/css'
import theme from '../../theme'

const CarouselCardSelect = ({ slides = [], renderSlide, keyExtractor }) => {
  const [swiperRef, setSwiperRef] = useState(null)

  const goToSlideByIndex = (index) => {
    swiperRef.slideTo(index)
  }

  return (
    <RootGrid overflow={'hidden'} pb={2}>
      <Swiper
        spaceBetween={16}
        onSwiper={(swiper) => setSwiperRef(swiper)}
        breakpoints={{
          0: {
            slidesPerView: 1,
            centeredSlides: true
          },
          [theme.breakpoints.values.sm]: {
            slidesPerView: 2,
            centeredSlides: false
          },
          [theme.breakpoints.values.md]: {
            slidesPerView: 3,
            centeredSlides: false
          }
        }}
      >
        {slides.map((slide, index) => {
          return (
            <SwiperSlide key={keyExtractor ? keyExtractor(slide) : slide.id} onClick={() => goToSlideByIndex(index)}>
              {renderSlide(slide)}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </RootGrid>
  )
}

export default CarouselCardSelect
