const customDeliveryTimeOptions = [
  {
    value: 0,
    label: {
      time: 'Before 8:00 AM',
      price: '+ € 60',
    },
  },
  {
    value: 1,
    label: {
      time: 'Before 9:00 AM',
      price: '+ € 50',
    },
  },
  {
    value: 2,
    label: {
      time: 'Before 10:00 AM',
      price: '+ € 40',
    },
  },
  {
    value: 3,
    label: {
      time: 'Before 13:00 AM',
      price: '+ € 30',
    },
  },
  {
    value: 4,
    label: {
      time: '1 hour timeslot',
      price: '+ € 60',
    },
  },
  {
    value: 5,
    label: {
      time: '2 hour timeslot',
      price: '+ € 50',
    },
  },
  {
    value: 6,
    label: {
      time: 'Daypart timeslot',
      price: '+ € 30',
    },
  },
]

export default customDeliveryTimeOptions
