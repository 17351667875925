import { styled } from '@mui/material/styles'
import { IconButton as MuiIconButton } from '@mui/material/'

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: 0,
  marginLeft: -16,
  '.MuiSvgIcon-root': {
    fontSize: '3rem'
  }
}))
