export const authorization = (getTokenFn) => (config) => {
  config.headers['Authorization'] = `Bearer ${getTokenFn()}`
  return config
}

const responseHandler = (errorHandlerFn, axiosResource) => async (err) => {
  if (!err.response) return Promise.reject(err)
  const statusCode = err.response.status
  if (errorHandlerFn) {
    const handlerObj = errorHandlerFn(statusCode)
    if (handlerObj) {
      return handlerObj.handler(err.response, axiosResource)
    } else {
      return Promise.reject(err.response)
    }
  }
  return Promise.reject(err.response)
}
/**
 * Adds errorhandlers to an axios instance.
 * Note that the order of the handlers matter, because the first match will be used to handle the specific error.
 * So to be sure your handler will be executed, add handlers from specific to more generic.
 * Example:
  ```addErrorHandler({
        match: 401,
        handler:...
    },
    {
        match: /40/,
        handler: ...
    }
 )```

 This errorhandler will first match a 401 error, then all other 40* errors.
 * @param {*} handlers A handler object consists of a match (exact match or regex) property and a handler (the function) prop.
 * @returns a function that matches a statuscode against the given handlers.
 */
export const addErrorHandlers = (...handlers) => {
  const errorHandlers = handlers.map((h) => ({ ...h, isRegEx: h.match instanceof RegExp }))
  return (errorCode) => {
    return errorHandlers.find(({ match: matcher, isRegEx }) => {
      return isRegEx ? `${errorCode}`.match(matcher) : errorCode === matcher
    })
  }
}

export const applyInterceptor = (request, response) => (axiosResource) => {
  if (request) {
    axiosResource.interceptors.request.use(request, (err) => Promise.reject(err))
  }
  if (response) {
    axiosResource.interceptors.response.use((res) => res, responseHandler(response, axiosResource))
  }
  return axiosResource
}
