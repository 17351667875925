import { Swiper as NativeSwiper } from 'swiper/react'
import { Grid, styled } from '@mui/material'

export const RootGrid = styled(Grid)(
  ({ theme }) => ({
    marginLeft: -16,
    marginRight: -16,

    [theme.breakpoints.up('sm')]: {
      marginLeft: -24,
      marginRight: -24,
    },

    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  }),
)

export const Swiper = styled(NativeSwiper)({
  overflow: 'visible',
  width: '60%',

  '.swiper-slide:first-of-type button': {
    marginLeft: 'calc(-38% - -24px)',
    width: 'calc(137% - 24px)',
  },
}, ({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '80%',

    '.swiper-slide:first-of-type button': {
      marginLeft: 'calc(-26% - -24px)',
      width: 'calc(126% - 24px)',
    },
  },
  [theme.breakpoints.up('md')]: {
    width: '100%',

    '.swiper-slide:first-of-type button': {
      marginLeft: '0',
      width: '100%',
    },
  },
}))
