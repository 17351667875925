import { Navigate } from 'react-router-dom'
import { routes } from 'constants'

const AppRoute = ({ children, guarded = false, redirectOnAuth = null, auth }) => {
  if (auth.token && redirectOnAuth) {
    return <Navigate to={redirectOnAuth} />
  }

  if (!auth.token && guarded) {
    const { path } = routes.find((route) => route.signIn)
    return <Navigate to={path} />
  }

  return children
}
export default AppRoute
