import { styled } from '@mui/material'
import { CircularProgress, IconButton as MuiIconButton } from '@mui/material'

export const Wrapper = styled('div')({
  display: 'flex',
  height: '100%',
  width: 70,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
})
export const Content = styled('div')(({ theme, label, progress }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  lineHeight: label ? 1.3 : progress ? 1.1 : 1.2,
}))
export const Progress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  svg: {
    circle: {
      stroke: theme.palette.text.primary,
    },
  },
}))

export const Large = styled('span')(({ theme, label }) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.h3.fontWeight,
  textAlign: 'center',
  backgroundColor: label ? theme.palette.tertiary.main : 'transparent',
  margin: label ? '4px 0' : 0,
  padding: label ? '2.5px 5px' : 0,
  borderRadius: label ? 5 : 0,
  minWidth: label ? 53 : 'auto',
  small: {
    fontSize: '0.75rem',
  },
}))
export const Small = styled('span')({
  fontSize: '0.75rem',
})

export const IconButton = styled(MuiIconButton)(({ position }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: 5,
  [position]: -11,
  '*': {
    pointerEvents: 'none',
  },
  '&.Mui-disabled': {
    pointerEvents: 'auto',
  },
}))
