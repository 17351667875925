import { Grid } from '@mui/material'
import { useSharedState } from '../../store'

import { StyledBadge } from './styles'
import CustomIcon from '../custom-icon'
import { useMemo } from 'react'

const CartBottomController = () => {
  const { items, samples } = useSharedState('cart')

  const totalAmount = useMemo(() => {
    return items?.reduce((memo, curr) => memo + curr.amount, samples?.length || 0)
  }, [items, samples])

  return (
    <Grid container alignItems={'center'} width={'auto'}>
      <CustomIcon icon={'cart_dark'} size={29} />
      <StyledBadge color={'primary'} badgeContent={totalAmount} />
    </Grid>
  )
}

export default CartBottomController
