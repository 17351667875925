import Spotlist from 'views/spotlist'
import OrderHistory from 'views/order-history'
import Settings from 'views/settings'
import Faq from 'views/faq'
import Contracts from 'views/contracts'
import Cart from 'views/cart'
import SignIn from 'views/sign-in'
import Register from '../views/register'
import ResetPassword from '../views/reset-password'

import AppLayout from 'components/app-layout'
import GuestLayout from 'components/guest-layout'
import EmailVerification from 'views/email-verification'
import ForgotPassword from 'views/forgot-password'
import NotFound from '../views/not-found'
import Checkout from '../views/checkout/Checkout'

const routes = [
  {
    path: '/',
    pageTitle: 'Spotlist',
    element: Spotlist,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/spotlist/:find_by_pbo',
    pageTitle: 'Spotlist Detail',
    element: Spotlist,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/account',
    pageTitle: 'Account',
    element: Settings,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/account/order-history',
    pageTitle: 'Account',
    element: OrderHistory,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/account/settings',
    pageTitle: 'Account',
    element: Settings,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/faq',
    pageTitle: 'Account',
    element: Faq,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/account/faq',
    pageTitle: 'Account',
    element: Faq,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/contracts',
    pageTitle: 'Contracts',
    element: Contracts,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/cart',
    pageTitle: 'Cart',
    element: Cart,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/checkout',
    pageTitle: 'Checkout',
    element: Checkout,
    guarded: true,
    layout: AppLayout
  },
  {
    path: '/sign-in',
    signIn: true,
    pageTitle: 'Sign in',
    element: SignIn,
    guarded: false,
    redirectOnAuth: '/',
    layout: GuestLayout
  },
  {
    path: '/register',
    signIn: true,
    pageTitle: 'Sign in',
    element: Register,
    guarded: false,
    redirectOnAuth: '/',
    layout: GuestLayout
  },
  {
    path: '/iframe-register',
    signIn: true,
    pageTitle: '',
    element: () => <Register external />,
    guarded: false
  },
  {
    path: '/register/verify/:token',
    signIn: true,
    pageTitle: 'Email Verification',
    element: EmailVerification,
    guarded: false,
    redirectOnAuth: '/',
    layout: GuestLayout
  },
  {
    path: '/password-reset',
    signIn: true,
    pageTitle: 'Password reset',
    element: ForgotPassword,
    guarded: false,
    redirectOnAuth: '/',
    layout: GuestLayout
  },
  {
    path: '/password-reset/:token',
    signIn: true,
    pageTitle: 'Email Verification',
    element: ResetPassword,
    guarded: false,
    redirectOnAuth: '/',
    layout: GuestLayout
  },
  {
    path: '*',
    signIn: true,
    pageTitle: 'Email Verification',
    element: NotFound,
    guarded: true,
    layout: AppLayout
  }
]

export default routes
