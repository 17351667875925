import { styled, Select } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

export const GridWrapper = styled(DataGrid)({
  display: 'flex',
  width: '100%',
  margin: '16px',
  '& .MuiDataGrid-virtualScroller': {
    msOverflowStyle: 'none' /* for Internet Explorer, Edge */,
    scrollbarWidth: 'none' /* for Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* for Chrome, Safari, and Opera */
    }
  }
})

export const StatusIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -15,
  left: -15,
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '30px 30px 0 0',
  borderColor: `${theme.palette.pine.main} transparent transparent transparent`
}))

export const WarehouseSelect = styled(Select)(({ theme }) => ({
  maxWidth: '25rem',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  flexGrow: 1
}))

export const BagsPrice = styled(Select)(({ theme }) => ({
  maxWidth: '25rem',
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 1,
  flexGrow: 1,
  marginTop: 12,
  [theme.breakpoints.up('sm')]: {
    maxWidth: '25rem',
    marginTop: 0,
    marginLeft: 12
  }
}))
