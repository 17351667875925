import {
  CircularProgress,
  Grid,
  Link as MuiLink,
  TextField,
  Typography
} from '@mui/material'
import { useForm } from 'react-hook-form'
import ButtonRound from 'components/button-round'
import { Link } from 'react-router-dom'
import { authServiceHooks } from 'services/auth/hooks'
import { useModal } from 'services/modal'
import ModalErrorContent from 'components/modal-error-content/ModalErrorContent'
import { useEffect } from 'react'

const { useForgotPassword } = authServiceHooks

const ForgotPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    }
  })
  const { openModal } = useModal()
  const [sendRequest, isLoading, response, , abort] = useForgotPassword({
    onError: (error) => {
      openModal({
        content: <ModalErrorContent error={error?.message} />
      })
    }
  })

  const onSubmit = ({ email }) => {
    try {
      sendRequest({ email })
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => abort, [abort])

  return (
    <Grid container spacing={1} component={'form'} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={12} mb={1}>
        <Typography variant={'h2'}>Reset your password</Typography>
      </Grid>
      {response?.status !== 204 ? (
        <>
          <Grid item xs={12} mb={1}>
            <TextField
              {...register('email', {
                required: { value: true, message: 'Email is required' },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'The email field must be a valid email'
                }
              })}
              label='Email address'
              error={!!errors['email']}
              helperText={errors['email']?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <ButtonRound
              type={'submit'}
              variant={'contained'}
              color={'secondary'}
              size={'large'}
              fullWidth
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress color='secondary' size={20} />}
            >
              Send E-mail
            </ButtonRound>
          </Grid>
          <Grid item>
            <MuiLink component={Link} to={'/sign-in'} color={'secondary'}>
              Go back to login
            </MuiLink>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography mb={2}>
            Thank you for your submission. If an account with this email address
            exists, we will send them a reset password email.
          </Typography>
          <MuiLink component={Link} to={'/'} color={'secondary'}>
            Back to homepage
          </MuiLink>
        </Grid>
      )}
    </Grid>
  )
}

export default ForgotPasswordForm
