import ButtonRound from '../button-round'

const RequestForMediaButton = ({ body }) => (
  <ButtonRound
    href={`mailto:${'mytrabocca@trabocca.com'}?subject=Request for media&body=${body}`}
    variant={'outlined'}
    color={'secondary'}
    target={'_blank'}
    fullWidth
  >
    Request for media
  </ButtonRound>
)

export default RequestForMediaButton
