import React, { cloneElement, useMemo } from 'react'
import { useSharedState } from 'store/ui.store'

const isFunction = (maybeFn) => typeof maybeFn === 'function'

export const ToggleViewMode = ({ children }) => {
  const viewmode = useSharedState('ui.viewmode')
  const renderView = useMemo(() => {
    const foundChild = React.Children.toArray(children).find((child) => child?.props?.view === viewmode)
    return (foundChild && cloneElement(foundChild, { ...foundChild.props })) || null
  }, [children, viewmode])
  return isFunction(children) ? children(viewmode) : renderView || null
}
