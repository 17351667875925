import { GTMProvider, useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { useCallback, useMemo } from 'react'
import * as R from 'ramda'
import { getUUID } from './uuid'
import { setPartialState, useSharedState } from 'store/ui.store'

setPartialState('gtm.id')(process.env.REACT_APP_GTM_ID)

export const GoogleTagManagerProvider = ({ children }) => {
  const gtmId = useSharedState('gtm.id')
  console.log('Use gtm id:', gtmId)
  return <GTMProvider state={{ id: gtmId }}> {children}</GTMProvider>
}
const contractToGtm =
  (isBasketEvent = true) =>
  (item) => {
    return !isBasketEvent
      ? {
          ...spotToGtm(item),
          name: `${item?.origin}, ${item?.region}, ${item?.grade}`,
          price: item.price
        }
      : item
  }

const sampleToGtm = (item) => ({
  ...spotToGtm(item),
  price: 0,
  quantity: 1
})
const spotToGtm = (item) => ({
  name: item.name,
  id: item.orderId,
  sku: item.orderId,
  price: item.price,
  brand: item.origin,
  category: item.type,
  quantity: item.amount
})

const itemsToGtm = (isBasketEvent) =>
  R.ifElse(
    R.propEq('type', 'contract'),
    contractToGtm(isBasketEvent),
    R.ifElse(R.propEq('type', 'sample'), sampleToGtm, spotToGtm)
  )
const orderItemsToGtm = itemsToGtm(false)
const cartItemsToGtm = itemsToGtm(true)

const isContractType = R.propEq('type', 'contract')

const getEcommerceData = (action, itemsFn) => ({
  ecommerce: {
    currencyCode: ({ currencyCode }) => currencyCode,
    [action]: {
      products: (item) => item.map(itemsFn)
    }
  }
})
const getPurchaseEcommerceData = (action, itemsFn) => ({
  ecommerce: {
    [action]: {
      actionField: ({ revenue, affiliation, id }) => ({
        revenue,
        id,
        affiliation,
        tax: 0,
        shipping: 0
      }),
      products: ({ items }) => items.map(itemsFn)
    }
  }
})

const cartItemEvent = (actionType) => (data) => {
  // add or remove
  if (isContractType(data)) {
    return contractToGtm(true)(data)
  }
  return R.applySpec(getEcommerceData(actionType, cartItemsToGtm))([data])
}
const orderItemEvent = (actionType) => (items, totalPrice) => {
  const itemsFn = R.pipe(R.values, R.pluck('products'), R.flatten)
  //   const revenueFn = R.pipe(
  //     R.values,
  //     R.pluck('totalPrice'),
  //     R.filter(R.is(Number)),
  //     R.reduce((acc, next) => acc + next, 0)
  //   )

  const data = {
    id: getUUID('trabocca'),
    affiliation: 'MyTrabocca Portal',
    items: itemsFn(items),
    revenue: totalPrice
  }
  return R.applySpec(getPurchaseEcommerceData(actionType, orderItemsToGtm))(data)
}

export const useGoogleTagManager = () => {
  const sendDataToGTM = useGTMDispatch()

  const sendEvent = useCallback(
    (event, valueFormatter) =>
      (...data) => {
        sendDataToGTM({
          event,
          //value: valueFormatter ? valueFormatter(...data) : data
          ...(valueFormatter ? valueFormatter(...data) : data)
        })
      },
    [sendDataToGTM]
  )

  const onSpotListItemOpen = useMemo(() => sendEvent('open-details'), [sendEvent])
  const onAddToCart = useMemo(
    () => sendEvent('addToBasket', cartItemEvent('add'), [sendEvent]),
    [sendEvent]
  )
  const onRemoveFromCart = useMemo(
    () => sendEvent('removedBasket', cartItemEvent('remove')),
    [sendEvent]
  )
  const onPlaceOrder = useMemo(
    () => sendEvent('placedOrder', orderItemEvent('purchase')),
    [sendEvent]
  )
  const onRegister = useMemo(() => sendEvent('registerSuccess'), [sendEvent])

  return {
    onSpotListItemOpen,
    onAddToCart,
    onRemoveFromCart,
    onPlaceOrder,
    onRegister
  }
}
