import { useCallback } from 'react'
import { useAuth } from 'services/auth'
import { CircularProgress, TextField, Grid, Link as MuiLink } from '@mui/material'
import ButtonRound from 'components/button-round'
import GuestFormBox from 'components/guest-form-box'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

const SignIn = () => {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  })
  const { login, loading } = useAuth()

  const signInHandler = useCallback(
    async ({ email, password }) => {
      try {
        login({
          username: email,
          password
        })
      } catch (error) {
        console.log({ error })
      }
    },
    [login]
  )

  return (
    <GuestFormBox title='Login'>
      <form onSubmit={handleSubmit(signInHandler)}>
        <Grid mb={1}>
          <TextField
            {...register('email', {
              required: { value: true, message: 'Email is required field' },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format'
              }
            })}
            label='Email address'
            error={!!errors['email']}
            helperText={errors['email']?.message}
            fullWidth
          />
        </Grid>
        <Grid mb={2.5}>
          <TextField
            {...register('password', {
              required: { value: true, message: 'Email is required field' }
            })}
            fullWidth
            label='Password'
            type='password'
            error={!!errors['password']}
            helperText={errors['password']?.message}
          />
        </Grid>
        <ButtonRound
          disabled={loading}
          type={'submit'}
          color='secondary'
          variant='contained'
          endIcon={loading && <CircularProgress color='secondary' size={20} />}
          fullWidth
        >
          Sign in
        </ButtonRound>
        <Grid container pt={2} justifyContent={'space-between'}>
          <Grid item>
            <MuiLink component={Link} to={'/password-reset'} color={'secondary'}>
              Forgot password
            </MuiLink>
          </Grid>
          <Grid item>
            <MuiLink component={Link} to={'/register'} color={'secondary'}>
              Create account
            </MuiLink>
          </Grid>
        </Grid>
      </form>
    </GuestFormBox>
  )
}

export default SignIn
