import { useForm, Controller } from 'react-hook-form'
import { Button, FormControl, FormHelperText, Grid } from '@mui/material'
import { RemoveCircle as RemoveIcon, AddCircle as AddIcon } from '@mui/icons-material'

import { Root, StyledIconButton, StyledTextField, StyledSnackbarContainer, ReserveButton } from './styles'
import { forwardRef, useImperativeHandle, useState } from 'react'
import CustomSnackbar from 'components/custom-snackbar'

const WithCartControllers = forwardRef(
  ({ children, onSubmit = () => {}, step = 1, initialQuantity = 1, availabilityDate, max, disabled }, ref) => {
    const [notification, setNotification] = useState(null)
    const {
      handleSubmit,
      setValue,
      watch,
      formState: { errors },
      control
    } = useForm({
      defaultValues: {
        quantity: initialQuantity
      }
    })

    useImperativeHandle(ref, () => ({
      openNotification: (text) => {
        setNotification(text)
      }
    }))

    const onIncrease = () => {
      setValue('quantity', +watch('quantity') + step)
    }

    const onDecrease = () => {
      const currentValue = +watch('quantity')
      if (currentValue < 2) return null
      setValue('quantity', watch('quantity') - step)
    }

    const onChangeLimiter = (onChange) => (e) => {
      if (e.target.value < 1 && e.target.value !== '') return null
      onChange(e.target.value)
    }

    const onBlurLimiter = (onBlur) => (e) => {
      if (e.target.value > max) setValue('quantity', max)
      onBlur(e)
    }

    return (
      <Grid component={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Grid minHeight={'100vh'} pb={8}>
          {children}
        </Grid>
        <Grid height={0} container position={'sticky'} bottom={0}>
          <Root>
            <Grid container spacing={1.5} alignItems={'center'} p={1} pt={1.5} pr={2} pb={3}>
              <StyledSnackbarContainer container justifyContent={'center'}>
                <Grid item xs={10.7}>
                  <CustomSnackbar isOpen={!!notification} title={notification} onClose={() => setNotification(null)} />
                </Grid>
              </StyledSnackbarContainer>
              <Grid container item xs={6} alignItems={'center'} component={FormControl} error={!!errors['quantity']}>
                <Grid item xs={3} container alignItems={'center'} justifyContent={'center'}>
                  <StyledIconButton onClick={onDecrease}>
                    <RemoveIcon fontSize={'inherit'} color={'text'} />
                  </StyledIconButton>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    rules={{
                      max: {
                        value: max,
                        message:
                          max === 0
                            ? 'all available quantities already in the cart'
                            : `maximum available amount is ${max}`
                      },
                      min: {
                        value: 1,
                        message:
                          max === 0 ? 'all available quantities already in the cart' : "quantity can't be less then 1"
                      }
                    }}
                    name={'quantity'}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <StyledTextField
                        min={1}
                        type={'number'}
                        value={value}
                        onChange={onChangeLimiter(onChange)}
                        onBlur={onBlurLimiter(onBlur)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3} container alignItems={'center'} justifyContent={'center'}>
                  <StyledIconButton onClick={onIncrease}>
                    <AddIcon fontSize={'inherit'} color={'text'} />
                  </StyledIconButton>
                </Grid>
                {errors['quantity'] ? (
                  <Grid item xs={12}>
                    <FormHelperText mt={0}>{errors['quantity']?.message}</FormHelperText>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={6}>
                {availabilityDate ? (
                  <ReserveButton type={'submit'} color={'primary'} variant={'contained'} fullWidth disabled={disabled}>
                    Reserve
                  </ReserveButton>
                ) : (
                  <Button type={'submit'} variant={'contained'} fullWidth disabled={disabled}>
                    Add to cart
                  </Button>
                )}
              </Grid>
            </Grid>
          </Root>
        </Grid>
      </Grid>
    )
  }
)

export default WithCartControllers
