import { MenuItem, Select } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

const CustomSelect = ({
  value = '',
  onChange,
  options,
  placeholder = 'Select an option',
  renderLabel,
  className,
  size = 'regular',
  transparent = false,
  skipSelectBackground,
  ...rest
}) => {
  const classes = ['custom-select', `custom-select-${size}`, transparent && `custom-select-transparent`].join(' ')
  return (
    <Select
      {...rest}
      value={value}
      onChange={onChange}
      IconComponent={KeyboardArrowDown}
      className={classes}
      fullWidth
      displayEmpty
      MenuProps={{
        PaperProps: {
          sx: {
            marginTop: '8px',
            borderRadius: '5px',
            boxShadow: '0px 2px 4px rgba(191, 191, 191, 0.5)',
            '.MuiList-root': {
              paddingTop: '3px',
              paddingBottom: '3px'
            },
            '.MuiMenuItem-root': {
              margin: '6px 0',
              padding: '4px 16px',
              '&:hover': {
                backgroundColor: 'sand.main'
              },
              '&.Mui-selected': {
                backgroundColor: 'background.default'
              }
            }
          }
        }
      }}
      sx={{
        color: value === '' ? 'grey.400' : 'currentColor',
        '.MuiSelect-select': {
          backgroundColor: !skipSelectBackground && value !== '' ? 'background.default' : 'white.main'
        }
      }}
    >
      <MenuItem value={''} disabled sx={{ display: 'none' }}>
        {placeholder}
      </MenuItem>
      {options.map(({ value, label }) => (
        <MenuItem value={value} key={value}>
          {renderLabel ? renderLabel(label) : label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CustomSelect
