import { EnumShippingMethods } from '../components/forms/shipping-method/mockOptions'

export const cartCheckoutStepsSamplesOnly = [
  {
    id: 1,
    buttonLabel: 'Choose address'
  },
  {
    id: 2,
    buttonLabel: 'Place order'
  }
]

const cartCheckoutSteps = (shippingMethod) => [
  {
    id: 1,
    buttonLabel: 'Choose carriers'
  },
  ...(shippingMethod !== EnumShippingMethods.Own
    ? [
        {
          id: 2,
          buttonLabel: 'Go to check out overview'
        }
      ]
    : [
        {
          id: 2,
          buttonLabel: 'SKKSSKSSJSJSJ'
        }
      ]),
  {
    id: 3,
    buttonLabel: 'Place order'
  },
  {
    id: 4,
    buttonLabel: 'Place order'
  }
]

export default cartCheckoutSteps
