import {
  CardHeader as MuiCardHeader,
  CardContent as MuiCardContent,
  Grid,
  styled,
  Card as MuiCard,
} from '@mui/material'
import MuiCheckIcon from '@mui/icons-material/Check'
import { colors } from '../../constants'

export const Card = styled(MuiCard)({
  width: '100%',
})

export const CardHeader = styled(MuiCardHeader)({
    color: colors.tertiary.contrastText,
    textAlign: 'center',
  }, ({ active }) => ({
    background: active === 'true' ? colors.sand.main : colors.tertiary.main,
  }),
)

export const CardContent = styled(MuiCardContent)({
    color: colors.tertiary.contrastText,
  }, ({ active }) => ({
    background: active === 'true' ? colors.background.default : colors.white.main,
  }),
)

export const StyledUL = styled(Grid)({
  paddingTop: 7,
  paddingLeft: 5,
  marginBottom: 10,
  listStyle: 'none',
})

export const CheckIcon = styled(MuiCheckIcon)({
  width: 24,
  marginRight: 10.3,
})

export const HeaderCheckMark = styled(CheckIcon)({
  position: 'absolute',
  marginLeft: -29,
  marginRight: 5,
})
