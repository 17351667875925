import { useCallback } from 'react'
import ToggleView from 'components/toggle-view/ToggleView'
import { compose } from 'acato-utils/index'
import { setPartialState, useSharedState } from 'store/ui.store'

const saveViewPreference = compose(([lens, value]) => localStorage.setItem(lens, value), setPartialState('ui.viewmode'))

export const ConnectedToggleView = () => {
  const viewmode = useSharedState('ui.viewmode')
  const handleViewChange = useCallback((e, value) => {
    if (value) {
      saveViewPreference(value)
    }
  }, [])
  return <ToggleView value={viewmode} onChange={handleViewChange} />
}
