import { useEffect, useState } from 'react'
import { Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material'
import Option from '../../option'
import AddIcon from '@mui/icons-material/Add'

import { mockOptions } from './mockOptions'
import CarouselCardSelect from '../../carousel-card-select'
import AddressCard from '../../address-card'
import { AddAddressButton } from './styles'
import ButtonRound from '../../button-round'
import AddShippingAddress from '../add-shipping-address/AddShippingAddress'
import ModalContent from '../../modal-content'
import { useFormContext, Controller } from 'react-hook-form'
import { EnumShippingMethods } from './mockOptions'

const ShippingMethod = ({ addresses, onAddAddress, onSubmit, isKnownUser }) => {
  const { control, watch, setValue } = useFormContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const shippingAddressId = watch('shippingAddress')
  const shippingMethod = watch('shippingMethod')
  const onlySamples = watch('onlySamples')

  useEffect(() => {
    if (onlySamples === 'true')
      setValue('shippingMethod', EnumShippingMethods.Carrier)
  }, [onlySamples, setValue])

  useEffect(() => {
    if (addresses && addresses.length === 1) {
      setValue('shippingAddress', addresses[0]?.id)
    }
  }, [addresses, setValue])

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  return (
    <Grid container pb={5}>
      {onlySamples === 'false' && (
        <>
          <Grid item xs={12} mb={2.5}>
            <Typography variant={'h3'}>Choose shipping method</Typography>
          </Grid>
          <Grid container pb={1.2}>
            <Controller
              control={control}
              render={({ field: { value, onChange, onBlur }, formState }) => (
                <>
                  {mockOptions.map((option) => (
                    <Grid item xs={12} key={option.value}>
                      <Grid item xs={12} md={5} mb={1.5}>
                        <Option
                          isActive={value === option.value}
                          label={option.label}
                          onBlur={onBlur}
                          onClick={() => onChange(option.value)}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
              name={'shippingMethod'}
            />
          </Grid>
        </>
      )}
      {watch('shippingMethod') === EnumShippingMethods.Carrier && (
        <Grid item xs={12}>
          <Typography variant={'h3'} mb={2}>
            Select shipping address
          </Typography>
          {!isMobile ? (
            <Grid container spacing={2} mb={2}>
              {addresses.map((slide, index) => (
                <Grid key={index} item xs={4}>
                  <AddressCard
                    isActive={shippingAddressId === slide.id}
                    onClick={() => setValue('shippingAddress', slide.id)}
                    {...slide}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <CarouselCardSelect
              slides={addresses}
              renderSlide={(slide) => (
                <AddressCard
                  isActive={shippingAddressId === slide.id}
                  onClick={() => setValue('shippingAddress', slide.id)}
                  {...slide}
                />
              )}
            />
          )}
          <Grid item xs={12} sm={6} lg={4} pr={1.4}>
            <AddAddressButton
              size={'large'}
              variant={'contained'}
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleModal}
            >
              Add other address
            </AddAddressButton>
          </Grid>
          <Modal open={isModalOpen} onClose={toggleModal}>
            <ModalContent>
              <AddShippingAddress
                samplesOnly={onlySamples === 'true'}
                isKnownUser={isKnownUser}
                onAddAddress={onAddAddress}
                onSuccess={toggleModal}
              />
            </ModalContent>
          </Modal>
        </Grid>
      )}
      {isMobile && (
        <Grid item xs={12} position={'fixed'} bottom={160} left={16} right={16}>
          <ButtonRound
            disabled={!shippingMethod}
            variant={'contained'}
            color={'secondary'}
            fullWidth
            onClick={onSubmit}
            size={'large'}
          >
            Go to next step
          </ButtonRound>
        </Grid>
      )}
    </Grid>
  )
}

export default ShippingMethod
