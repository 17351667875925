import {
  styled,
  Card as MuiCard,
  CardContent as MuiCardContent
} from '@mui/material'

export const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexGrow: 1
})
export const Footer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexGrow: 1
})

export const Card = styled(MuiCard)(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  flexDirection: 'column',
  ':hover': {
    backgroundColor: theme.palette.sand.main
  }
}))

export const CardContent = styled(MuiCardContent)(({ theme }) => ({
  '&:last-child': { paddingBottom: 16 },
  display: 'flex',
  minHeight: 'inherit',
  '&:hover section:only-of-type': {
    borderLeft: `solid 2px ${theme.palette.sand.dark}`
  }
}))

export const VolumeColumn = styled('div')({
  flex: '0 0 78px',
  paddingRight: 8
})
export const ContentColumn = styled('section')(({ theme }) => ({
  flex: '1 0 0',
  minHeight: 'inherit',
  alignSelf: 'stretch',
  alignItems: 'stretch',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  borderLeft: `solid 2px ${theme.palette.sand.main}`,
  paddingLeft: 8
}))

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap'
}))

export const StatusIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -1,
  left: -1,
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '30px 30px 0 0',
  borderColor: `${theme.palette.pine.main} transparent transparent transparent`
}))
