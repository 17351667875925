import { styled } from '@mui/material/styles'

export const Wrapper = styled('div')(({ theme }) => ({
  padding: '78px 47px 40px',
  backgroundColor: theme.palette.sand.main,
}))

export const InfoCircleHolder = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 18,
})

export const ProductsQuantity = styled('div')(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.navy.main,
  '&:before': {
    content: '""',
    flexShrink: 0,
    width: 12,
    height: 12,
    marginRight: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette[color].main,
  },
}))
