import { Check } from '@mui/icons-material'
import { StyledCheckIconRoot } from './styles'
import { colors } from 'constants'

export const iconsSet = {
  'success': {
    color: colors.tertiary.contrastText,
    icon: (
      <StyledCheckIconRoot>
        <Check fontSize={'inherit'} />
      </StyledCheckIconRoot>
    )
  }
}
