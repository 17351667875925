import {
  CircularProgress,
  TextField,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import Stepper from '../stepper'
import { useCallback, useEffect, useMemo, useState } from 'react'
import cartCheckoutSteps, {
  cartCheckoutStepsSamplesOnly
} from '../../constants/cart-checkout-steps'
import ShippingMethod from '../forms/shipping-method'
import Carrier from '../forms/carrier/Carrier'
import mockCarrierCompanies from '../../constants/mock-data/carrier-companies'
import customDeliveryTimeOptions from '../../constants/mock-data/custom-delivery-time-options'
import CartTotal from '../cart-total'
import { FormProvider, useForm } from 'react-hook-form'
import { StyledHorizontalLine, StyledTotalWrapper } from './styles'
import ButtonRound from '../button-round'
import BackLink from '../back-link'
import { useNavigate } from 'react-router-dom'
import { useCart } from '../../utils/hooks/cart'
import { getState } from '../../store'
import { EnumShippingMethods } from '../forms/shipping-method/mockOptions'
import { checkoutServiceHooks } from '../../services/checkout/hooks'
import { getAddresses, setNewAddress } from '../../store/delivery.state'
import CheckoutConfirmation from '../checkout-confirmation'
import { useModal } from '../../services/modal'
import ModalErrorContent from '../modal-error-content/ModalErrorContent'
import CheckoutTotal from '../checkout-total'
import { useGoogleTagManager } from 'utils/helpers/gtm'
import { formatCurrency } from 'utils/helpers/formatting'
import { useUserData } from 'services/auth/index'
import { LoadingIndicator } from 'components/loading-indicator/index'
import AddShippingAddress from 'components/forms/add-shipping-address/AddShippingAddress'

const { useCheckout } = checkoutServiceHooks

const CheckoutForm = ({ samplesOnly = false }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const {
    prepareCartItems,
    clearCart,
    prepareCartItemsForGtm,
    getCartItemsByCategory
  } = useCart()
  const { onPlaceOrder } = useGoogleTagManager()

  const { openModal } = useModal()
  const { currency } = getState('settings')

  const [addressesList, setAddressList] = useState(getAddresses())
  const [isKnownUser, setIsKnownUser] = useState()
  const [unknownHasAddress, setUnknownHasAddress] = useState()
  const [noCarriersAvailable, setNoCarriersAvailable] = useState()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [checkoutRequest, isLoading, responseData] = useCheckout({
    onError: (err) => {
      openModal({
        content: <ModalErrorContent error={err?.data?.message || err} />
      })
    }
  })

  const addAddress = useCallback((address, isUserDefineAdddress = true) => {
    const addressesToAdd = Array.isArray(address) ? address : [address]
    if (isUserDefineAdddress) {
      setNewAddress((addresses) => {
        // add an 'id' so we can identify the user defined addresses:
        return [...addresses, ...addressesToAdd].map((adr, idx) => {
          return { ...adr, id: idx, userDefined: true }
        })
      })
      setUnknownHasAddress(true)
    }
    setAddressList((addresses) =>
      [
        ...addresses,
        ...addressesToAdd.map((a) => ({ ...a, userDefined: isUserDefineAdddress }))
      ].map((address, id) => ({
        ...address,
        id,
        telephone: address?.phone || address.telephone,
        company: address.name || address.company
      }))
    )
  }, [])

  const { whoami, loading, userData } = useUserData()
  useEffect(() => {
    whoami()
  }, [whoami])

  useEffect(() => {
    setIsKnownUser(!!userData?.customer)
    if (userData && userData.addresses) {
      addAddress(userData.addresses, false)
    }
  }, [userData, addAddress])

  const methods = useForm({
    defaultValue: {
      // method might be 'own' || 'carrier'
      isKnownUser,
      special_request: '',
      shippingMethod: '',
      shippingAddress: null,
      carrierCompany: null
    }
  })
  const { getTotalCartPrice } = useCart()
  const [activeStepId, setActiveStepId] = useState(1)

  useEffect(() => {
    setActiveStepId(isKnownUser ? 2 : 1)
  }, [isKnownUser])

  const { shippingAddress, carrierCompany, shippingMethod } = methods.watch()

  const handleStepPress = (id) => setActiveStepId(id)

  const onSubmit = (values) => {
    const address =
      addressesList.find((address) => address.id === shippingAddress) ||
      addressesList[addressesList.length - 1]
    const cartItems = prepareCartItems()
    const has_own_transport =
      values.shippingMethod === EnumShippingMethods.Own ? 1 : 0
    const comment = values?.special_request || ''

    console.group('checkout::')
    console.log('samples only:\t\t', samplesOnly)
    console.log('known user:\t\t', isKnownUser)
    console.log('address:\t\t', address)
    console.log('comment:\t\t', comment)
    console.groupEnd()

    const deliveryTypes = {
      0: 'different',
      1: 'self',
      2: 'same'
    }

    if (samplesOnly) {
      checkoutRequest({
        samples: cartItems?.samples,
        ...(comment && {
          comment
        }),
        has_own_transport: 0,
        ...address,
        address
      })
    } else {
      if (isKnownUser) {
        checkoutRequest({
          ...cartItems,
          ...(comment && {
            comment
          }),
          transport: values.carrierCompany?.data,
          has_own_transport,
          ...address,
          ...(!has_own_transport && {
            address
          })
        })
      } else {
        checkoutRequest({
          ...(comment && {
            comment
          }),
          transport: values.carrierCompany?.data,
          spots: cartItems?.spots || [],
          samples: cartItems?.samples || [],
          has_own_transport,
          delivery_type: deliveryTypes[has_own_transport],
          ...address,
          ...(!has_own_transport && {
            address
          })
        })
      }
    }
  }

  const nextStep = useCallback(() => {
    let nextStepId = activeStepId + 1
    if (shippingMethod === EnumShippingMethods.Own && activeStepId === 2) {
      nextStepId += 1
    }

    if (nextStepId > 4) return null
    setActiveStepId(nextStepId)
  }, [shippingMethod, activeStepId])

  const canPlaceOrder = useMemo(() => {
    if (isKnownUser) {
      return samplesOnly
        ? shippingAddress !== undefined
        : shippingMethod !== EnumShippingMethods.Own
        ? carrierCompany || noCarriersAvailable
        : true
    }

    return (
      unknownHasAddress &&
      (samplesOnly
        ? shippingAddress !== undefined
        : shippingMethod !== EnumShippingMethods.Own
        ? carrierCompany || noCarriersAvailable
        : true)
    )
  }, [
    samplesOnly,
    noCarriersAvailable,
    isKnownUser,
    shippingAddress,
    carrierCompany,
    shippingMethod,
    unknownHasAddress
  ])

  const totalCartPrice = getTotalCartPrice()
  const products = getCartItemsByCategory()

  const formatPrice = (price) => {
    if (currency?.weight === 'lb') {
      return `${currency?.sign} ${formatCurrency(price, 0)}${
        currency?.additionalSign
      }`
    }
    return `${currency?.sign} ${formatCurrency(price)}`
  }

  useEffect(() => {
    if (shippingMethod === EnumShippingMethods.Own) {
      methods.setValue('carrierCompany', null)
    }
  }, [shippingMethod, methods])

  useEffect(() => {
    if (noCarriersAvailable) {
      methods.setValue('carrierCompany', null)
    }
  }, [noCarriersAvailable, methods])

  const renderCartCheckoutSteps = useMemo(() => {
    if (isMobile && samplesOnly) return cartCheckoutStepsSamplesOnly

    return cartCheckoutSteps(shippingMethod)
  }, [isMobile, samplesOnly, shippingMethod])

  const renderAddAddressUnknownUser = useMemo(() => {
    return (
      <>
        <Grid
          container
          sx={{
            ...(!isMobile && { maxWidth: '60%' }),
            pl: isMobile && 2,
            pr: isMobile && 2
          }}
        >
          <Typography mb={2} variant={'body'} color={!isKnownUser && 'primary.main'}>
            Your profile is not yet completed. To order we need some more details.
          </Typography>
          <Grid item>
            <AddShippingAddress
              onAddAddress={addAddress}
              onSuccess={nextStep}
              isKnownUser={isKnownUser}
              samplesOnly={samplesOnly}
            />
          </Grid>
        </Grid>
      </>
    )
  }, [nextStep, isMobile, isKnownUser, addAddress, samplesOnly])

  const renderSpecialRequest = useMemo(() => {
    return (
      <Grid item sx={{ mt: 1 }} xs={12}>
        <Typography variant={'h3'}>Special request</Typography>
        <TextField
          sx={{ mt: 1, mb: 4 }}
          {...methods.register('special_request', {
            required: {
              value: false
            }
          })}
          fullWidth
          multiline
        />
      </Grid>
    )
  }, [methods])

  const totalCost = useMemo(
    () => ({
      currency: currency?.sign,
      subTotalPrice: totalCartPrice,
      totalPrice: !!carrierCompany
        ? (totalCartPrice + carrierCompany.price).toFixed(2)
        : totalCartPrice,
      carrierCompany: carrierCompany
        ? {
            name: carrierCompany?.name,
            price: `${carrierCompany?.price} ${currency?.sign}`
          }
        : null,
      isLoading,
      disabled: isLoading || !!methods.formState.isValidating,
      products
    }),
    [
      carrierCompany,
      currency?.sign,
      isLoading,
      methods.formState.isValidating,
      products,
      totalCartPrice
    ]
  )

  const _renderForm = (stepId) => {
    if (isMobile && samplesOnly && stepId === 2) {
      return (
        <Grid
          p={2}
          mb={7}
          container
          component={'form'}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <CheckoutTotal {...totalCost}>{renderSpecialRequest}</CheckoutTotal>
        </Grid>
      )
    }
    switch (stepId) {
      case 1:
        return renderAddAddressUnknownUser
      case 2:
        return (
          <Grid p={2} container mb={12}>
            <ShippingMethod
              isKnownUser={isKnownUser}
              onAddAddress={addAddress}
              addresses={addressesList}
              onSubmit={nextStep}
            />
          </Grid>
        )
      case 3:
        return (
          <Grid p={2} container mb={6}>
            <Carrier
              setNoCarriersAvailable={setNoCarriersAvailable}
              addresses={addressesList}
              companies={mockCarrierCompanies}
              deliveryTimeOptions={customDeliveryTimeOptions}
              onSubmit={nextStep}
            />
          </Grid>
        )
      case 4:
        return (
          <Grid
            p={2}
            mb={7}
            container
            component={'form'}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <CheckoutTotal {...totalCost}>{renderSpecialRequest}</CheckoutTotal>
          </Grid>
        )

      default:
        return null
    }
  }

  const _renderDesktopForm = (stepId) => {
    const cartTotalPrice = getTotalCartPrice()
    // const finalPrice = !!carrierCompany
    //   ? (cartTotalPrice + carrierCompany.price).toFixed(2)
    //   : cartTotalPrice
    const finalPrice = cartTotalPrice
    return (
      <Grid container>
        <Grid item xs={8} p={2} px={3}>
          <BackLink onClick={() => navigate(-1)} />
          <Typography variant={'h1'} mb={2}>
            Check out
          </Typography>
          {stepId === 1 && renderAddAddressUnknownUser}
          {stepId > 1 && (
            <ShippingMethod
              isKnownUser={isKnownUser}
              onAddAddress={addAddress}
              addresses={addressesList}
              onSubmit={nextStep}
            />
          )}
          {typeof shippingAddress === 'number' &&
            shippingMethod === EnumShippingMethods.Carrier && (
              <Carrier
                setNoCarriersAvailable={setNoCarriersAvailable}
                addresses={addressesList}
                companies={mockCarrierCompanies}
                deliveryTimeOptions={customDeliveryTimeOptions}
                onSubmit={nextStep}
              />
            )}
        </Grid>

        <StyledTotalWrapper
          container
          item
          xs={4}
          alignItems={'flex-start'}
          minHeight={'100vh'}
          component={'form'}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid container position={'sticky'} top={0} p={3} pt={7}>
            <Grid
              item
              xs={12}
              container
              justifyContent={'space-between'}
              alignItems={'baseline'}
              mb={6}
            >
              <Typography variant={'h3'}>Total order amount</Typography>
              <Typography variant={'h3'}>{formatPrice(cartTotalPrice)}</Typography>
            </Grid>
            {carrierCompany ? (
              <Grid container item xs={12} mb={3}>
                <Grid item xs={12} mb={0.75}>
                  <Typography variant={'h3'}>Delivery costs</Typography>
                </Grid>
                <Grid item xs={12} container justifyContent={'start'}>
                  <Typography variant={'body1'}>
                    Carrier {carrierCompany.name}
                  </Typography>
                  {/*
                  <Typography variant={'body1'}>
                    {formatPrice(carrierCompany.price)}
                  </Typography>
                  */}
                </Grid>

                <Grid item xs={12} container justifyContent={'start'}>
                  <Typography variant={'body1'}>
                    Trabocca will shortly contact you with more details on transport
                    and associated costs.
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <StyledHorizontalLine />
            <Grid
              item
              xs={12}
              container
              justifyContent={'space-between'}
              alignItems={'baseline'}
              mb={6}
            >
              <Typography variant={'h3'}>Total</Typography>
              <Typography variant={'h3'}>{formatPrice(finalPrice)}</Typography>
            </Grid>
            <Grid item xs={12} mb={3}>
              <Typography>
                All offers and orders are subject to our final confirmation and
                availability
              </Typography>
            </Grid>
            {renderSpecialRequest}
            <ButtonRound
              {...(isLoading && {
                endIcon: <CircularProgress color='primary' size={20} />
              })}
              disabled={
                !canPlaceOrder ||
                isLoading ||
                !!methods.formState.isValidating ||
                activeStepId === 0
              }
              variant={'contained'}
              color={'secondary'}
              fullWidth
              size={'large'}
              type={'submit'}
            >
              Place order
            </ButtonRound>
          </Grid>
        </StyledTotalWrapper>
      </Grid>
    )
  }

  useEffect(() => {
    if (responseData?.data?.success) {
      clearCart()
      onPlaceOrder(prepareCartItemsForGtm(), getTotalCartPrice())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData])

  if (responseData?.data?.success) {
    return <CheckoutConfirmation />
  }

  return (
    <Grid container justifyContent={'center'} minHeight={'calc(100vh - 94px)'}>
      <LoadingIndicator isLoading={loading} label={'Loading...'}>
        <FormProvider {...methods}>
          <input
            type={'hidden'}
            value={samplesOnly}
            {...methods.register('onlySamples')}
          />
          <Grid item xs={12} position={'relative'}>
            {isMobile ? (
              <>
                <Stepper
                  {...(isMobile &&
                    shippingMethod === EnumShippingMethods.Own && {
                      inActiveStepId: 3
                    })}
                  activeStepId={activeStepId}
                  onClickStep={handleStepPress}
                  steps={renderCartCheckoutSteps}
                >
                  <Grid container pt={2.8}>
                    {_renderForm(activeStepId)}
                  </Grid>
                </Stepper>
                {activeStepId < 4 && <CartTotal sum={totalCost.totalPrice} />}
              </>
            ) : (
              _renderDesktopForm(activeStepId)
            )}
          </Grid>
        </FormProvider>
      </LoadingIndicator>
    </Grid>
  )
}

export default CheckoutForm
