import { styled } from '@mui/material/styles'
import { ReactComponent as SmudgedTagSvg } from 'assets/images/smudged-tag.svg'

export const Wrapper = styled('span')(({ theme }) => ({
  width: 104,
  height: 24,
  position: 'relative',
  fontSize: '0.75rem',
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const Svg = styled(SmudgedTagSvg)(({ theme, color }) => ({
  position: 'absolute',
  zIndex: 0,
  fill: theme.palette[color]?.main || theme.palette.primary.main,
  left: 0,
}))

export const Label = styled('span')(({ theme, color }) => ({
  position: 'relative',
  color:
    theme.palette[color]?.contrastText || theme.palette.primary.contrastText,
  zIndex: 1,
}))
