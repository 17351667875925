import { useMemo } from 'react'
import ProductCard from 'components/product-card'
import ProductCardVolume from 'components/product-card-volume'
import { Grid } from '@mui/material'

export const CardLayout = ({ cuppingScore, spots, onSpotClick, bagsPrice }) => {
  return useMemo(() => {
    if (!spots) return null
    return spots.map((prod, idx) => {
      return (
        <Grid key={`prod-${idx}`} item xs={12} sm={6} lg={4}>
          <ProductCard
            {...(prod.availability_date && {
              statusFuture: true
            })}
            volume={
              <ProductCardVolume
                label={prod.availability_date ? 'Future' : ''}
                large={prod.bags}
                small={`${prod.pack_weight} ${prod.pack_unit}`}
              />
            }
            price={prod.pricingData.pricesFormatted[bagsPrice]}
            description={prod.process}
            label={prod.batch}
            title={prod.location.name}
            tag={cuppingScore && cuppingScore(prod.score)}
            cursor={onSpotClick ? 'pointer' : 'default'}
            onClick={() => onSpotClick(prod)}
          />
        </Grid>
      )
    })
  }, [spots, bagsPrice, cuppingScore, onSpotClick])
}
