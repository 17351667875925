import { useState, useMemo, useRef, useEffect } from 'react'
import { Pagination, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IconButton, useTheme, useMediaQuery } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { RootGrid, Image } from './styles'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { SwiperControls } from './styles'

const ProductModalGallery = ({ images = [], fallbackImage }) => {
  const swiperRootRef = useRef(null)
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const prevSlide = useRef(null)
  const nextSlide = useRef(null)
  const [swiperRef, setSwiperRef] = useState(null)

  const goToSlideByIndex = (index) => {
    swiperRef.slideTo(index)
  }

  const handleKeyDown = (e) => {
    const hasParent = (element, parents) => {
      if (!element) return false
      if (!parents?.length) return false
      return parents.some((parent) => parent.contains(element))
    }

    const swiperFocused = hasParent(e.target, [swiperRootRef.current])
    if (swiperFocused) {
      e.preventDefault()
      const isNext = e.keyCode === 39
      const isPrev = e.keyCode === 37
      if (isNext) nextSlide.current.click()
      if (isPrev) prevSlide.current.click()
    }
  }

  const renderSlides = useMemo(() => {
    if (!images?.length) {
      return (
        <SwiperSlide key={0} onClick={() => goToSlideByIndex(0)}>
          <Image src={fallbackImage} />
        </SwiperSlide>
      )
    }
    return images.map((img, index) => {
      return (
        <SwiperSlide key={index} onClick={() => goToSlideByIndex(index)}>
          <Image src={img} />
        </SwiperSlide>
      )
    })
    // eslint-disable-next-line
  }, [images])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <RootGrid ref={swiperRootRef} style={{ '--swiper-pagination-color': '#FFF' }}>
      <Swiper
        pagination={true}
        loop={renderSlides?.length > 1}
        autoplay={{
          enabled: renderSlides?.length > 1,
          delay: 5000,
          disableOnInteraction: false
        }}
        navigation={{
          enabled: true,
          prevEl: prevSlide.current,
          nextEl: nextSlide.current
        }}
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={16}
        onSwiper={(swiper) => setSwiperRef(swiper)}
        style={{
          width: '100%',
          height: mobile ? 375 : 475
        }}
      >
        {renderSlides}
        {renderSlides?.length > 1 && (
          <SwiperControls id='modal-gallery-controls'>
            <IconButton
              size='small'
              sx={{ background: 'rgba(255, 255, 255, 0.5)' }}
              ref={prevSlide}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              size='small'
              sx={{ background: 'rgba(255, 255, 255, 0.5)' }}
              ref={nextSlide}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </SwiperControls>
        )}
      </Swiper>
    </RootGrid>
  )
}

export default ProductModalGallery
