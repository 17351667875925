import { Outlet } from 'react-router-dom'
import { Main, Page, LogoContainer, LogoLetter } from './styles'

const GuestLayout = () => {
  return (
    <Page>
      <LogoContainer to={'/'}>
        <LogoLetter />
      </LogoContainer>
      <Main>
        <Outlet />
      </Main>
    </Page>
  )
}

export default GuestLayout
