import { NavLink } from 'react-router-dom'

import { ListItem, ListItemText } from '@mui/material'
import { ListItemButton, ListItemIcon } from './styles'

const AppDrawerItem = ({ label, to, icon, onClick }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton component={to && NavLink} to={to && to} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  )
}

export default AppDrawerItem
