const statusColors = {
  'Just arrived': 'thyme',
  Afloat: 'stone',
  Expired: 'chocolat',
  'Expires soon': 'pine'
}

export const statusEnum = {
  Future: 'pine',
  'Delivery pending': 'stone'
}

export default statusColors
