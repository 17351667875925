import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import { GridView as GridViewIcon, TableRows as TableRowsIcon } from '@mui/icons-material'

import { Wrapper, Item } from './styles'

const ToggleView = ({ value, onChange }) => {
  return (
    <Wrapper>
      <Item>
        <Typography>View as</Typography>
      </Item>
      <Item>
        <ToggleButtonGroup exclusive={true} onChange={onChange} value={value}>
          <ToggleButton size='small' key='table' value='table' color='primary'>
            <TableRowsIcon />
          </ToggleButton>
          <ToggleButton size='small' key='grid' value='grid' color='primary'>
            <GridViewIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Item>
    </Wrapper>
  )
}

export default ToggleView
