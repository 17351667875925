import { useLayoutEffect, useState } from 'react'
import { MenuItem } from '@mui/material'
import { KeyboardArrowDown, LocationOn } from '@mui/icons-material'
import { WarehouseSelect } from './styles'

export const WarehouseSelection = ({ options, onChange }) => {
  const [value, setValue] = useState('ALL')

  useLayoutEffect(() => {
    onChange(value)
  }, [value, onChange])
  return (
    <WarehouseSelect
      value={value}
      onChange={(e) => setValue(e.target.value)}
      IconComponent={KeyboardArrowDown}
    >
      {options.map(({ title, key }, idx) => (
        <MenuItem key={idx} value={key}>
          <LocationOn />
          {title}
        </MenuItem>
      ))}
    </WarehouseSelect>
  )
}
