import { Typography, Grid } from '@mui/material'

import { CardHeader, CardContent, StyledUL, CheckIcon, Card, HeaderCheckMark } from './styles'
import ButtonBaseCard from '../button-base-card'

const CarrierCard = ({ label, name, hours, has_tail_lift, time_options = [], onClick, isActive = false }) => {
  const getCarrierSelectorContent = (e) => {
    return `Delivered within ${Math.ceil(e / 8)} days`
  }

  return (
    <ButtonBaseCard onClick={onClick}>
      <Card>
        <CardHeader
          title={
            <>
              {isActive && <HeaderCheckMark />}
              {label}
            </>
          }
          active={isActive.toString()}
        />
        <CardContent active={isActive.toString()}>
          <Typography variant={'h3'} mb={2}>
            {name}
          </Typography>
          <Typography variant={'body1'}>Expected delivery:</Typography>
          <Typography fontWeight={'bold'}>{getCarrierSelectorContent(hours)}</Typography>
          <StyledUL container component={'ul'} flexDirection={'column'}>
            {has_tail_lift && (
              <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} mb={1.8}>
                <CheckIcon />
                <Typography variant={'body1'} component={'li'}>
                  Taillift option
                </Typography>
              </Grid>
            )}
            {time_options?.length ? (
              <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} mb={1.8}>
                <CheckIcon />
                <Typography variant={'body1'} component={'li'}>
                  Custom delivery time
                </Typography>
              </Grid>
            ) : null}
          </StyledUL>
        </CardContent>
      </Card>
    </ButtonBaseCard>
  )
}

export default CarrierCard
