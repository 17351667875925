import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import backgroundImage from '../../assets/images/guest-layout-background.png'
import logoBackgroundImage from '../../assets/images/logo-background-small.jpeg'
import { ReactComponent as LogoLetterIcon } from 'assets/images/logo-letter.svg'

export const Page = styled('div')({
  background: `url(${backgroundImage}) no-repeat 0 0 / cover`,
  '&:before': {
    display: 'block',
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(170.84deg, #10FFFF 8.27%, #000000 91.15%)',
    opacity: 0.22
  }
})

export const Main = styled('main')({
  position: 'relative',
  minHeight: '100vh',
  zIndex: 1002
})

export const LogoContainer = styled(Link)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: 70,
  height: 80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${logoBackgroundImage}) no-repeat 0 0 / cover`,
  zIndex: 1001
})

export const LogoLetter = styled(LogoLetterIcon)({})
