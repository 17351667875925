import { CircularProgress } from '@mui/material'
import { Loader } from './styles'

export const LoadingIndicator = ({ isLoading, label, variant = 'h7', iconSize = 14, children }) => {
  return isLoading ? (
    <Loader variant={variant}>
      {label}
      <CircularProgress color='secondary' size={iconSize} />
    </Loader>
  ) : (
    children
  )
}
