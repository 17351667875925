import { Grid, Typography, Link } from '@mui/material'
import ButtonRound from 'components/button-round'
import LinkCompanyModal from 'components/link-company-modal'
import { useModal } from 'services/modal/ModalProvider'

const PersonalData = ({
  id,
  firstName,
  lastName,
  fullName,
  company,
  customer,
  email,
  contactEmail
}) => {
  const { openModal } = useModal()

  const linkCompany = () => {
    openModal({
      title: 'Link your company',
      content: <LinkCompanyModal user={{ id, fullName, company }} />
    })
  }

  const companyIsNotLinked = !company || (company && !customer?.key)

  return (
    <Grid container columnSpacing={2.2} rowSpacing={2.75}>
      <Grid item xs={12}>
        <Typography variant='h3'>Personal data</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='body2' mb={1}>
          Surname
        </Typography>
        <Typography fontWeight={600}>{firstName}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='body2' mb={1}>
          Last name
        </Typography>
        <Typography fontWeight={600}>{lastName}</Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography variant='body2' mb={1}>
          Company
        </Typography>
        <Typography fontWeight={600} mb={1}>
          {company}
        </Typography>
        {companyIsNotLinked && (
          <ButtonRound onClick={linkCompany} color='secondary' variant='contained'>
            Link company
          </ButtonRound>
        )}
      </Grid>

      <Grid item xs={6}>
        <Typography variant='body2' mb={1}>
          Email address
        </Typography>
        <Typography fontWeight={600}>{email}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='body2' mb={1}>
          Want to change your info
        </Typography>
        <Link
          href={`mailto:${contactEmail}`}
          color='inherit'
          fontWeight={600}
          sx={{
            '&:hover': {
              textDecoration: 'none'
            }
          }}
        >
          Please contact us
        </Link>
      </Grid>
    </Grid>
  )
}

export default PersonalData
