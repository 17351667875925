import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  FormHelperText
} from '@mui/material'
import { useForm, useFormContext, Controller } from 'react-hook-form'
import CarouselCardSelect from '../../carousel-card-select'
import CarrierCard from '../../carrier-card'
import ButtonRound from '../../button-round'
import CustomSelect from '../../custom-select'
import Option from '../../option'
import { useEffect } from 'react'
import { checkoutServiceHooks } from '../../../services/checkout/hooks'
import { LoadingIndicator } from '../../loading-indicator'
import {
  carrierTimeOptions,
  sortTimeOptions
} from '../../../constants/carrier-time-options'
import { useCart } from '../../../utils/hooks/cart'

const { useGetTransports } = checkoutServiceHooks

const Carrier = ({ addresses, onSubmit, setNoCarriersAvailable }) => {
  const { watch: watchCheckout, setValue } = useFormContext()
  const {
    control,
    watch,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      carrierCompanyCode: '',
      tailLift: false,
      extraTime: ''
    }
  })
  const { prepareCartItems } = useCart()
  const { carrierCompany, shippingAddress, onlySamples } = watchCheckout()
  const address = addresses.find((address) => address.id === shippingAddress)

  const body = {
    address,
    ...prepareCartItems()
  }

  const [transportReq, isLoading, data, error] = useGetTransports()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (onlySamples === 'false') {
      reset()
      transportReq(body)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, onlySamples])

  useEffect(() => {
    if (onlySamples === 'true') {
      setValue('carrierCompany', null)
    }
  }, [onlySamples, setValue])

  useEffect(() => {
    if (error || isLoading) {
      setNoCarriersAvailable(true)
    }
  }, [error, isLoading, setNoCarriersAvailable])

  const renderLabel = (key) => carrierTimeOptions[key] || 'Unknown'

  const carrierCompanies = data?.data?.data
  const carrierFetchError = error?.data

  const selectedCompany = carrierCompanies?.find(
    (company) => company.code === watch('carrierCompanyCode')
  )

  const carrierCompanyValues = watch()

  useEffect(() => {
    const { carrierCompanyCode, tailLift, extraTime } = carrierCompanyValues

    if (!carrierCompanyCode || typeof tailLift !== 'boolean')
      return null

    const selectedCarrierOption = selectedCompany.options.find(
      ({ has_tail_lift, time_option }) => {
        if (!extraTime) {
          return has_tail_lift === tailLift
        } else {
         return has_tail_lift === tailLift && time_option === extraTime
        }
      }
    )

    if (carrierCompany?.data !== selectedCarrierOption.data)
      setValue('carrierCompany', selectedCarrierOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierCompanyValues])

  useEffect(() => {
    if (carrierCompanies) {
      setNoCarriersAvailable(false)
    }
  }, [carrierCompanies, setNoCarriersAvailable])

  if (carrierFetchError) {
    switch (carrierFetchError?.message) {
      case 'No transport rates available':
        return (
          <Grid>
            <Typography variant={'h3'} mb={2}>
              No carriers available
            </Typography>
            <Typography variant={'body1'}>
              There are no carrier choices available for the selected address.
            </Typography>
            <Typography variant={'body1'}>
              Trabocca will contact you as soon as possible for more information
              about the transportation.
            </Typography>
          </Grid>
        )

      default:
        return (
          <Grid>
            <Typography variant={'h3'} mb={2}>
              No carriers available
            </Typography>
            <Typography variant={'body1'}>
              There are no carrier choices available for the selected address.
            </Typography>
            <Typography variant={'body1'}>
              Trabocca will contact you as soon as possible for more information
              about the transportation.
            </Typography>
            {isMobile && (
              <Grid
                item
                xs={12}
                position={'fixed'}
                bottom={160}
                left={16}
                right={16}
              >
                <ButtonRound
                  variant={'contained'}
                  color={'secondary'}
                  fullWidth
                  onClick={onSubmit}
                  size={'large'}
                >
                  Go to next step
                </ButtonRound>
              </Grid>
            )}
          </Grid>
        )
    }
  }

  return (
    (onlySamples === 'false' && (
      <Grid container pb={13}>
        <LoadingIndicator isLoading={isLoading} label={'Loading transports'}>
          <Controller
            control={control}
            render={({ field: { value, onChange } }) => {
              const _renderCard = (company) => (
                <CarrierCard
                  isActive={value === company.code}
                  onClick={() => onChange(company.code)}
                  {...company}
                />
              )
              return (
                <Grid item xs={12} mb={2}>
                  <Typography variant={'h3'} mb={2}>
                    Choose a carrier
                  </Typography>
                  {!isMobile ? (
                    <Grid container spacing={2} mb={2}>
                      {carrierCompanies?.map((slide) => (
                        <Grid key={slide.code} item xs={4}>
                          {_renderCard(slide)}
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <CarouselCardSelect
                      slides={carrierCompanies}
                      renderSlide={_renderCard}
                      keyExtractor={(item) => item.code}
                    />
                  )}
                </Grid>
              )
            }}
            name={'carrierCompanyCode'}
          />
          <Grid item xs={12}>
            <Typography variant={'h3'} mb={2}>
              Choose options
            </Typography>
          </Grid>
          {selectedCompany ? (
            <>
              {selectedCompany?.has_tail_lift && (
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <Grid item xs={12} md={6} mb={2}>
                      <Option
                        onClick={() => onChange(!value)}
                        isActive={value}
                        label={'Extra taillift'}
                      />
                    </Grid>
                  )}
                  name={'tailLift'}
                  control={control}
                />
              )}
              {selectedCompany.time_options?.length ? (
                <>
                  <Grid item xs={12} mb={2}>
                    <Typography>Custom delivery time</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: 'Delivery time is required field'
                        }
                      }}
                      render={({ field: { value, onChange } }) => (
                        <FormControl error={!!errors?.extraTime?.message} fullWidth>
                          <CustomSelect
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            options={sortTimeOptions(
                              selectedCompany?.time_options
                            ).map((option) => ({
                              value: option,
                              label: option
                            }))}
                            renderLabel={renderLabel}
                          />
                          {!!errors?.extraTime?.message && (
                            <FormHelperText>
                              {errors?.extraTime?.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                      name={'extraTime'}
                      control={control}
                    />
                  </Grid>
                </>
              ) : null}
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant={'body1'} mb={2}>
                Please pick your carrier first
              </Typography>
            </Grid>
          )}
          {isMobile && (
            <Grid item xs={12} position={'fixed'} bottom={160} left={16} right={16}>
              <ButtonRound
                variant={'contained'}
                color={'secondary'}
                fullWidth
                onClick={onSubmit}
                size={'large'}
              >
                Go to next step
              </ButtonRound>
            </Grid>
          )}
        </LoadingIndicator>
      </Grid>
    )) ||
    null
  )
}

export default Carrier
