import { styled, Typography } from '@mui/material'

export const AccordionBlock = styled('div')({
  '.MuiTypography-root': {
    lineHeight: 1.375
  }
})

export const AccordionHeader = styled(Typography)({
  marginBottom: 12
})

export const AccordionTitle = styled(Typography)(({ theme }) => ({
  maxWidth: '186px',
  flexShrink: 0,
  [theme.breakpoints.up('sm')]: {
    maxWidth: 'none'
  }
}))
