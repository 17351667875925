import { CardContent } from 'views/register/styles'
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import ButtonRound from 'components/button-round'
import { useModal } from 'services/modal'
import { useForm, Controller } from 'react-hook-form'
import RegistrationSteps from 'constants/registration-steps'
import { registerServiceHooks } from 'services/register/hooks'
import CustomSelect from 'components/custom-select'
import businessTypeOptions from 'constants/mock-data/buisness-type-options'

const { useCreate } = registerServiceHooks

const CreateUserForm = ({ defaultValues, onNextStep, children }) => {
  const { openModal } = useModal()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    defaultValues
  })

  const [create, loading] = useCreate({
    onError: (error) => {
      openModal({
        content: (
          <Grid>
            <Typography>{error?.message}</Typography>
          </Grid>
        )
      })
    },
    onSuccess: ({ data }) => {
      const { success } = data
      if (success) {
        onNextStep(RegistrationSteps.Success, {})
      } else {
        openModal({
          content: (
            <Grid>
              <Typography>Fail to recognize the error</Typography>
            </Grid>
          )
        })
      }
    }
  })

  const onSubmit = async ({ companyName, size }) => {
    const { firstName, lastName, email, country, password, passwordConfirmation } =
      defaultValues
    try {
      create({
        first_name: firstName,
        last_name: lastName,
        email,
        country,
        password,
        password_confirmation: passwordConfirmation,
        company: companyName,
        size: size
      })
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12}>
            <TextField
              {...register('companyName', {
                required: { value: true, message: 'Company number is required' }
              })}
              label='Company name'
              fullWidth
              error={!!errors['companyName']}
              helperText={errors['companyName']?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{
                required: { value: true, message: 'Country is required field' }
              }}
              render={({ field: { value, onChange, onBlur } }) => (
                <FormControl error={!!errors['size']} fullWidth>
                  <CustomSelect
                    value={value}
                    onBlur={onBlur}
                    size={'small'}
                    placeholder='Business type'
                    onChange={(e) => onChange(e.target.value)}
                    options={businessTypeOptions}
                    skipSelectBackground
                  />
                  <FormHelperText>{errors['size']?.message}</FormHelperText>
                </FormControl>
              )}
              name={'size'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <ButtonRound
              type={'submit'}
              color='secondary'
              variant='contained'
              fullWidth
              size={'large'}
              disabled={loading}
              endIcon={loading && <CircularProgress color='secondary' size={20} />}
            >
              Connect me
            </ButtonRound>
          </Grid>
        </Grid>
        {children && (
          <Grid container>
            <Grid item>{children}</Grid>
          </Grid>
        )}
      </CardContent>
    </form>
  )
}

export default CreateUserForm
