import { styled } from '@mui/material'

export const Wrapper = styled('ul')(({ theme }) => ({
  display: 'flex',
  listStyle: 'none',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}))

export const Item = styled('li')(({ theme }) => ({
  paddingLeft: 4,
  paddingRight: 4,
}))
