import moment from 'moment'

export const isAvailable = (date) => (date ? isSameOrBefore(date) : false)

export const isAfter = (date) => (!!date ? false : isSameOrBefore(date))

export const isSameOrBefore = (date, nowDate = new Date()) => {
  if (!date) return false
  return moment(date).isSameOrBefore(nowDate, 'day')
}

export const parseDate = (date, format) => {
  return moment(new Date(date)).format(format)
}

export const sortContracts = (a, b) => {
  return moment(b?.from_date).diff(moment(a?.from_date))
}
