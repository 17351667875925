export const EnumShippingMethods = {
  Own: 'own',
  Carrier: 'carrier'
}

export const mockOptions = [
  {
    value: EnumShippingMethods.Own,
    label: 'I’ll arrange transport myself'
  },
  {
    value: EnumShippingMethods.Carrier,
    label: 'Delivery by one of our carriers'
  }
]
