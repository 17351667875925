import { Chip } from '@mui/material'
import { formatAmount, getScoreBasedColor } from 'utils/helpers/formatting'

export const createCuppingScore = (min, max) => {
  const calcColor = getScoreBasedColor(min, max)
  return (score) => (
    <Chip
      color='forest'
      style={{ backgroundColor: '#' + calcColor(score) }}
      size='small'
      label={`${formatAmount(score)}+`}
    />
  )
}
